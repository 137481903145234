import React from 'react'
import styled from 'styled-components'

import { BtnS } from 'components/Typography'

const StepContainer = styled.div`
  display: flex;
  width: 100%;
  filter: ${({ $disabled }) =>
    $disabled ? 'grayscale(100%) contrast(0.1)' : 'none'};
  @media (max-width: 1280px) {
    transform: translateX(7%);
  }
  @media (max-width: 1020px) {
    transform: translateX(3%);
  }
`

const Step = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  font-weight: ${({ $isActive }) => (!$isActive ? '400' : '700')};
  position: relative;
  width: ${({ $steps }) => `calc(100% / ${$steps.length})`};
`

const Circle = styled.div`
  border-radius: 100%;
  border: 2px solid ${({ theme }) => theme.colors.primary.darkBlue};
  background-color: ${({ $isActive, theme, $disabled }) =>
    !$isActive || $disabled
      ? theme.colors.neutral.white
      : theme.colors.primary.darkBlue};
  width: ${({ $isActive }) => (!$isActive ? '10px' : '15px')};
  height: ${({ $isActive }) => (!$isActive ? '10px' : '15px')};
  margin-top: ${({ $isActive }) => (!$isActive ? '2px' : '0')};
`

const Line = styled.div`
  width: 100%;
  height: ${({ $isActive }) => (!$isActive ? '1px' : '3px')};
  border-top-width: ${({ $isActive }) => (!$isActive ? '1px' : '3px')};
  border-top-style: ${({ $isActive }) => (!$isActive ? 'dotted' : 'solid')};
  border-top-color: ${({ theme }) => theme.colors.primary.darkBlue};
  position: absolute;
  top: ${({ $isActive }) => (!$isActive ? '7px' : '8px')};
  margin-top: ${({ $isActive }) => (!$isActive ? '2px' : '0')};
  left: 12px;
`

const Text = styled(BtnS)`
  position: absolute;
  margin-top: ${({ $isActive }) => ($isActive ? '8px' : '11px')};
`

const StepsComponent = ({ steps, currentStep, disabled }) => {
  return (
    <StepContainer $disabled={disabled}>
      {steps.map((step, index) => {
        return (
          <Step
            key={index}
            $isActive={currentStep >= index && !disabled}
            $currentStep={currentStep}
            $steps={steps}
          >
            <Circle $isActive={currentStep >= index && !disabled} />
            {index < steps.length - 1 && (
              <Line $isActive={currentStep >= index && !disabled} />
            )}
            {<Text $isActive={currentStep >= index && !disabled}>{step}</Text>}
          </Step>
        )
      })}
    </StepContainer>
  )
}

export default StepsComponent
