import React from 'react'
import styled from 'styled-components'

import { Search } from 'feather-icons-react/build/IconComponents'
import Input from 'components/Input/Input'

const SearchInputStyle = styled(Input)`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  ::placeholder {
    color: ${({ theme }) => theme.colors.primary.lighterBlue};
  }
  margin-left: 0 !important;
`

const SearchInput = ({ onChange, value, placeholder, className }) => (
  <SearchInputStyle
    className={className}
    type="text"
    value={value}
    onChange={onChange}
    placeholder={placeholder || 'Buscar'}
    icon={<Search size={20} />}
  />
)

export default SearchInput
