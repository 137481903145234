import React, { createContext, useContext, useState } from 'react'

const ModalContext = createContext()

export const useModal = () => useContext(ModalContext)

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [optionalData, setOptionalData] = useState({})
  const [afterClose, setAfterClose] = useState()

  function openModal(data = {}, afterClose) {
    setOptionalData(data)
    setAfterClose(afterClose)
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
    if (afterClose) {
      afterClose()
    }
  }

  const contextValues = {
    isModalOpen,
    openModal,
    closeModal,
    optionalData,
  }

  return (
    <ModalContext.Provider value={contextValues}>
      {children}
    </ModalContext.Provider>
  )
}
