import { BtnM, BtnS, P2 } from 'components/Typography'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { compressLongText, replaceText } from 'utils/textProcess'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ScrollableWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: scroll;
`

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(45% - 2px) 2px 55%;
  height: 30vh;
`

const TableHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(45% - 2px) 2px 55%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  position: sticky;
  top: 0;
`

const TableTotalesWrapper = styled.div`
  position: sticky;
  bottom: -20px;
  display: grid;
  grid-template-columns: 50%;
`

const TableContentAsoc = styled.div`
  height: 100%;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary.lightBlue};
  display: flex;
`

const TableCell = styled(BtnS)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
`

const TableCellAsoc = styled(TableCell)`
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary.lightBlue};
`

const AsociadaHeaderWithTooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: help;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const TableRow = styled.div`
  height: 60px;
  &.header {
    height: 90px;
  }
  display: grid;
  grid-template-columns: 18% 35% 12% 12% 13%;
  gap: 15px;
  padding: 6px 30px;
  width: calc(100% - 2px);
  min-width: fit-content;
  box-sizing: border-box;
  background-color: ${({ theme, $index }) =>
    $index % 2 === 0 || $index === '0'
      ? theme.colors.neutral.almostWhite
      : theme.colors.neutral.white};
`

const TableRowAsoc = styled.div`
  height: 60px;
  &.header {
    height: 90px;
  }
  display: inline-grid;
  gap: 15px;
  padding: 6px 30px;
  grid-template-columns: ${({ $gridTemplate }) => $gridTemplate};
  width: calc(100% - 2px);
  min-width: fit-content;
  box-sizing: border-box;
  background-color: ${({ theme, $index, $error }) =>
    $error
      ? `#f8d7da`
      : $index % 2 === 0 || $index === '0'
        ? theme.colors.neutral.almostWhite
        : theme.colors.neutral.white};
`

const NumberOfCell = styled(P2)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  max-width: fit-content;
`

const RecibidosCell = styled(NumberOfCell)`
  font-weight: bold;
`

const NumberOfCellApedir = styled.input`
  width: 25px;
  height: 20px;
  border: ${({ theme, $error, disabled }) =>
    disabled
      ? 'none'
      : `2px solid ${
          $error
            ? theme.colors.alarmsStatuses.red
            : theme.colors.primary.lightBlue
        }`};
  padding: 8px;
  border-radius: 4px;

  color: ${({ theme }) => theme.colors.primary.lightBlue};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }

  &:focus-visible {
    outline: none;
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield;
  }

  /* Chrome, Safari, Edge, Opera */
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Additional styling for Internet Explorer */
  &[type='number'] {
    -ms-input-appearance: none;
  }
`

const PedidoCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

const SubtitleSection = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  text-transform: uppercase;
`

const DottedVerticalLine = styled.div`
  border-left: 2px dotted ${({ theme }) => theme.colors.primary.lightBlue};
  height: 100%;
`

const TotalesTableSection = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  padding: 20px 30px 0;
  & > :nth-child(1) {
    grid-column-start: 2;
  }
`

const TitleCoop = styled(BtnS)`
  text-transform: uppercase;
  cursor: pointer;
`

const DistribuirPedidosTable = ({
  data,
  headers,
  articulosDivididos,
  setArticulosDivididos,
  $error,
  setError,
  validarErrores,
}) => {
  const columnWidth = 150
  const fixedColumnCount = 5
  let totalPedido = 0
  let totalRecibidos = 0
  let totalDiferencia = 0

  useEffect(() => {
    if (Object.keys(articulosDivididos).length === 0) {
      const initialArticulosDivididos = {}

      data.forEach((item) => {
        Object.values(item.asociadas).forEach((asociada) => {
          const { id_subasociada, id_articulo } = asociada
          if (!initialArticulosDivididos[id_subasociada]) {
            initialArticulosDivididos[id_subasociada] = {}
          }
          if (id_articulo !== null)
            initialArticulosDivididos[id_subasociada][id_articulo] = 0
        })
      })

      setArticulosDivididos(initialArticulosDivididos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChange(id_producto, id_asociada, cantidad) {
    if ($error.length !== 0) {
      setError([])
    }
    setArticulosDivididos((prevState) => {
      const newState = { ...prevState }
      if (cantidad === 0) {
        if (newState[id_asociada] && newState[id_asociada][id_producto]) {
          delete newState[id_asociada][id_producto]
          if (Object.keys(newState[id_asociada]).length === 0) {
            delete newState[id_asociada]
          }
        }
      } else {
        if (!newState[id_asociada]) {
          newState[id_asociada] = {}
        }
        newState[id_asociada][id_producto] = parseInt(cantidad)
      }

      if ($error.length > 0) validarErrores()
      return newState
    })
  }

  function buscarCantidad(id_producto, id_asociada) {
    if (
      articulosDivididos !== undefined &&
      articulosDivididos[id_asociada] !== undefined &&
      articulosDivididos[id_asociada][id_producto] !== undefined
    ) {
      return articulosDivididos[id_asociada][id_producto]
    } else {
      return 0
    }
  }

  return (
    <>
      <Wrapper>
        <ScrollableWrapper>
          <TableHeaderWrapper>
            <TableRow className="header">
              {headers.slice(0, fixedColumnCount).map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
            <DottedVerticalLine />
            <TableRowAsoc
              className="header"
              $gridTemplate={`repeat(${
                headers.length - fixedColumnCount
              }, ${columnWidth}px)`}
            >
              {headers.slice(fixedColumnCount).map((header, index) => (
                <TableCellAsoc key={index}>
                  <AsociadaHeaderWithTooltip title={header}>
                    <TitleCoop title={header}>
                      {compressLongText(
                        replaceText(header, 'cooperativa', 'Coop'),
                        32,
                      )}
                    </TitleCoop>
                  </AsociadaHeaderWithTooltip>
                  <SubtitleSection>
                    <div>Pedido</div>
                    <div>A recibir</div>
                  </SubtitleSection>
                </TableCellAsoc>
              ))}
            </TableRowAsoc>
          </TableHeaderWrapper>
          <TableWrapper>
            <div>
              {data.map((row, index) => (
                <TableRow $index={index} key={index}>
                  {headers
                    .slice(0, fixedColumnCount)
                    .map((column, columnIndex) => {
                      const columName = column.toLowerCase()

                      if (columName === 'pedidos') {
                        totalPedido = totalPedido + parseInt(row[columName])
                      }
                      if (columName === 'recibidos') {
                        totalRecibidos =
                          totalRecibidos + parseInt(row[columName])
                      }
                      if (columName === 'diferencia') {
                        totalDiferencia =
                          totalDiferencia + parseInt(row[columName])
                      }

                      const CellComponent =
                        columName === 'recibidos' ? RecibidosCell : NumberOfCell

                      return (
                        <TableCell key={columnIndex}>
                          <CellComponent>{row[columName]}</CellComponent>
                        </TableCell>
                      )
                    })}
                </TableRow>
              ))}
            </div>
            <DottedVerticalLine />
            <TableContentAsoc>
              {data.map((row, rowIndex) => {
                return (
                  <TableRowAsoc
                    $gridTemplate={`repeat(${
                      headers.length - fixedColumnCount
                    }, ${columnWidth}px)`}
                    key={rowIndex}
                    $index={rowIndex}
                    $error={$error.includes(rowIndex)}
                  >
                    {headers.slice(fixedColumnCount).map((header, index) => {
                      const asociadosValues = Object.values(row['asociadas'])
                      const asociadaData = asociadosValues[index]

                      return (
                        asociadaData && (
                          <PedidoCell key={index}>
                            <NumberOfCell>{asociadaData?.pedido}</NumberOfCell>
                            <NumberOfCellApedir
                              type="number"
                              disabled={asociadaData?.pedido === 0}
                              value={buscarCantidad(
                                asociadaData?.id_articulo,
                                asociadaData?.id_subasociada,
                              )}
                              onChange={(e) =>
                                handleChange(
                                  asociadaData?.id_articulo,
                                  asociadaData?.id_subasociada,
                                  e.target.value,
                                  rowIndex,
                                )
                              }
                            />
                          </PedidoCell>
                        )
                      )
                    })}
                  </TableRowAsoc>
                )
              })}
            </TableContentAsoc>
          </TableWrapper>
        </ScrollableWrapper>
        <TableTotalesWrapper>
          <TotalesTableSection>
            <BtnM>Totales:</BtnM>
            <BtnM>{totalPedido}</BtnM>
            <BtnM>{totalDiferencia}</BtnM>
            <BtnM>{totalRecibidos}</BtnM>
          </TotalesTableSection>
        </TableTotalesWrapper>
      </Wrapper>
    </>
  )
}

export default DistribuirPedidosTable
