import React from 'react'
import styled from 'styled-components'
import Link from 'components/Button/LinkUnderline'

const StyledFooterCopyright = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`

const CopyrightText = styled.p`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.black};
  text-wrap: balance;
  color: white;
  font-size: 12px;
  margin: 0;
  padding: 12px;

  @media (max-width: 440px) {
    padding: 8px;
  }
`
const StyledLink = styled(Link)`
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.neutral.white};
  &:visited {
    color: ${({ theme }) => theme.colors.neutral.white};
  }
`

const FooterCopyright = () => {
  return (
    <StyledFooterCopyright>
      <CopyrightText>
        © Copyright Central Obrera de Compras F.A.C.C / Todos los derechos
        reservados a Central Obrera de compras F.A.C.C / Dirección General de
        Defensa y Protección al Consumidor: para consultas y/o denuncias ingrese{' '}
        <StyledLink
          target="_blank"
          href="https://www.argentina.gob.ar/servicio/iniciar-un-reclamo-ante-defensa-del-consumidor"
        >
          aquí
        </StyledLink>
        .
      </CopyrightText>
    </StyledFooterCopyright>
  )
}

export default FooterCopyright
