import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { P1, P4 } from 'components/Typography'
import { useCronograma } from 'contexts/CronogramaContext'

const TitleCard = styled(P4)`
  margin-bottom: 18px;
  font-family: 'Roboto-bold';
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral.darkGray};
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const TextTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 73px;
  width: 100%;
  border-radius: 8px;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  color: ${({ theme }) => theme.colors.neutral.white};
  background-color: ${({ theme }) => theme.colors.primary.blue};

  @media (max-width: 440px) {
    font-size: 36px;
    height: 60px;
  }
`

const TimeBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  gap: 10px;
`

const FooterText = styled(P1)`
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 10px 0;
  font-family: 'Roboto-bold';
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral.darkGray};
`

const TiempoRestanteCard = () => {
  const [tiempoRestante, setTiempoRestante] = useState({})
  const { cronograma } = useCronograma()
  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }
  const calcularTiempoCronograma = () => {
    const fechaActual = new Date()

    function calcularTiempoRestante(startDate) {
      let proximoCierre = new Date(cronograma.fecha_fin)

      proximoCierre.setHours(proximoCierre.getHours() + 3)
      proximoCierre.setHours(23, 59, 59, 0)

      const fechaFin = proximoCierre.toLocaleDateString('es', dateOptions)

      let diferencia = proximoCierre.getTime() - startDate.getTime()

      const dias =
        Math.floor(diferencia / (1000 * 60 * 60 * 24)) > 0
          ? Math.floor(diferencia / (1000 * 60 * 60 * 24))
          : 0
      diferencia -= dias * (1000 * 60 * 60 * 24)

      const horas = Math.floor(diferencia / (1000 * 60 * 60))
      diferencia -= horas * (1000 * 60 * 60)

      const minutos = Math.floor(diferencia / (1000 * 60))

      return {
        dias,
        horas,
        minutos,
        fechaFin,
      }
    }
    const diferencia = calcularTiempoRestante(fechaActual)
    setTiempoRestante(diferencia)
  }

  useEffect(() => {
    calcularTiempoCronograma()

    const interval = setInterval(() => {
      calcularTiempoCronograma()
    }, 60000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TitleCard>Tiempo restante</TitleCard>
      <Body>
        <TextTime>
          {tiempoRestante.dias > 0 ? tiempoRestante.dias : 0}{' '}
          {tiempoRestante.dias > 1 || tiempoRestante.dias === 0
            ? 'días'
            : 'día'}
        </TextTime>
        <TimeBox>
          <TextTime>
            {tiempoRestante.horas > 0 ? tiempoRestante.horas : 0}h
          </TextTime>
          :
          <TextTime>
            {tiempoRestante.minutos > 0 ? tiempoRestante.minutos : 0}m
          </TextTime>
        </TimeBox>
      </Body>
      {tiempoRestante?.fechaFin && (
        <FooterText>
          Cierre del cronograma {tiempoRestante?.fechaFin.slice(0, 5)}
        </FooterText>
      )}
    </>
  )
}

export default TiempoRestanteCard
