import React from 'react'
import styled from 'styled-components'

const Text = styled.p`
  margin: 0;
  font-size: 17px;
  font-weight: 500;
`

const P3 = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default P3
