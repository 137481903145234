import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table/Table'
import { Button, Input } from 'components/index'
import { BtnS } from 'components/Typography'
import HeaderTable from 'components/Table/HeaderTable'
import CurrencyFormat from 'react-currency-format'
import { usePedidos } from 'contexts/PedidosContext'
import { Download } from 'feather-icons-react/build/IconComponents'

const EmptyTableData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const ScrolleableTable = styled(Table)`
  width: 100%;
  max-height: 50vh;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledTextInput = styled(Input)`
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.primary.darkBlue};
  max-width: 120px;
`

const StyledButton = styled(Button)`
  padding: 0;
  padding-left: -8px;
`

const DownloadIcon = styled(Download)`
  width: 18px;
  height: 18px;
  margin-right: 12px;
  cursor: pointer;
`

const getTableData = (
  data,
  openModal,
  stepNumber,
  handleChange,
  onClickRemitoDescarga,
) => {
  return Object.keys(data).map((key) => ({
    cuit: (
      <BtnS>
        <CurrencyFormat
          value={data[key].cuit}
          displayType={'text'}
          format="##-########-#"
        />
      </BtnS>
    ),
    razonSocial: <BtnS>{data[key].razon_social}</BtnS>,
    remito: !data[key].numero_remito ? (
      <StyledTextInput
        type="text"
        placeholder={'000-000'}
        maxLength="6"
        onChange={(event) =>
          handleChange(data[key].id_afiliado, event.target.value)
        }
      />
    ) : (
      <BtnS>{data[key].numero_remito}</BtnS>
    ),
    verDetalle: (
      <StyledButton
        type="tertiary"
        text="Ver detalle"
        onClick={() => openModal(data[key].detalle)}
      />
    ),
    descarga:
      stepNumber === 4 ? (
        <DownloadIcon
          onClick={() =>
            onClickRemitoDescarga(
              data[key].id_cabecera,
              data[key].id_cabecera_recepcion,
              data[key].id_afiliado,
            )
          }
        />
      ) : (
        ''
      ),
  }))
}

const topHeaders = [
  { name: 'CUIT', isOrderable: false },
  { name: 'Razón social', isOrderable: false },
  { name: 'Remito', isOrderable: false },
  { name: 'Pedido', isOrderable: false },
]

const GenerarRemitosTable = ({
  openModal,
  data,
  stepNumber,
  nrosDeRemito,
  setNrosDeRemito,
}) => {
  const { obtenerPdfRemito } = usePedidos()

  function handleChange(id, remito) {
    const updatedNrosDeRemito = { ...nrosDeRemito }
    Object.keys(updatedNrosDeRemito).forEach((key) => {
      if (key === id) {
        updatedNrosDeRemito[key] = parseInt(remito)
      }
    })
    setNrosDeRemito(updatedNrosDeRemito)
  }

  const onClickRemitoDescarga = async (
    idOcCabecera,
    idCabeceraRecepcion,
    idAfiliado,
  ) => {
    const pdfData = await obtenerPdfRemito(
      idOcCabecera,
      idCabeceraRecepcion,
      idAfiliado,
    )
    const url = window.URL.createObjectURL(
      new Blob([pdfData], { type: 'application/pdf' }),
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `Remito-OC-${idOcCabecera}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <>
      {Object.keys(data)?.length > 0 ? (
        <TableWrapper>
          <HeaderTable
            headers={topHeaders}
            gridTemplate="1fr 2fr 1fr 1fr 1fr"
            numerate={false}
          />
          <ScrolleableTable
            data={getTableData(
              data,
              openModal,
              stepNumber,
              handleChange,
              onClickRemitoDescarga,
            )}
            gridTemplate="1fr 2fr 1fr 1fr 1fr"
            numerate={false}
          />
        </TableWrapper>
      ) : (
        <EmptyTableData>No hay datos</EmptyTableData>
      )}
    </>
  )
}

export default GenerarRemitosTable
