import React from 'react'
import styled from 'styled-components'
import { H2 } from 'components/Typography'
import TotalesSection from './TotalesSection'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 25px;
  width: 60%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const TopSection = styled(Row)`
  justify-content: space-around;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const HeaderPedidoConfirmado = ({ cabecera, totales, fecha }) => {
  return (
    <Header>
      <Title>
        <H2>{cabecera?.condiciones.nombre_comercial}</H2>
      </Title>
      <InfoSection>
        <TopSection>
          <TotalesSection
            headerData={{
              total: totales.total,
              subtotal: totales.subtotal,
              fecha: fecha,
              bultos: totales.bultos,
            }}
          />
        </TopSection>
      </InfoSection>
    </Header>
  )
}

export default HeaderPedidoConfirmado
