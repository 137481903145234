import { P2 } from 'components/Typography'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;

  width: ${({ $progress }) => `${$progress ? '80' : '62'}%`};
  gap: 5px;
  align-items: center;
  color: ${({ theme, $progress, $tipoProveedor, $type }) =>
    !$progress
      ? theme.colors.neutral.gray
      : $type === 'participacion'
        ? theme.colors.primary.blue
        : $progress < 100
          ? $tipoProveedor == 1
            ? theme.colors.alarmsStatuses.red
            : theme.colors.alarmsStatuses.yellow
          : theme.colors.alarmsStatuses.green};
`

const ProgressContainer = styled.div`
  flex: ${({ $progress }) => ($progress ? '80%' : '100%')};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: 2px solid
    ${({ theme, $progress, $tipoProveedor, $type }) =>
      !$progress
        ? theme.colors.neutral.gray
        : $type === 'participacion'
          ? theme.colors.primary.blue
          : $progress < 100
            ? $tipoProveedor == 1
              ? theme.colors.alarmsStatuses.red
              : theme.colors.alarmsStatuses.yellow
            : theme.colors.alarmsStatuses.green};
  border-radius: 20px;
  overflow: hidden;
`

const ProgressBar = styled.div`
  width: ${({ $progress }) => `${$progress}%`};
  height: 100%;
  background-color: ${({ theme, $progress, $tipoProveedor, $type }) =>
    !$progress
      ? theme.colors.neutral.gray
      : $type === 'participacion'
        ? theme.colors.primary.blue
        : $progress < 100
          ? $tipoProveedor == 1
            ? theme.colors.alarmsStatuses.red
            : theme.colors.alarmsStatuses.yellow
          : theme.colors.alarmsStatuses.green};
  transition: width 0.3s ease;
`

const P2Wrapper = styled.div`
  flex: ${({ $progress }) => ($progress ? '20%' : '0%')};
  color: ${({ theme, $progress, $tipoProveedor, $type }) =>
    !$progress
      ? theme.colors.neutral.gray
      : $type === 'participacion'
        ? theme.colors.primary.blue
        : $progress < 100
          ? $tipoProveedor == 1
            ? theme.colors.alarmsStatuses.red
            : theme.colors.alarmsStatuses.yellow
          : theme.colors.alarmsStatuses.green};
`

const ProgressBarComponent = ({
  progress,
  tipoProveedor,
  className,
  type = 'progress',
}) => (
  <Wrapper $progress={progress} $type={type}>
    <ProgressContainer
      $progress={progress}
      $tipoProveedor={tipoProveedor}
      className={className}
      $type={type}
    >
      <ProgressBar
        $type={type}
        $progress={progress}
        $tipoProveedor={tipoProveedor}
      />
    </ProgressContainer>
    {progress && type === 'progress' && (
      <P2Wrapper $progress={progress} $tipoProveedor={tipoProveedor}>
        <P2>{progress > 100 ? 100 : progress}%</P2>
      </P2Wrapper>
    )}
  </Wrapper>
)

export default ProgressBarComponent
