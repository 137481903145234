import React from 'react'
import styled from 'styled-components'

import CardCarousel from './components/CardCarousel'
import { H4 } from 'components/Typography'
import { Wrapper } from 'components/layout/FlexComponents'
import { useCronograma } from 'contexts/CronogramaContext'
import HorizontalPromoCarousel from 'components/Carousel/HorizontalPromoCarousel'

const CronogramaText = styled(H4)`
  align-self: flex-start;
  margin: 20px 180px;
  z-index: 2;

  @media (max-width: 440px) {
    margin: 10px 40px;
  }
`

const WrapperCards = styled(Wrapper)`
  height: 450px;
  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.neutral.white} 50%,
    #162240 50%
  );
`

const productos = [
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '1',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '2',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '3',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '4',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '5',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '6',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '7',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '8',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
  {
    title: 'Test',
    proveedor: 'Test',
    topTag: '',
    botTags: [],
    id: '9',
    habilitado: true,
    puedePedir: true,
    precio: '300,567',
    zona: 'Buenos Aires',
  },
]

const HomeProveedor = () => {
  const { cronograma } = useCronograma()

  return (
    <Wrapper>
      <Wrapper>
        <WrapperCards>
          <CronogramaText>{`Cronograma N°${cronograma.id_cronograma}`}</CronogramaText>
          <CardCarousel />
        </WrapperCards>
        {Object.keys(productos).length > 0 && (
          <HorizontalPromoCarousel
            title="Mis Productos"
            type="primary"
            cards={productos}
            cronogramaHabilitado={true}
          />
        )}
      </Wrapper>
    </Wrapper>
  )
}

export default HomeProveedor
