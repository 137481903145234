import React from 'react'
import AppRoutes from 'routes/routes'
import Theme from 'Theme'
import { AuthProvider } from 'contexts/AuthContext'
import { ModalProvider } from 'contexts/ModalContext'
import { CronogramaProvider } from 'contexts/CronogramaContext'
import { CarritoProvider } from 'contexts/CarritoContext'
import { ProveedoresProvider } from 'contexts/ProveedoresContext'
import { ArticulosProvider } from 'contexts/ArticulosContext'
import { NotificacionesProvider } from 'contexts/NotificacionesContext'
import { InitialDataProvider } from 'contexts/InitialDataContext'
import { FiltersProvider } from 'contexts/FiltersContext'
import ErrorBoundary from 'pages/ErrorBoundary'
import { WindowWidthProvider } from 'contexts/WindowWidthContext'

const App = () => {
  return (
    <WindowWidthProvider>
      <ErrorBoundary>
        <Theme>
          <AuthProvider>
            <ModalProvider>
              <CronogramaProvider>
                <ProveedoresProvider>
                  <CarritoProvider>
                    <ArticulosProvider>
                      <NotificacionesProvider>
                        <FiltersProvider>
                          <InitialDataProvider>
                            <AppRoutes />
                          </InitialDataProvider>
                        </FiltersProvider>
                      </NotificacionesProvider>
                    </ArticulosProvider>
                  </CarritoProvider>
                </ProveedoresProvider>
              </CronogramaProvider>
            </ModalProvider>
          </AuthProvider>
        </Theme>
      </ErrorBoundary>
    </WindowWidthProvider>
  )
}

export default App
