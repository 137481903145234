import { useAuth } from 'contexts/AuthContext'
import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { removeAccents } from 'utils/textProcess'

const StyledNavbarOptions = styled.ul`
  display: inline-flex;
  list-style-type: none;
  padding-left: 0;
  @media (max-height: 768px) {
    margin: 0;
    margin-top: 6px;
  }
`

const NavItem = styled.li`
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 16px;
  }
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  :after {
    content: '';
    display: ${({ name, $pathname, $user }) =>
      $pathname.toLowerCase().includes(removeAccents(name.toLowerCase())) ||
      ($pathname.toLowerCase().includes('consolidacion-pedido') &&
        removeAccents(name.toLowerCase()) === 'coordinacion') ||
      ($pathname.toLowerCase().includes('detalle-proveedor') &&
        removeAccents(name.toLowerCase()) === 'comprar') ||
      (($user.rol.id_rol === '2' || $user.rol.id_rol === '3') &&
        name.toLowerCase() === 'mis pedidos' &&
        (location.pathname.includes('/pedidos') ||
          location.pathname.includes('/mis-pedidos'))) ||
      (name.toLowerCase() === 'pedidos' &&
        location.pathname.includes('/comprobantes'))
        ? 'block'
        : 'none'};
    width: auto;
    height: 2px;
    margin-top: 4px;
    background-color: ${({ theme }) => theme.colors.primary.blue};
  }
`

const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  color: initial;
  &:visited {
    color: initial;
  }
`
const NavbarOptions = ({ options, className }) => {
  const location = useLocation()
  const { user } = useAuth()
  return (
    <StyledNavbarOptions className={className}>
      {options.map((option) => {
        return (
          <NavItem
            key={option.id}
            name={option.name}
            $pathname={location.pathname.slice(1)}
            $user={user}
          >
            <Link href={option.url}>{option.name}</Link>
          </NavItem>
        )
      })}
    </StyledNavbarOptions>
  )
}

export default NavbarOptions
