import useAxiosInstance from './axiosInterceptor'
const axios = useAxiosInstance
import { queryString } from 'utils/textProcess'

const ArticulosService = {
  getArticulos: async ({ idCronograma, idProveedor, idCarrito, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_carrito: parseInt(idCarrito),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/articulos?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)

      .then(({ data }) => {
        if (data.data.reservaStock) {
          data.data.articulos.forEach((categoria) => {
            categoria.articulos.forEach((articulo) => {
              articulo.estado = '1'
            })
          })
        }

        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  getArticulosProveedor: async ({ idCronograma, idCarrito, idZona, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_zona: parseInt(idZona),
      id_carrito: parseInt(idCarrito),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/articulos-proveedor?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  getArticulo: async ({ idArticulo, idCarrito, idCronograma, token }) => {
    let data = {
      id_articulo: parseInt(idArticulo),
      id_carrito: parseInt(idCarrito),
      id_cronograma: parseInt(idCronograma),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/articulo?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  getPromocion: async ({ idCronograma, idProveedor, idCarrito, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_carrito: parseInt(idCarrito),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/articulos-promocion?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data.articulos
      })
      .catch((error) => {
        throw error
      })
  },
  getPromociones: async ({ idCronograma, idCarrito, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_carrito: parseInt(idCarrito),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/promociones?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then((resp) => {
        return resp.data.data
      })
      .catch((error) => {
        throw error
      })
  },
}

export default ArticulosService
