import React, { useState } from 'react'
import styled from 'styled-components'
import DatosPerfil from './DatosPerfil'
import { Settings, User, Users } from 'feather-icons-react/build/IconComponents'
import DatosAgrupacion from './DatosAgrupacion'
import DatosCuenta from './DatosCuenta'
import { useAuth } from 'contexts/AuthContext'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 40px;
  width: 100%;

  @media (max-width: 440px) {
    flex-direction: column;
    gap: 36px;
    padding: 20px;
    align-self: flex-start;
  }
`

const WrapperBox = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  height: fit-content;
  margin-right: 40px;
  border-radius: 20px;
`

const WrapperSelectors = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  float: left;
`

const Selector = styled.div`
  display: flex;
  gap: 12px;
  place-items: center;
  cursor: pointer;
  font-weight: bold;
  width: 236px;
  padding: 10px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary.darkBlue : theme.colors.neutral.darkGray};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 8px;
  margin-bottom: 10px;
`

const SectionWrapper = styled.div`
  float: right;
  width: 96%;
`

const MiPerfilPage = () => {
  const { user } = useAuth()
  const [pestañaActiva, setPestañaActiva] = useState('perfil')

  const cambiarPestaña = (pestaña) => {
    setPestañaActiva(pestaña)
  }

  return (
    <Container>
      <WrapperBox>
        <WrapperSelectors>
          <Selector
            isActive={pestañaActiva === 'perfil'}
            onClick={() => cambiarPestaña('perfil')}
          >
            <User />
            Datos de mi perfil
          </Selector>
          {user.rol.id_rol !== '3' && (
            <Selector
              isActive={pestañaActiva === 'agrupacion'}
              onClick={() => cambiarPestaña('agrupacion')}
            >
              <Users />
              Datos de agrupación
            </Selector>
          )}
          <Selector
            isActive={pestañaActiva === 'cuenta'}
            onClick={() => cambiarPestaña('cuenta')}
          >
            <Settings />
            Datos de cuenta
          </Selector>
        </WrapperSelectors>
      </WrapperBox>
      <SectionWrapper>
        {pestañaActiva === 'perfil' && <DatosPerfil />}
        {user.rol.id_rol !== '3' && pestañaActiva === 'agrupacion' && (
          <DatosAgrupacion />
        )}
        {pestañaActiva === 'cuenta' && <DatosCuenta />}
      </SectionWrapper>
    </Container>
  )
}

export default MiPerfilPage
