import React, { useState } from 'react'
import styled from 'styled-components'
import { H3, H4 } from 'components/Typography'
import { useAuth } from 'contexts/AuthContext'
import { Edit2, Lock, User } from 'feather-icons-react/build/IconComponents'
import Input from 'components/Input/Input'
import { Button } from 'components/index'
import DropdownNotification from 'components/Notification/DropdownNotification'
import { showToast } from 'components/Notification/ToastNotification'

const WrapperExpandabletables = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 20px;

  @media (max-width: 440px) {
    width: 96%;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  border-radius: 0 0 20px 20px;

  @media (max-width: 440px) {
    flex-direction: column;
    gap: 6px;
  }
`

const InfoContainerEdit = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 40%);
  border-radius: 0 0 20px 20px;

  @media (max-width: 440px) {
    display: flex;
    flex-direction: column;
  }
`

const DataWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  padding: 30px 40px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral.darkGray};

  @media (max-width: 440px) {
    padding: 18px 24px;
  }
`

const Data = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const Title = styled(H3)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const EditButton = styled(Edit2)`
  cursor: pointer;
`

const StyledInput = styled(Input)`
  border-radius: 8px;
  margin-top: 8px;
`

const DropdownNotificationCustom = styled(DropdownNotification)`
  margin: 0 0 24px;
`

const ButtonWrapper = styled.div`
  align-items: center;
  padding: 20px 40px 40px 40px;
  gap: 20px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  border-radius: 20px 20px 0 0;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral.gray}`};
`

const DatosCuenta = () => {
  const { user, updatePassword } = useAuth()
  const [editable, setEditable] = useState(false)
  const [error, setError] = useState(false)
  const [inputValues, setInputValues] = useState({
    contraseña: '',
    nuevaContraseña: '',
    repiteContraseña: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setError(false)
    setInputValues({
      ...inputValues,
      [name]: value,
    })
  }

  const handleEditToggle = () => {
    setEditable(!editable)
  }

  return (
    <div>
      <Title>Datos de cuenta</Title>
      <WrapperExpandabletables>
        <Header>
          <H4>Contraseña</H4>
          <EditButton onClick={handleEditToggle} />
        </Header>
        {editable ? (
          <>
            <InfoContainerEdit>
              <DataWrapper>
                <Data>
                  <div>Nombre de usuario</div>
                  <Content>
                    <StyledInput
                      border={true}
                      icon={<User size={20} />}
                      disabled={true}
                      value={user.operador.login}
                    />
                  </Content>
                </Data>
              </DataWrapper>
              <DataWrapper>
                <Data>
                  <div>Contraseña actual</div>
                  <Content>
                    <StyledInput
                      border={true}
                      icon={<Lock size={20} />}
                      name="contraseña"
                      onChange={handleChange}
                      password
                    />
                  </Content>
                </Data>
              </DataWrapper>
              <DataWrapper>
                <Data>
                  <div>Contraseña nueva</div>
                  <Content>
                    <StyledInput
                      border={true}
                      icon={<Lock size={20} />}
                      name="nuevaContraseña"
                      onChange={handleChange}
                      $error={error}
                      password
                    />
                  </Content>
                </Data>
              </DataWrapper>
              <DataWrapper>
                <Data>
                  <div>Confirmar contraseña</div>
                  <Content>
                    <StyledInput
                      border={true}
                      icon={<Lock size={20} />}
                      name="repiteContraseña"
                      onChange={handleChange}
                      $error={error}
                      password
                    />
                  </Content>
                </Data>
              </DataWrapper>
            </InfoContainerEdit>
            <ButtonWrapper>
              {error && (
                <DropdownNotificationCustom
                  text="Los campos de contraseña no coinciden"
                  type="error"
                />
              )}
              <Button
                text="Cambiar contraseña"
                type="primary"
                disabled={
                  inputValues.nuevaContraseña === '' ||
                  inputValues.repiteContraseña === ''
                }
                onClick={async () => {
                  if (
                    inputValues.nuevaContraseña === inputValues.repiteContraseña
                  ) {
                    await updatePassword(
                      inputValues.contraseña,
                      inputValues.nuevaContraseña,
                    )
                    showToast(`Contraseña actualizada!`, 'validacion')
                    setTimeout(function () {
                      window.location.reload(false)
                    }, 1000)
                  } else {
                    setError(true)
                  }
                }}
              />
            </ButtonWrapper>
          </>
        ) : (
          <InfoContainer>
            <DataWrapper>
              <User />
              <Data>
                <div>Nombre de usuario</div>
                <Content>{user.operador.login}</Content>
              </Data>
            </DataWrapper>
            <DataWrapper>
              <Lock />
              <Data>
                <div>Contraseña actual</div>
                <Content>********</Content>
              </Data>
            </DataWrapper>
          </InfoContainer>
        )}
      </WrapperExpandabletables>
    </div>
  )
}

export default DatosCuenta
