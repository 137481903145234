import { useAuth } from 'contexts/AuthContext'
import { Navigate, Outlet } from 'react-router-dom'
import Layout from 'pages/Layout'
import { useInitialData } from 'contexts/InitialDataContext'

export const ProtectedRoute = () => {
  const { token } = useAuth()
  const { loading, errorLoadingData } = useInitialData()

  if (!token && !loading && !errorLoadingData) {
    return <Navigate to="/login" />
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}
