import { H1, H2, P1 } from 'components/Typography'
import { Button } from 'components/index'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import LogoFaccCentral from 'components/images/LogoFaccCentral.jsx'

const Main = styled.section`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  gap: 18px;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 1199px) {
    flex-direction: column;
  }

  @media (max-width: 440px) {
    padding: 16px;
  }
`

const LeftContainer = styled.div`
  width: 50%;
  text-align: end;

  @media (max-width: 1199px) {
    width: 100%;
    text-align: center;
  }
`

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-left: 16px;

  @media (max-width: 1199px) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.blue};
  height: 80%;
  width: 2px;

  @media (max-width: 1199px) {
    width: 80%;
    height: 2px;
  }
`

const Logo = styled(LogoFaccCentral)`
  flex-shrink: 0;
  max-height: 30vh;
  width: auto;
  align-self: center;

  @media (max-width: 440px) {
    max-height: 16vh;
  }
`

const StyledP1 = styled(P1)`
  text-wrap: balance;
  margin-bottom: 24px;
`

const StyledButton = styled(Button)`
  width: min-content;
`

const Error404 = () => {
  const navigate = useNavigate()

  return (
    <Main>
      <LeftContainer>
        <Logo />
      </LeftContainer>
      <Line />
      <RightContainer>
        <H1>Error 404</H1>
        <H2>Página no encontrada</H2>
        <StyledP1>
          Lo sentimos, la página que estás buscando no pudo ser encontrada.
          <br />
          Por favor, verifica la URL o regresa a la página de Inicio
        </StyledP1>
        <StyledButton
          text="Ir al inicio"
          onClick={() => navigate('/')}
        ></StyledButton>
      </RightContainer>
    </Main>
  )
}

export default Error404
