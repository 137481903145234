import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import DistribuirPedidosTable from './DistribuirPedidosTable'
import { Button } from 'components/index'
import { usePedidos } from 'contexts/PedidosContext'
import { Copy, Trash2 } from 'feather-icons-react/build/IconComponents'
import ModalNotification from 'components/Notification/ModalNotification'
import { useModal } from 'contexts/ModalContext'
import { showToast } from 'components/Notification/ToastNotification'
import DropdownNotification from 'components/Notification/DropdownNotification'

const TableSection = styled.div`
  width: 100%;
`

const CustomExpandableTables = styled(ExpandableTables)`
  padding: 14px 44px;
  border: none;
  border-radius: 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 50px 30px;
`

const CustomDropdownNotification = styled(DropdownNotification)`
  margin: 20px;
`

function verificarCantidades(articulos, objetoComparacion) {
  let cantidadesSuma = {}

  for (let key in objetoComparacion) {
    for (let subKey in objetoComparacion[key]) {
      if (!cantidadesSuma[subKey]) {
        cantidadesSuma[subKey] = 0
      }
      cantidadesSuma[subKey] += objetoComparacion[key][subKey]
    }
  }

  let resultados = []

  articulos.forEach((item, index) => {
    let articuloId = item.id_articulo
    if (parseInt(cantidadesSuma[articuloId]) !== parseInt(item.cantidades)) {
      resultados.push(index)
    }
  })

  return resultados
}

function generarEncabezados(resultadoPedido) {
  if (resultadoPedido?.length > 0) {
    const encabezadosSet = new Set([
      'EAN',
      'Producto',
      'Pedidos',
      'Faltantes',
      'Recibidos',
    ])

    Object.keys(resultadoPedido).forEach((key) => {
      Object.values(resultadoPedido[key].asociadas).forEach((e) => {
        if (!encabezadosSet.has(e.nombre_comercial)) {
          encabezadosSet.add(e.nombre_comercial)
        }
      })
    })

    const encabezados = Array.from(encabezadosSet)

    return encabezados
  }
}

function formatData(pedidoAgrupacion) {
  const resultados = []
  if (pedidoAgrupacion?.detalle) {
    pedidoAgrupacion?.detalle?.forEach((det) => {
      const {
        ean13,
        descripcion,
        cant_pedido,
        cant_recibida,
        cantidades,
        id_articulo,
      } = det

      const recibidos = cant_recibida
      const faltantes =
        cant_pedido - recibidos > 0 ? cant_pedido - recibidos : 0

      const resultado = {
        ean: ean13,
        producto: descripcion,
        pedidos: cant_pedido,
        recibidos: cantidades,
        faltantes: faltantes,
        asociadas: {},
        id_articulo: id_articulo,
      }
      for (const key in pedidoAgrupacion.subasociadas) {
        const subasociada = pedidoAgrupacion.subasociadas[key]
        const pedidoSubasociado = subasociada.articulos.find(
          (articulo) => articulo.id == id_articulo,
        )

        const cant_pedido_subasociado = pedidoSubasociado
          ? pedidoSubasociado?.cant_pedido
          : 0
        const a_pedir = 0
        resultado.asociadas[subasociada.id_afiliado] = {
          nombre_comercial: subasociada.nombre_comercial,
          id_subasociada: subasociada.id_afiliado,
          id_articulo: pedidoSubasociado?.id || null,
          pedido: cant_pedido_subasociado,
          recibidos: recibidos,
          a_pedir: a_pedir,
        }
      }
      resultados.push(resultado)
    })
  }

  return resultados
}

function redistribuirValoresPedido(produ, articulosDivididos) {
  produ.forEach((item) => {
    const totalPedidos = parseInt(item.pedidos)
    const totalRecibidos = parseInt(item.recibidos)
    let totalRedistribuido = 0
    const keys = Object.keys(item.asociadas)

    keys.forEach((key) => {
      const asociada = item.asociadas[key]
      const pedido = parseInt(asociada.pedido) || 0
      const porcentaje = pedido / totalPedidos
      let monto = Math.floor(porcentaje * totalRecibidos)
      totalRedistribuido += monto
      if (asociada.id_articulo) {
        if (!articulosDivididos[key]) {
          articulosDivididos[key] = {}
        }
        if (!articulosDivididos[key][asociada.id_articulo]) {
          articulosDivididos[key][asociada.id_articulo] = 0
        }
        articulosDivididos[key][asociada.id_articulo] = monto
      }
    })

    if (totalRedistribuido !== totalRecibidos) {
      const diferencia = totalRecibidos - totalRedistribuido

      for (let i = 0; i < diferencia; i++) {
        for (const key of keys) {
          const asociada = item.asociadas[key]
          if (
            asociada.id_articulo &&
            articulosDivididos[key][asociada.id_articulo] < totalRecibidos
          ) {
            articulosDivididos[key][asociada.id_articulo] =
              articulosDivididos[key][asociada.id_articulo] + 1
            totalRedistribuido += 1
            if (totalRedistribuido === totalRecibidos) break
          }
        }
        if (totalRedistribuido === totalRecibidos) break
      }
    }
  })
  return articulosDivididos
}

const DistribuirPedidoPage = ({ goToNextStep }) => {
  const { recepcionPedido, distribuirPedido } = usePedidos()
  const { openModal, isModalOpen, closeModal } = useModal()
  const [articulosDivididos, setArticulosDivididos] = useState({})
  const [error, setError] = useState([])

  useEffect(() => {
    setArticulosDivididos({
      ...redistribuirValoresPedido(
        formatData(pedidoAgrupacion),
        articulosDivididos,
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pedidoAgrupacion = recepcionPedido.dataPaso.pedido

  async function confirmarDistribucion() {
    if (
      verificarCantidades(pedidoAgrupacion.detalle, articulosDivididos)
        .length === 0
    ) {
      try {
        await distribuirPedido(
          pedidoAgrupacion.id,
          recepcionPedido.dataPaso.ocRecepcion.id_cabecera_recepcion,
          articulosDivididos,
        )
        closeModal()
        goToNextStep()
        showToast(`¡La operación fue realizada con éxito!`, 'validacion')
      } catch (error) {
        console.log('error ', error)
      }
    } else {
      closeModal()
      setError(
        verificarCantidades(pedidoAgrupacion.detalle, articulosDivididos),
      )
    }
  }

  function asignarCeros(obj) {
    for (let clave1 in obj) {
      for (let clave2 in obj[clave1]) {
        obj[clave1][clave2] = '0'
      }
    }
    return obj
  }
  return (
    <TableSection>
      <CustomExpandableTables
        openTables={[pedidoAgrupacion.proveedor]}
        items={[
          {
            title: pedidoAgrupacion.proveedor,
            component: pedidoAgrupacion?.detalle && (
              <DistribuirPedidosTable
                data={formatData(pedidoAgrupacion)}
                headers={generarEncabezados(formatData(pedidoAgrupacion)) || {}}
                articulosDivididos={articulosDivididos}
                setArticulosDivididos={setArticulosDivididos}
                $error={error}
                setError={setError}
                validarErrores={() =>
                  setError(
                    verificarCantidades(
                      pedidoAgrupacion.detalle,
                      articulosDivididos,
                    ),
                  )
                }
              />
            ),
          },
        ]}
      />
      {error.length !== 0 && (
        <CustomDropdownNotification
          text="Hay errores en la distribución del pedido: comprobá que los montos
            cargados no excedan los pedidos o que no hayan quedado celdas sin
            completar"
          type="error"
        />
      )}
      <ButtonWrapper>
        <Button
          text={'Borrar cantidades cargadas'}
          type={'tertiary'}
          icon={<Trash2 size={14} />}
          onClick={() => {
            setArticulosDivididos({ ...asignarCeros(articulosDivididos) })
            showToast(`¡Se borraron los valores con éxito!`, 'validacion')
          }}
        />
        <Button
          text={'Redistribución automática'}
          type={'tertiary'}
          icon={<Copy size={14} />}
          onClick={() => {
            setArticulosDivididos({
              ...redistribuirValoresPedido(
                formatData(pedidoAgrupacion),
                articulosDivididos,
              ),
            })
            showToast(
              `¡Se redistribuyeron los valores con éxito!`,
              'validacion',
            )
          }}
        />
        <Button
          text={'Confirmar distribución'}
          type={'primary'}
          onClick={openModal}
        />
      </ButtonWrapper>
      {isModalOpen && (
        <ModalNotification
          type="notificacion"
          title="Estás por redistribuir el pedido"
          descripcion={[
            'Si seleccionas',
            ' CONTINUAR',
            ' confirmarás la redistribución. Si preferís controlar seleccioná',
            ' VOLVER Y REVISAR',
          ]}
          primaryFunction={confirmarDistribucion}
          secondaryFunction={closeModal}
          afterClose={() => {}}
        />
      )}
    </TableSection>
  )
}

export default DistribuirPedidoPage
