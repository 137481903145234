import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table/Table'
import { BtnS, H2 } from 'components/Typography'
import RemitoModal from '../../components/RemitoModal'
import HeaderTable from 'components/Table/HeaderTable'
import { useModal } from 'contexts/ModalContext'
import CurrencyFormat from 'react-currency-format'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledTableHeader = styled(H2)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin: 24px 0;
`

const ScrollableTable = styled(Table)`
  overflow-y: scroll;
  box-sizing: border-box;
  max-height: calc(80vh - 200px);
`

const StyledBtnS = styled(BtnS)`
  text-align: start;
`

const bottomHeader = [
  { name: 'EAN', isOrderable: false },
  { name: 'Producto', isOrderable: false },
  { name: 'Precio unitario', isOrderable: false },
  { name: 'Cant. de bultos', isOrderable: false },
  { name: 'Neto', isOrderable: false },
  { name: 'Subtotal con iva', isOrderable: false },
]

const getModalData = (data) => {
  return data.map((item) => ({
    ean: <BtnS>{item.ean13}</BtnS>,
    producto: <StyledBtnS>{item.descripcion}</StyledBtnS>,
    precioUnitario: (
      <CurrencyFormat
        value={parseFloat(item.precio_pedido)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    cantidadBultos: <BtnS>{item.cant_recibida}</BtnS>,
    subtotalSinIva: (
      <CurrencyFormat
        value={parseFloat(item.precio_pedido) * parseFloat(item.cant_recibida)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    subtotalConIva: (
      <CurrencyFormat
        value={
          parseFloat(item.precio_pedido) * parseFloat(item.cant_recibida) * 1.21
        }
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
  }))
}

const GenerarRemitoModal = () => {
  const { optionalData } = useModal()

  return (
    <RemitoModal loading={false}>
      <TableWrapper>
        <StyledTableHeader>Detalle de pedido</StyledTableHeader>
        <HeaderTable
          numerate={false}
          headers={bottomHeader}
          gridTemplate="1fr 2fr 1fr 1fr 1fr 1fr"
        />
        <ScrollableTable
          numerate={false}
          data={getModalData(optionalData)}
          gridTemplate="1fr 2fr 1fr 1fr 1fr 1fr"
        />
      </TableWrapper>
    </RemitoModal>
  )
}

export default GenerarRemitoModal
