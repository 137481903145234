import axios from 'axios'
import { showToast } from 'components/Notification/ToastNotification'

const axiosInstance = axios.create()
const controller = new AbortController()
const signal = controller.signal

axiosInstance.interceptors.request.use((config) => {
  config.signal = signal
  return config
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      controller.abort()
      localStorage.clear()
      showToast(
        `La sesión ha expirado por vencimiento de tiempo o porque se cerró en otro navegador.`,
        'error',
      )
      setTimeout(function () {
        window.location.reload()
      }, 3000)
    } else {
      return Promise.reject(error)
    }
  },
)

export default axiosInstance
