import React from 'react'
import styled from 'styled-components'
import {
  BtnL,
  BtnM,
  BtnS,
  Filtros,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P1,
  P2,
  P3,
  P4,
} from './Typography'
const Page = styled.div`
  margin: 20px;
`

const Components = () => {
  return (
    <Page>
      <h2>H</h2>

      <H1>Esto es un Texto en H1</H1>
      <br />
      <H2>Esto es un Texto en H2</H2>
      <br />
      <H3>Esto es un Texto en H3</H3>
      <br />
      <H4>Esto es un Texto en H4</H4>
      <br />
      <H5>Esto es un Texto en H5</H5>
      <br />
      <H6>Esto es un Texto en H6</H6>
      <br />

      <h2>P</h2>

      <P1>Esto es un Texto en P1</P1>
      <br />
      <P2>Esto es un Texto en P2</P2>
      <br />
      <P3>Esto es un Texto en P3</P3>
      <br />
      <P4>Esto es un Texto en P4</P4>
      <br />

      <h2>Otros</h2>

      <BtnL>Esto es un Texto en BtnL</BtnL>
      <br />
      <BtnM>Esto es un Texto en BtnM</BtnM>
      <br />
      <BtnS>Esto es un Texto en BtnS</BtnS>
      <br />
      <br />
      <Filtros>Esto es un Texto en Filtros</Filtros>
    </Page>
  )
}

export default Components

export { default as Button } from './Button/Button'
export { default as Input } from './Input/Input'
