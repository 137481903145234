import React from 'react'
import styled from 'styled-components'

const StyledIconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral.black};

  &[disabled] {
    color: ${({ theme }) => theme.colors.neutral.lightGray};
  }
`

const IconButton = React.forwardRef(({ icon, children, ...props }, ref) => {
  return (
    <StyledIconButton ref={ref} {...props}>
      {icon}
      {children}
    </StyledIconButton>
  )
})

IconButton.displayName = 'IconButton'

export default IconButton
