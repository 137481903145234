import React, { createContext, useContext, useState } from 'react'
import ProveedorService from 'services/proveedor'
import { useAuth } from './AuthContext'
import { useCarrito } from './CarritoContext'
import { useCronograma } from './CronogramaContext'

const ProveedorContext = createContext()

export const useProveedor = () => useContext(ProveedorContext)

export const ProveedorProvider = ({ children }) => {
  const { token } = useAuth()
  const { carritoAgrupado } = useCarrito()
  const [proveedorLoading, setProveedorLoading] = useState(false)
  const [currentProveedor, setCurrentProveedor] = useState({})
  const { cronograma } = useCronograma()

  const getProveedor = async (proveedorId) => {
    setProveedorLoading(true)
    if (proveedorId) {
      return await ProveedorService.getProveedor({
        token,
        idProveedor: proveedorId,
        idCarrito: carritoAgrupado?.cabecera?.id,
        idCronograma: cronograma.id_cronograma,
      })
        .then((resp) => {
          setCurrentProveedor(resp)
          return resp
        })
        .catch((error) => {
          console.error('Error al obtener los datos del proveedor:', error)
          throw error
        })
        .finally(() => setProveedorLoading(false))
    }
  }

  const contextValues = {
    currentProveedor,
    setCurrentProveedor,
    getProveedor,
    proveedorLoading,
    setProveedorLoading,
  }

  return (
    <ProveedorContext.Provider value={contextValues}>
      {children}
    </ProveedorContext.Provider>
  )
}
