import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import NotificationCenter from '../../components/Notification/NotificationCenter'
import CardCarousel from './components/CardCarousel'
import { H4 } from 'components/Typography'
import backgroundImg from 'assets/office.png'
import circleImg from 'assets/circulo_home.png'
import MessageWithAction from './components/MessageWithAction'
import BannerCarousel from './components/Banner'
import { banners } from 'utils/BannersMocks'
import { Wrapper } from 'components/layout/FlexComponents'
import AlertSection from './components/AlertSection/AlertSection'
import HorizontalCarousel from 'components/Carousel/HorizontalCarousel'
import HorizontalPromoCarousel from 'components/Carousel/HorizontalPromoCarousel'
import backgroundImgHome from 'assets/fondo_punto.svg'
import { useCronograma } from 'contexts/CronogramaContext'
import { orderByObjectKeyValue, shuffle } from 'utils/arrayFunctions'

const PedidosSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
`

const CronogramaText = styled(H4)`
  align-self: flex-start;
  margin: 20px 180px;
  z-index: 2;

  @media (max-width: 440px) {
    margin: 10px 40px;
  }
`

const ImageOverlay = styled.img`
  position: absolute;
  width: 100%;
  height: 25vh;
`

const WrapperHome = styled(Wrapper)`
  background-image: url(${backgroundImgHome});
  background-repeat: repeat;
  background-position: 5px 5px;
  background-size: auto;
`

const NotificationHome = styled(NotificationCenter)`
  padding: 0 10%;
  margin-top: 16px;
`

const MessageWithActionContainer = styled.div`
  @media (max-width: 440px) {
    display: none;
  }
`

const BannerContainer = styled.div`
  display: block;
  width: 100%;
  @media (max-width: 440px) {
    display: none;
  }
`

const HomeAsociadaIndividual = ({
  promocionesLoading,
  promociones,
  proveedoresLoading,
  proveedoresActivos,
  proveedoresDehabilitados,
  formatData,
  cronogramaHabilitado,
}) => {
  const { cronograma } = useCronograma()
  const navigate = useNavigate()

  return (
    <WrapperHome>
      <NotificationHome />
      <PedidosSection>
        <ImageOverlay src={circleImg} alt="Logo" />
        <CronogramaText>{`Cronograma N°${cronograma.id_cronograma}`}</CronogramaText>
        <CardCarousel />
        <MessageWithActionContainer>
          <MessageWithAction
            title="Hacé un control de tus pedidos"
            subtitle="Hacé un seguimiento de tus compras"
            action={() => navigate(`/pedidos/`)}
            textButton="Ver pedidos"
          />
        </MessageWithActionContainer>
      </PedidosSection>

      {!promocionesLoading &&
        promociones !== undefined &&
        promociones !== null &&
        Object.keys(promociones).length > 0 && (
          <HorizontalPromoCarousel
            title="Promociones"
            type="primary"
            cards={formatData(shuffle(promociones), 'promociones')}
            cronogramaHabilitado={cronogramaHabilitado}
          />
        )}

      {!proveedoresLoading &&
        proveedoresActivos &&
        Object.keys(proveedoresActivos).length > 0 && (
          <HorizontalCarousel
            title="Proveedores habilitados"
            cards={formatData(
              orderByObjectKeyValue(proveedoresActivos, 'puedePedir', true),
              'proveedores',
            )}
          />
        )}
      <BannerContainer>
        <BannerCarousel banners={banners} />
      </BannerContainer>

      {!proveedoresLoading &&
        proveedoresDehabilitados &&
        Object.keys(proveedoresDehabilitados).length > 0 && (
          <AlertSection
            proveedores={formatData(proveedoresDehabilitados, 'alertSection')}
          />
        )}
    </WrapperHome>
  )
}

export default HomeAsociadaIndividual
