import { BtnL } from 'components/Typography'
import React from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  cursor: pointer;
`

const LinkBig = ({ className, href, onClick, children, target }) => (
  <StyledLink
    href={href}
    className={className}
    onClick={onClick}
    target={target}
  >
    <BtnL>{children}</BtnL>
  </StyledLink>
)

export default LinkBig
