import Modal from 'components/Modal/Modal'
import React from 'react'

const formatNotification = {
  error: {
    type: 'error',
    icon: 'slash',
  },
  validacion: {
    type: 'success',
    icon: 'check',
  },
  alerta: {
    type: 'alert',
    icon: 'alert',
  },
  notificacion: {
    type: 'info',
    icon: 'bell',
  },
}

const ModalNotification = ({
  type,
  title,
  descripcion,
  primaryFunction,
  secondaryFunction,
  afterClose,
  customCloseModal,
}) => {
  const content = {
    type: formatNotification[type].type,
    icon: formatNotification[type].icon,
    title,
    text: descripcion,
    primaryButton: {
      function: primaryFunction,
      text: 'Continuar',
    },
    secondaryButton: {
      function: secondaryFunction,
      text: 'Volver y revisar',
    },
    afterClose,
    customCloseModal,
  }
  return <Modal content={content} />
}

export default ModalNotification
