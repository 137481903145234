import React, { createContext, useContext, useEffect, useState } from 'react'

const WindowWidthContext = createContext()

export const useWindowWidth = () => useContext(WindowWidthContext)

export const WindowWidthProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValues = {
    width,
  }

  return (
    <WindowWidthContext.Provider value={contextValues}>
      {children}
    </WindowWidthContext.Provider>
  )
}
