import React from 'react'
import styled from 'styled-components'

import { Row } from 'components/layout/FlexComponents'
import Table, { TableRow } from 'components/Table/Table'
import { P4 } from 'components/Typography'
import { FileText } from 'feather-icons-react/build/IconComponents'
import { Button } from 'components/index'
import { usePedidos } from 'contexts/PedidosContext'
import LinkUnderline from 'components/Button/LinkUnderline'
import { useNavigate } from 'react-router-dom'
import Loading from 'components/Loading/Loading'
import HeaderTable from 'components/Table/HeaderTable'
import StatusTag from 'components/Tag/StatusTag'
import {
  ESTADOS_DESHABILITAR_RECEPCION_STR,
  ESTADOS_OCULTAR_COMPROBANTES_STR,
} from 'utils/EstadosPedidos'
import dayjs from 'dayjs'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;
`

const DataBoxNumberLink = styled(LinkUnderline)`
  color: ${({ theme }) => theme.colors.neutral.black};
`

const Nombre = styled(P4)`
  font-weight: bold;
`

const ComprobanteIcon = styled(FileText)`
  width: 18px;
  height: 18px;
  margin-right: 6px;
  cursor: pointer;
`

const PercentageIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid
    ${({ theme, $porcentajeRecepcion }) =>
      $porcentajeRecepcion === 0
        ? theme.colors.neutral.gray
        : $porcentajeRecepcion === 100
          ? theme.colors.alarmsStatuses.green
          : $porcentajeRecepcion > 0
            ? theme.colors.alarmsStatuses.yellow
            : theme.colors.primary.lightBlue};
  background-color: ${({ theme, $porcentajeRecepcion }) =>
    $porcentajeRecepcion === 100
      ? theme.colors.alarmsStatuses.green
      : theme.colors.neutral.white};
  color: ${({ theme, $porcentajeRecepcion }) =>
    $porcentajeRecepcion === 0
      ? theme.colors.neutral.gray
      : $porcentajeRecepcion === 100
        ? theme.colors.neutral.white
        : $porcentajeRecepcion > 0
          ? theme.colors.alarmsStatuses.yellow
          : theme.colors.primary.lightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Roboto-bold';
`

const CustomHeader = styled(HeaderTable)`
  @media (min-width: 440px) and(max-width: 1366px) {
    grid-template-columns: 10% 12% 21% 10% 12% 11% 12% 3%;
    padding-left: 8px;
  }
`

const CustomTable = styled(Table)`
  ${TableRow} {
    @media (min-width: 440px) and(max-width: 1366px) {
      grid-template-columns: 10% 12% 21% 10% 12% 11% 12% 3%;
      padding-left: 8px;
    }
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonIrAComprar = styled(Button)`
  width: 116px;
  padding: 8px 20px;
`

const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const AccionablesContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
`

const StyledComprobanteIcon = styled(ComprobanteIcon)`
  margin-right: 0;
`

const RecepcionButton = styled(Button)`
  min-width: 45px;
`

const Porcentaje = ({ value }) => {
  return (
    <PercentageIcon $porcentajeRecepcion={value}>{value + '%'}</PercentageIcon>
  )
}

const formatData = (
  pedidosAgrupacion,
  onClickComprobantesDetalle,
  handleOnClickRecepcion,
  onClickPedidoDescarga,
  navigate,
  width,
) =>
  pedidosAgrupacion?.pedidos.map((pedido) => ({
    OC: (
      <DataBoxNumberLink
        onClick={() =>
          navigate(`/pedidos-agrupacion/OC-${pedido.ordenDeCompra}`, {
            state: pedido.id,
          })
        }
      >
        {pedido.ordenDeCompra}
      </DataBoxNumberLink>
    ),
    Estado: (
      <StatusTag
        estado={
          pedido?.estado?.descripcion
          /* : pedido?.estado?.descripcion */
        }
      />
    ),
    Operador: (
      <ProveedoresItem key={pedido.nombre}>
        <Nombre>{pedido.proveedor}</Nombre>
      </ProveedoresItem>
    ),
    ...(width > 440 && {
      Fecha: <P4>{dayjs(pedido.fecha_confeccion).format('DD/MM/YYYY')}</P4>,
      Logistica: <P4>{pedido.entrega}</P4>,
      ...(pedido.tipo_proveedor === '1' ||
      ESTADOS_DESHABILITAR_RECEPCION_STR.includes(
        pedido?.estado?.descripcion?.toLowerCase(),
      )
        ? { PorcentajeRecepcion: <></> }
        : {
            PorcentajeRecepcion: (
              <Porcentaje value={pedido.porcentajeRecepcion} />
            ),
          }),
      Recepción: (
        <>
          {pedido.tipo_proveedor !== '1' && (
            <RecepcionButton
              type="primary"
              size="small"
              text={'Recepción'}
              disabled={
                pedido.tipo_proveedor === '1' ||
                pedido.porcentajeRecepcion === 100 ||
                ESTADOS_DESHABILITAR_RECEPCION_STR.includes(
                  pedido?.estado?.descripcion?.toLowerCase(),
                )
              }
              onClick={() =>
                handleOnClickRecepcion(pedido.id, pedido.ordenDeCompra)
              }
            />
          )}
        </>
      ),
    }),
    Accionables: !ESTADOS_OCULTAR_COMPROBANTES_STR.includes(
      pedido?.estado?.descripcion?.toLowerCase(),
    ) && (
      <AccionablesContainer>
        <StyledComprobanteIcon
          onClick={() =>
            onClickComprobantesDetalle(
              pedido.ordenDeCompra,
              pedido.id,
              pedido.tipo_proveedor,
            )
          }
        />
      </AccionablesContainer>
    ),
  }))

const PedidosAgrupacionTable = ({
  onClickPedidoDescarga,
  handleSetActiveOrder,
  activeOrder,
}) => {
  const navigate = useNavigate()
  const { width } = useWindowWidth()
  const { pedidosAgrupacion, pedidosLoading, setRecepcionPedido } = usePedidos()

  const onClickComprobantesDetalle = async (
    oc,
    idOcCompraCoordinadora,
    tipoProveedor,
  ) => {
    navigate(`/pedidos-agrupacion/comprobantes/${oc}`, {
      state: { idOcCompraCoordinadora, tipoProveedor },
    })
  }

  const handleOnClickRecepcion = (id, ordenCompra) => {
    setRecepcionPedido({})
    localStorage.setItem('recepcionPedido', {})
    navigate(`/pedidos/recepcion/${id}`, {
      state: { ordenCompra },
    })
  }

  const headers = [
    {
      name: 'OC',
      tag: 'ordenDeCompra',
    },
    {
      name: 'Estado',
      tag: 'estado',
    },
    {
      name: 'Proveedor',
      tag: 'proveedor',
    },
    ...(width > 440
      ? [
          {
            name: 'Fecha',
            tag: 'fecha_confeccion',
          },
          {
            name: 'Retiro',
            tag: 'entrega',
          },
          {
            name: 'Recepción',
            tag: 'recepcion',
          },
          {
            name: '',
            tag: '',
          },
        ]
      : []),
    {
      name: '',
      tag: '',
    },
  ]

  return (
    <TableWrapper>
      {pedidosLoading ? (
        <Loading />
      ) : pedidosAgrupacion?.pedidos?.length > 0 ? (
        <TableWrapper>
          <CustomHeader
            headers={headers}
            gridTemplate={
              width > 440
                ? width > 1366
                  ? '8% 12% 18% 10% 10% 8% 12% 5%'
                  : '8% 12% 20% 10% 10% 8% 15% 5%'
                : '22% 24% 28% 10%'
            }
            activeOrder={activeOrder}
            handleSetActiveOrder={handleSetActiveOrder}
            camposOrden={pedidosAgrupacion.pager.campos_orden}
          />
          <CustomTable
            data={formatData(
              pedidosAgrupacion,
              onClickComprobantesDetalle,
              handleOnClickRecepcion,
              onClickPedidoDescarga,
              navigate,
              width,
            )}
            gridTemplate={
              width > 440
                ? width > 1366
                  ? '8% 12% 18% 10% 10% 8% 12% 5%'
                  : '8% 12% 20% 10% 10% 8% 15% 5%'
                : '22% 24% 28% 10%'
            }
          />
        </TableWrapper>
      ) : (
        <TableWrapper>
          <EmptyPage>
            <EmptyTableData>No hay pedidos</EmptyTableData>
            <ButtonIrAComprar
              onClick={() => navigate('/comprar')}
              type="secondary"
              text="Ir a Comprar"
              size="small"
            />
          </EmptyPage>
        </TableWrapper>
      )}
    </TableWrapper>
  )
}

export default PedidosAgrupacionTable
