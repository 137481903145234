import React from 'react'
import styled from 'styled-components'
import { Wrapper } from 'components/layout/FlexComponents'
import { terminosYCondiciones } from 'utils/TermsConditionsMocks'
import { H1, H2, P1 } from 'components/Typography'

const TerminosCondicionesWrapper = styled(Wrapper)`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
`

const StyledH1 = styled(H1)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  font-size: 30px;
  align-self: start;
  padding: 48px 0 32px;
`

const StyledH2 = styled(H2)`
  font-size: 20px;
  margin-bottom: 16px;
`

const StyledP1 = styled(P1)`
  line-height: 1.5;
  margin-bottom: 16px;
`

const TerminosContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 48px;
  flex-wrap: wrap;
  margin-bottom: 60px;
  @media screen and (max-width: 600px) {
    max-height: initial;
    flex-wrap: nowrap;
  }
`

const SubsectionContainer = styled.div`
  margin-top: 16px;
`

const HorizontalLine = styled.hr`
  color: ${({ theme }) => theme.colors.primary.blue};
  width: 90%;
  margin-top: 32px;
`

const TerminosCondiciones = () => {
  return (
    <TerminosCondicionesWrapper>
      <StyledH1>{terminosYCondiciones.title}</StyledH1>
      <TerminosContainer>
        {terminosYCondiciones.sections.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <StyledH2>{section.title}</StyledH2>
            {section.description && <StyledP1>{section.description}</StyledP1>}

            {section.subsections &&
              section.subsections.map((subsection, subsectionIndex) => (
                <SubsectionContainer key={subsectionIndex}>
                  <StyledH2>{subsection.title}</StyledH2>
                  {subsection.content.map((text, textIndex) => (
                    <StyledP1 key={textIndex}>{text}</StyledP1>
                  ))}
                </SubsectionContainer>
              ))}

            {section.content &&
              section.content.map((text, contentIndex) => (
                <StyledP1 key={contentIndex}>{text}</StyledP1>
              ))}

            {sectionIndex < terminosYCondiciones.sections.length - 1 && (
              <HorizontalLine />
            )}
          </div>
        ))}
      </TerminosContainer>
    </TerminosCondicionesWrapper>
  )
}

export default TerminosCondiciones
