import React from 'react'
import Modal from './Modal'
import { useModal } from 'contexts/ModalContext'
import { useCarrito } from 'contexts/CarritoContext'
import { showToast } from 'components/Notification/ToastNotification'

const CancelarComprarEnCeroModal = ({ idProveedor, resetFilter }) => {
  const { closeModal } = useModal()
  const { cancelarComprarEnOtraOcasion, loading } = useCarrito()

  const content = {
    type: 'info',
    icon: 'bell',
    title: '¿Estás seguro queres volver a comprar?',
    text: [
      'Si seleccionas ',
      'CONFIRMAR ',
      'podras volver a comprar en este proveedor',
    ],
    primaryButton: {
      function: () => {
        cancelarComprarEnOtraOcasion(idProveedor)
        showToast('El proveedor fue activado de manera exitosa', 'validacion')
        closeModal()
        resetFilter()
      },
      text: 'Confirmar',
    },
    secondaryButton: {
      function: () => {
        closeModal()
      },
      text: 'Volver y revisar',
    },
    loading,
  }
  return <Modal content={content} idProveedor={idProveedor}></Modal>
}

export default CancelarComprarEnCeroModal
