import React, { useState } from 'react'
import styled from 'styled-components'
import { Filter, X } from 'feather-icons-react/build/IconComponents'
import IconButton from 'components/Button/IconButton'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const MobileFilterWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
`

const MobileFilterPanel = styled.div`
  width: 80%;
  height: 100%;
  background-color: white;
  padding: 20px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  flex-direction: column;
  overflow-y: auto;
`

const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const FilterIconButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  margin: 14px 10px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const MobileFilterComponent = ({ children }) => {
  const { width } = useWindowWidth()
  const [showMobileFilters, setShowMobileFilters] = useState(false)

  return width > 440 ? (
    <>{children}</>
  ) : (
    <>
      {!showMobileFilters && (
        <FilterIconButton
          icon={<Filter />}
          $message=""
          onClick={() => setShowMobileFilters(true)}
        />
      )}

      {showMobileFilters && (
        <MobileFilterWrapper>
          <MobileFilterPanel>
            <CloseButton onClick={() => setShowMobileFilters(false)}>
              <X size={24} />
            </CloseButton>
            {children}
          </MobileFilterPanel>
        </MobileFilterWrapper>
      )}
    </>
  )
}

export default MobileFilterComponent
