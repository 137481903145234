import MessageWithAction from 'pages/home/components/MessageWithAction'

export const banners = [
  {
    element: (
      <MessageWithAction
        title="Central de compras FACC"
        subtitle=""
        action={() => {}}
        navigateTo="/comprar"
        textButton="Comprar"
      />
    ),
  },
  {
    element: (
      <MessageWithAction
        title="Central de compras FACC"
        subtitle=""
        action={() => {}}
        navigateTo="/comprar"
        textButton="Comprar"
      />
    ),
  },
  {
    element: (
      <MessageWithAction
        title="Central de compras FACC"
        subtitle=""
        action={() => {}}
        navigateTo="/comprar"
        textButton="Comprar"
      />
    ),
  },
]
