import React from 'react'
import Modal from './Modal'
import { useModal } from 'contexts/ModalContext'
import { usePedidos } from 'contexts/PedidosContext'
import { useNavigate } from 'react-router-dom'
import RotatingLoading from 'components/Loading/Loading'
import { showToast } from 'components/Notification/ToastNotification'

const EditPedidoModal = ({ idCronograma, idProveedor, afterClose }) => {
  const { closeModal } = useModal()
  const { cancelarConfirmacionCarrito, editPedidosLoading } = usePedidos()
  const navigate = useNavigate()

  const content = {
    type: 'info',
    icon: 'bell',
    title: '¿Estás seguro de editar el pedido?',
    text: ['Si seleccionas ', 'CONFIRMAR ', 'el pedido volverá al carrito.'],
    primaryButton: {
      function: async () => {
        await cancelarConfirmacionCarrito(idCronograma, idProveedor)
        showToast(`El pedido volvió al carrito`, 'validacion')
        closeModal()
        navigate(`/mi-carrito`)
        afterClose()
      },
      text: editPedidosLoading ? <RotatingLoading size="large" /> : 'Confirmar',
    },
    secondaryButton: {
      function: () => {
        closeModal()
        afterClose()
      },
      text: 'Cancelar',
    },
    afterClose,
  }
  return <Modal content={content} />
}

export default EditPedidoModal
