import React from 'react'
import styled from 'styled-components'

const StyledTag = styled.div`
  display: inline-block;
  cursor: default;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  background-color: ${({ type, theme }) =>
    type === 'primary'
      ? theme.colors.primary.blue
      : type === 'secondary'
        ? theme.colors.neutral.lighterGray
        : theme.colors.primary.lightBlue};
  color: ${({ type, theme }) =>
    type === 'primary' || type === 'tertiary'
      ? theme.colors.neutral.white
      : theme.colors.primary.darkBlue};
`

const Tag = ({ children, className, type, title }) => {
  return (
    <StyledTag type={type} className={className} title={title}>
      {children}
    </StyledTag>
  )
}

export default Tag
