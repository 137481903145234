import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import IconButton from 'components/Button/IconButton'
import { Search } from 'feather-icons-react/build/IconComponents'
import styled from 'styled-components'
import SearchResultsList from './SearchResultsList'
import BusquedaService from 'services/busqueda'
import axios from 'axios'
import { useCronograma } from 'contexts/CronogramaContext'
import { useCarrito } from 'contexts/CarritoContext'
import { useAuth } from 'contexts/AuthContext'
import RotatingLoader from 'components/Loading/RotatingLoader'

const StyledContainer = styled.div`
  position: relative;
  height: 32px;
`

const Spacer = styled.div`
  width: 32px;
  height: 32px;
`

const StyledTextInput = styled.input`
  position: absolute;
  top: -3px;
  right: 4px;
  font-size: 1rem;
  border-radius: 16px;
  padding: 6px 12px;
  padding-left: 32px;
  z-index: 999;
  border: none;
  width: 320px;
  height: 32px;
  box-sizing: border-box;
  border: #0062ff 2px solid;
  &:focus,
  &:active {
    border: ${({ theme }) => theme.colors.primary.blue} 3px solid;
    outline: none;
  }
  @media (max-height: 768px) {
    top: -2px;
  }
  @media (max-width: 979px) {
    right: 0px;
  }
`

const StyledSearchIcon = styled(Search)`
  position: absolute;
  top: calc(50% - 3px);
  right: 300px;
  transform: translateY(-50%);
  width: 16px;
  z-index: 999999;
  @media (max-height: 768px) {
    top: calc(50% - 2px);
  }
  @media (max-width: 979px) {
    right: 294px;
  }
`

const StyledRotatingLoader = styled(RotatingLoader)`
  position: absolute;
  right: 16px;
  top: calc(50% - 6px);
  background-color: white;
  z-index: 999999;
  @media (max-height: 768px) {
    top: calc(50% - 6px);
  }
  @media (max-width: 979px) {
    left: 10px;
  }
`

const CollapsableSearchbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const searchbarButtonRef = useRef(null)
  const searchbarRef = useRef(null)
  const inputRef = useRef(null)
  const [results, setResults] = useState([])
  const cronograma = useCronograma()
  const carrito = useCarrito()
  const user = useAuth()
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [debounceTimeout, setDebounceTimeout] = useState(null)

  const resetSearch = () => {
    setIsOpen(false)
    setResults([])
    setSearchValue('')
  }

  const openSearchBar = () => {
    resetSearch()
    setIsOpen(true)
  }
  const handleClickOutside = () => {
    if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
      resetSearch()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isOpen && inputRef?.current) {
      inputRef?.current.focus()
    }
  }, [isOpen])

  useEffect(() => {
    setIsSearching(true)
    const source = axios.CancelToken.source()
    const fetchArticulos = async (
      idCronograma,
      idCarrito,
      searchValue,
      token,
    ) => {
      setLoading(true)
      try {
        const response = await BusquedaService.buscar({
          idCronograma,
          idCarrito,
          searchValue,
          token,
        })
        setLoading(false)
        setIsSearching(false)
        return response
      } catch (error) {
        if (axios.isCancel(error)) {
          setLoading(false)
          console.log('Solicitud previa cancelada:', error.message)
        } else {
          setLoading(false)
          console.error('Error al obtener los pedidos de agrupación:', error)
        }
        setLoading(false)
        return null
      }
    }

    const idCronograma = cronograma?.cronograma?.id_cronograma
    const idCarrito = carrito?.carritoAgrupado?.cabecera?.id
    const token = user?.token

    const fetchData = async () => {
      if (isOpen && inputRef?.current) {
        if (searchValue && idCronograma && idCarrito) {
          const articulosEncontrados = await fetchArticulos(
            idCronograma,
            idCarrito,
            searchValue,
            token,
          )
          if (articulosEncontrados) {
            setResults(articulosEncontrados)
          }
        }
      }
    }
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const timeoutId = setTimeout(() => {
      fetchData()
    }, 500)

    setDebounceTimeout(timeoutId)

    return () => {
      source.cancel('Operación cancelada debido a una nueva solicitud.')
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, searchValue, cronograma, carrito, user])

  return (
    <StyledContainer ref={searchbarRef}>
      {isOpen ? (
        <>
          <StyledTextInput
            ref={inputRef}
            onChange={(e) => setSearchValue(e.target.value)}
            $isOpen={isOpen}
            $finishedSearching={!loading && !isSearching}
            type="text"
            placeholder="Buscar producto"
          />
          {loading && <StyledRotatingLoader />}
          <Spacer className="spacer"></Spacer>
          <StyledSearchIcon icon={<Search size={16} />} />
          {!loading && !isSearching && (
            <SearchResultsList results={results} resetSearch={resetSearch} />
          )}
        </>
      ) : (
        <IconButton
          icon={<Search />}
          $message=""
          onClick={() => openSearchBar()}
          ref={searchbarButtonRef}
        />
      )}
    </StyledContainer>
  )
}

export default CollapsableSearchbar
