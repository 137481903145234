import React from 'react'
import styled from 'styled-components'
import Table from 'components/Table/Table'
import { BtnS } from 'components/Typography'
import HeaderTable from 'components/Table/HeaderTable'
import { Input } from 'components/index'
import CurrencyFormat from 'react-currency-format'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ScrolleableTable = styled(Table)`
  width: 100%;
  height: 50vh;
  overflow: scroll;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TextWrapContainer = styled(BtnS)`
  text-wrap: balance;
  line-break: anywhere;
`

const StyledInput = styled(Input)`
  border-radius: 8px;
  width: fit-content;
  // saca las flechas del input type=number
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`

const formatData = (
  pedidos,
  articulosRecibidos,
  handleChange,
  $error,
  setError,
) =>
  pedidos?.map((item, index) => ({
    Ean: <BtnS>{item.ean13}</BtnS>,
    Producto: <TextWrapContainer>{item.descripcion}</TextWrapContainer>,
    Precio: (
      <BtnS>
        <CurrencyFormat
          value={item.precio_pedido.replace('.', ',')}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          decimalScale={3}
          fixedDecimalScale={true}
        />
      </BtnS>
    ),
    Pedidos: <BtnS>{item.cant_pedido}</BtnS>,
    Recepcionados: <BtnS>{item.cant_recibida}</BtnS>,
    Recibidos: (
      <StyledInput
        $error={$error.includes(index)}
        type="number"
        border={true}
        value={articulosRecibidos[item.id_articulo]}
        onChange={(event) => {
          const inputValue = event.target.value
          if (inputValue === '') {
            if (!$error.includes(index)) {
              setError([...$error, index])
            }
          } else if ($error.includes(index)) {
            setError($error.filter((errorIndex) => errorIndex !== index))
          }

          handleChange(item.id_articulo, event.target.value)
        }}
      />
    ),
    Facturados: <BtnS>{item.cant_factura}</BtnS>,
    SubtotalSinIva: (
      <BtnS>
        <CurrencyFormat
          value={item.neto_pedido.replace('.', ',')}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          decimalScale={3}
          fixedDecimalScale={true}
        />
      </BtnS>
    ),
    SubtotalConIva: (
      <BtnS>
        <CurrencyFormat
          value={item.total_pedido.replace('.', ',')}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          prefix={'$'}
          decimalScale={3}
          fixedDecimalScale={true}
        />
      </BtnS>
    ),
  }))

const headers = [
  {
    name: 'EAN',
    isOrderable: false,
  },
  {
    name: 'Producto',
    isOrderable: false,
  },
  {
    name: 'Precio unitario',
    isOrderable: false,
  },
  {
    name: 'Bultos pedidos',
    isOrderable: false,
  },
  {
    name: 'Recepción parcial',
    isOrderable: false,
  },
  {
    name: 'Bultos recibidos',
    isOrderable: false,
  },
  {
    name: 'Bultos facturados',
    isOrderable: false,
  },
  {
    name: 'Neto',
    isOrderable: false,
  },
  {
    name: 'Subtotal con IVA',
    isOrderable: false,
  },
]

const RecepcionTable = ({
  data,
  articulosRecibidos,
  setArticulosRecibidos,
  $error,
  setError,
}) => {
  const handleChange = (id, cantidad) => {
    const updatedArticulosRecibidos = { ...articulosRecibidos }
    Object.keys(updatedArticulosRecibidos).forEach((key) => {
      if (key === id) {
        updatedArticulosRecibidos[key] = parseInt(cantidad)
      }
    })
    setArticulosRecibidos(updatedArticulosRecibidos)
  }

  const tableFormat =
    articulosRecibidos !== undefined
      ? formatData(data, articulosRecibidos, handleChange, $error, setError)
      : null

  return (
    <>
      {data?.length > 0 ? (
        <TableWrapper>
          <HeaderTable
            headers={headers}
            gridTemplate="10% 15% 10% 8% 8% 8% 8% 10% 10%"
            numerate={false}
          />
          <ScrolleableTable
            data={tableFormat}
            gridTemplate="10% 15% 10% 8% 8% 8% 8% 10% 10%"
            numerate={false}
          />
        </TableWrapper>
      ) : (
        <EmptyTableData>No hay datos</EmptyTableData>
      )}
    </>
  )
}

export default RecepcionTable
