import { Loader } from 'feather-icons-react/build/IconComponents'
import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const RotatingLoader = styled(Loader)`
  margin: -4px;
  height: 16px;
  width: 16px;
  animation: ${spinAnimation} 2s linear infinite;
`

export default RotatingLoader
