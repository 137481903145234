import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from 'feather-icons-react/build/IconComponents'
import React from 'react'
import styled from 'styled-components'

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 440px) {
    gap: 3px;
  }
`

const PageNumber = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary.darkBlue : theme.colors.neutral.white};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.neutral.white : theme.colors.primary.darkBlue};
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? 'transparent' : theme.colors.neutral.gray};
  font-size: 12px;
  font-weight: 500;

  &:hover {
    background-color: ${({ selected, theme }) =>
      selected
        ? theme.colors.primary.darkBlue
        : theme.colors.neutral.lighterGray};
  }

  @media (max-width: 440px) {
    width: 10px;
    height: 10px;
    padding: 8px;
  }
`

const generatePageRange = (currentPage, totalPages, maxPagesToShow) => {
  if (totalPages < maxPagesToShow) {
    return Array.from({ length: totalPages }, (_, index) => index + 1)
  }

  if (totalPages <= maxPagesToShow) {
    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }

  const middleIndex = Math.floor(maxPagesToShow / 2)
  const leftPages = Math.min(currentPage - 1, middleIndex)
  const rightPages = Math.min(totalPages - currentPage, middleIndex)

  let pages = []

  for (let i = currentPage - leftPages; i <= currentPage + rightPages; i++) {
    pages.push(i)
  }

  return pages
}

const Pagination = ({ totalPages, currentPage, onPageChange, className }) => {
  const maxPagesToShow = 3
  const pageNumbers = generatePageRange(currentPage, totalPages, maxPagesToShow)
  const handlePageClick = (pageNumber) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber)
    }
  }

  return (
    <PaginationContainer className={className}>
      {currentPage > 1 && totalPages > 3 && (
        <>
          <PageNumber onClick={() => handlePageClick(1)}>
            <ChevronsLeft />
          </PageNumber>
          <PageNumber onClick={() => handlePageClick(currentPage - 1)}>
            <ChevronLeft />
          </PageNumber>
        </>
      )}
      {pageNumbers.map((pageNumber) => (
        <PageNumber
          key={pageNumber}
          $clickable={pageNumber !== '...' && totalPages > 1}
          selected={pageNumber === currentPage}
          onClick={() => {
            pageNumber !== '...' &&
              totalPages > 1 &&
              handlePageClick(pageNumber)
          }}
        >
          {pageNumber}
        </PageNumber>
      ))}
      {currentPage < totalPages && totalPages > 3 && (
        <>
          <PageNumber onClick={() => handlePageClick(currentPage + 1)}>
            <ChevronRight />
          </PageNumber>
          <PageNumber onClick={() => handlePageClick(totalPages)}>
            <ChevronsRight />
          </PageNumber>
        </>
      )}
    </PaginationContainer>
  )
}

export default Pagination
