import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'components/index'
import ExpandableTablePedidos from './ExpandableTablePedidos'
import HeaderInfo from './HeaderInfo'

const WrapperDisplayAsociadasIndividuales = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const CardsWrapper = styled.div`
  width: 100%;
`

const TableFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
  padding: 20px;
  margin: 30px;
  width: -webkit-fill-available;
`

const DetallePedido = ({ proveedor, goToNextStep }) => {
  const [inputs, setInputs] = useState({
    oc_grupo: '',
    id_zona: 1,
    observacion: '',
    id_entrega: 0,
    fecha_vto: '',
  })

  const transporte = [
    {
      nombre: 'Buenos Aires',
      id: 1,
    },
    {
      nombre: 'Fábrica',
      id: 2,
    },
    {
      nombre: 'Bahia Blanca',
      id: 3,
    },
  ]

  const dataConsolidacion = {
    inputs,
    setInputs,
    transporte,
  }

  return (
    <WrapperDisplayAsociadasIndividuales>
      <HeaderInfo proveedor={proveedor} />

      <CardsWrapper>
        <ExpandableTablePedidos
          data={proveedor}
          dataConsolidacion={dataConsolidacion}
        />
      </CardsWrapper>

      <TableFooter>
        <Button
          type="primary"
          text="Siguiente"
          disabled={
            proveedor.enConsolidacion ||
            proveedor.consolidado ||
            !proveedor.afiliados ||
            proveedor.afiliados.length === 0 ||
            proveedor.afiliados.every(
              (afiliado) => afiliado.estadoCarritos === 'EN CURSO',
            )
          }
          onClick={() => {
            goToNextStep()
          }}
        />
      </TableFooter>
    </WrapperDisplayAsociadasIndividuales>
  )
}

export default DetallePedido
