import React from 'react'
import styled from 'styled-components'

const Text = styled.span`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`

const Filtros = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default Filtros
