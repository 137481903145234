import React from 'react'
import styled from 'styled-components'
import { P1 } from 'components/Typography'

const FilterTagContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 15px;
  padding: 4px 8px;
  margin: 4px;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.primary.blue};
  color: ${({ theme }) => theme.colors.primary.blue};
  font-size: 14px;
`

const FilterTag = ({ label }) => {
  return (
    <FilterTagContainer>
      <P1>{label}</P1>
    </FilterTagContainer>
  )
}

export default FilterTag
