import IconButton from 'components/Button/IconButton'
import { H6, P2, P4 } from 'components/Typography'
import {
  ChevronDown,
  ChevronUp,
  Trash2,
} from 'feather-icons-react/build/IconComponents'
import React, { useState } from 'react'
import styled from 'styled-components'
import Link from 'components/Button/Link'
import { useNavigate } from 'react-router-dom'
import { replaceText } from 'utils/textProcess'
import CurrencyFormat from 'react-currency-format'

const StyledCartItem = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.lighterGray};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
`

const ItemHeader = styled.div`
  width: 100%;
  padding: 20px 25px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;

  @media (max-width: 440px) {
    padding: 14px;
  }
`

const TopRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`

const ActionsContainer = styled.div`
  display: flex;
`

const CartBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 25px;
  padding-top: 0;
  gap: 5px;

  @media (max-width: 440px) {
    padding: 14px;
  }
`

const LinkMenu = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.neutral.black};
  :hover {
    color: ${({ theme }) => theme.colors.neutral.black};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.neutral.black};
  }
`

const DeleteIcon = styled(Trash2)`
  display: flex;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  stroke-width: 2.5px;
`

const TitleProveedor = styled(H6)`
  text-align: left;
  text-transform: uppercase;
`

const CartItem = ({ pedido, setDeleteIdProveedor }) => {
  const [isOpen, setIsOpen] = useState(true)
  const navigate = useNavigate()

  const navigateToProveedorPage = (idProveedor, nombreProveedor) => {
    navigate(`/detalle-proveedor/${replaceText(nombreProveedor, ' ', '-')}`, {
      state: { idProveedor },
    })
  }

  function handleClick(e) {
    e.stopPropagation()
    setIsOpen((prevStatus) => !prevStatus)
  }

  return (
    <>
      <StyledCartItem>
        <ItemHeader onClick={handleClick}>
          <TopRow>
            <TitleProveedor>{pedido.nombre_comercial}</TitleProveedor>
            <ActionsContainer>
              <IconButton
                icon={
                  <DeleteIcon
                    size={16}
                    onClick={() => setDeleteIdProveedor(pedido.id_proveedor)}
                  />
                }
              />
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </ActionsContainer>
          </TopRow>
          <LinkMenu
            onClick={() =>
              navigateToProveedorPage(
                pedido.id_proveedor,
                pedido.nombre_comercial,
              )
            }
            href=""
          >
            {'Ver requisitos'}
          </LinkMenu>
        </ItemHeader>
        {isOpen && (
          <CartBody>
            <P4>Bultos: {pedido.cantidad}</P4>
            <P2>
              <CurrencyFormat
                value={parseFloat(pedido.precio)}
                displayType={'text'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'Total: $'}
                decimalScale={3}
                fixedDecimalScale={true}
              />
            </P2>
          </CartBody>
        )}
      </StyledCartItem>
    </>
  )
}

export default CartItem
