// Referencia para ids de ESTADOS_DESHABILITADOS en backend -> app/Support(Constants/Estados.php
// Los estados marcados con *** son los posibles estados actuales, el resto son estados del Back Office
/*
1	 ACTIVO            
2	 SUSPENDIDO           
3	 BAJA               ***          
4	 EN CONFECCION        
5	 CONFECCIONADO      ***    
6	 APROBADO FACC      ***
7	 DESAPROBADO FACC   ***    
9	 FACTURADO          ***  
10	 CANCELADO        ***    
11	 EN FABRICACION       
12	 EN DESARROLLO        
13	 PROCESADO            
14	 LIQUIDADO        *** 
16	 APROBADO CON MODIF.  
17	 OC GENERADA      *** 
18	 VENCIDO              
19	 ACTIVO SIN ACEPTACIO 
20	 ACUSE RECIBO PROV. O  ***
21	 PARA LIQUIDAR         ***
22	 TODOS                
23	 SOLO DIFERENCIAS     
24	 SIN DIFERENCIAS      
25	 MANUAL               
26	 AUTOMATICO           
27	 PARCIAL              
28	 TOTAL                
29	 LIQUIDADO PARCIAL    ***
30	 CANCELADO POR COL    
31	 SUSP.POR PRECIO      
32	 RECHAZADO            
*/

export const ESTADOS_HABILITADOS = [6, 9, 14, 20, 21, 29]
export const ESTADOS_DESHABILITADOS = [3, 5, 7, 10, 17]

export const ESTADOS_OCULTAR_COMPROBANTES_STR = [
  'baja',
  'confeccionado',
  'generado',
  'desaprobado',
  'cancelado',
  'oc generada',
  'no_consolidado',
]

export const ESTADOS_OCULTAR_OC_STR = [
  'generado',
  'desaprobado',
  'cancelado',
  'aprobado',
]

export const ESTADOS_DESHABILITAR_RECEPCION_STR = [
  'baja',
  'confeccionado',
  'generado',
  'desaprobado',
  'cancelado',
  'oc generada',
  'recepcionado',
  'no_consolidado',
]

const ESTADOS_RECEPCION = [
  {
    step: 0,
    estado: 'APROBACIÓN',
    descripcion: 'Su orden de compra ha sido enviada al proveedor',
  },
  {
    step: 1,
    estado: 'PREPARACIÓN',
    descripcion: 'Su Orden de compra fue confirmada por el proveedor',
  },
  {
    step: 2,
    estado: 'RETIRO O ENTREGA',
    descripcion: 'La mercadería fue despachada, consultar transporte',
  },
  {
    step: 3,
    estado: 'EN DESTINO',
    descripcion: 'El pedido ha sido recepcionado',
  },
  {
    step: -1,
    estado: '',
    descripcion: '',
  },
]

export const obtenerEstadoPedido = (id, porcentajeRecepcion) => {
  if (porcentajeRecepcion === 100) {
    return ESTADOS_RECEPCION[3]
  } else if (id == '6') {
    return ESTADOS_RECEPCION[0]
  } else if (id == '20' || id == '8') {
    return ESTADOS_RECEPCION[1]
  } else if (
    id == '9' ||
    id == '34' ||
    (porcentajeRecepcion > 1 && porcentajeRecepcion < 55)
  ) {
    return ESTADOS_RECEPCION[2]
  } else {
    return ESTADOS_RECEPCION[4]
  }
}
