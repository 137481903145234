export function shuffle(array) {
  let currentIndex = array.length
  const newArray = [...array]

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    const randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex],
      newArray[currentIndex],
    ]
  }

  return newArray
}

export function orderByObjectKeyValue(array, objectKey, value) {
  const isSubKey = objectKey === 'puedePedir'
  return array.sort((a, b) => {
    if (isSubKey ? a[objectKey][objectKey] : a[objectKey] === value) {
      return -1
    } else if (isSubKey ? b[objectKey][objectKey] : b[objectKey] === value) {
      return 1
    } else {
      return 0
    }
  })
}
