import React, { useState } from 'react'
import styled from 'styled-components'

import { useModal } from 'contexts/ModalContext'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import GenerarRemitosTable from './GenerarRemitosTable'
import GenerarRemitoModal from './GenerarRemitoModal'
import { Button } from 'components/index'
import { usePedidos } from 'contexts/PedidosContext'
import { useNavigate } from 'react-router-dom'
import ModalNotification from 'components/Notification/ModalNotification'
import { showToast } from 'components/Notification/ToastNotification'

const TableSection = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  text-align: start;
`

const CustomExpandableTables = styled(ExpandableTables)`
  padding: 14px 44px;
  border: none;
  border-radius: 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 30px;
  gap: 15px;
`
const EmptyTableData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const GenerarRemitosPage = ({ goToNextStep, stepNumber }) => {
  const { isModalOpen, openModal } = useModal()
  const { recepcionPedido, generarRemitos } = usePedidos()
  const [confirmarModal, setconfirmarModal] = useState(false)
  const navigate = useNavigate()

  function modificarRemitos(remitos) {
    return Object.keys(remitos).reduce((result, key) => {
      result[remitos[key].id_afiliado] = parseInt(remitos[key].numero_remito)
      return result
    }, {})
  }
  const [nrosDeRemito, setNrosDeRemito] = useState(
    recepcionPedido.dataPaso?.remitos
      ? modificarRemitos(recepcionPedido.dataPaso.remitos)
      : [],
  )

  async function checkGenerarRemitos() {
    try {
      setconfirmarModal(false)
      await generarRemitos(
        recepcionPedido.dataPaso?.ocRecepcion?.id_cabecera_recepcion,
        recepcionPedido.dataPaso?.ocRecepcion?.id_cabecera,
        nrosDeRemito,
      )
      // no avanzar al paso siguiente, mostrar otro modal con mensaje de confirmación y 1 botón para ir al paso 2

      showToast(`¡La operación fue realizada con éxito!`, 'validacion')
      goToNextStep()
    } catch (error) {
      console.log('error ', error.response.data.messages.errors)
    }
  }

  const remitoData =
    stepNumber === 3
      ? recepcionPedido.dataPaso?.remitos
      : Object.values(recepcionPedido.dataPaso?.remitos)[0]

  return (
    <TableSection>
      <CustomExpandableTables
        openTables={[recepcionPedido.dataPaso?.proveedor]}
        items={[
          {
            title: recepcionPedido.dataPaso?.proveedor,
            component: remitoData ? (
              <GenerarRemitosTable
                data={remitoData}
                openModal={openModal}
                stepNumber={stepNumber}
                nrosDeRemito={nrosDeRemito}
                setNrosDeRemito={setNrosDeRemito}
              />
            ) : (
              <EmptyTableData>No hay datos</EmptyTableData>
            ),
          },
        ]}
      />
      <ButtonWrapper>
        {stepNumber === 3 ? (
          <Button
            text={`Generar remito`}
            type={'primary'}
            onClick={() => {
              setconfirmarModal(true)
            }}
          />
        ) : (
          <>
            <Button
              text={`Ir a Pedidos`}
              type={'secondary'}
              onClick={() => navigate('/pedidos')}
            />
          </>
        )}
      </ButtonWrapper>
      {isModalOpen && <GenerarRemitoModal />}

      {confirmarModal && (
        <ModalNotification
          type="notificacion"
          title="Estas por generar remitos"
          descripcion={[
            'Si seleccionas',
            ' CONTINUAR ',
            'confirmarás la generación de remitos. Si preferís controlar seleccioná',
            ' VOLVER Y REVISAR',
          ]}
          primaryFunction={() => {
            checkGenerarRemitos()
          }}
          secondaryFunction={() => {
            setconfirmarModal(false)
          }}
          afterClose={() => {}}
          customCloseModal={() => {
            setconfirmarModal(false)
          }}
        />
      )}
    </TableSection>
  )
}

export default GenerarRemitosPage
