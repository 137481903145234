import React from 'react'
import styled from 'styled-components'

const Text = styled.h4`
  margin: 0;
  font-size: 20px;
  font-family: 'Roboto-bold';
  line-height: 32px;

  @media screen and (max-width: 1366px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const H4 = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default H4
