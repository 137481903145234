import React, { createContext, useContext, useState } from 'react'

import AuthService from 'services/auth'
import { getLocalStorageItem } from 'utils/loadData'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => getLocalStorageItem('user', {}))

  const [token, setToken] = useState(() => {
    return localStorage.getItem('token') || ''
  })

  const [loginLoading, setLoginLoading] = useState(false)
  const [logoutLoading, setLogutLoading] = useState(false)

  const getUser = async () => {
    try {
      const resp = await AuthService.getUserData(token)
      localStorage.setItem('user', JSON.stringify(resp))
      setUser(resp)
      return resp
    } catch (error) {
      console.error('Error al obtener los datos de usuario:', error)
    }
  }

  const login = async (username, password) => {
    setLoginLoading(true)
    await AuthService.login(username, password)
      .then((data) => {
        if (data && data.token) {
          setToken(data.token)
          localStorage.setItem('token', data.token)
        }
      })
      .catch((error) => {
        console.error('Error de autenticación:', error)
        throw error
      })
      .finally(() => setLoginLoading(false))
  }

  const logout = async () => {
    setLogutLoading(true)
    await AuthService.logout(token)
      .then(() => {
        setToken('')
        setUser({})
        localStorage.clear()
      })
      .catch((error) => {
        console.error('Error de autenticación:', error)
        throw error
      })
      .finally(() => setLogutLoading(false))
  }

  const updatePassword = async (password, passwordConfirmation) => {
    setLoginLoading(true)
    try {
      const resp = await AuthService.updatePassword(
        password,
        passwordConfirmation,
        token,
      )
      return resp
    } catch (error) {
      console.error('Error al obtener los datos de usuario:', error)
      throw error
    } finally {
      setLoginLoading(false)
    }
  }

  const resetPassword = async (password, passwordConfirmation, token) => {
    setLoginLoading(true)
    try {
      const resp = await AuthService.resetPassword(
        password,
        passwordConfirmation,
        token,
      )
      return resp
    } catch (error) {
      console.error('Error al obtener los datos de usuario:', error)
      throw error
    } finally {
      setLoginLoading(false)
    }
  }

  const forgotPassword = async (username) => {
    setLoginLoading(true)
    try {
      const resp = await AuthService.forgotPassword(username)
      return resp
    } catch (error) {
      console.error('Error al obtener los datos de usuario:', error)
      throw error
    } finally {
      setLoginLoading(false)
    }
  }

  const contextValues = {
    setUser,
    getUser,
    login,
    loginLoading,
    logout,
    user,
    token,
    updatePassword,
    resetPassword,
    forgotPassword,
    logoutLoading,
  }

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  )
}
