import MobileFilterComponent from 'components/FilterComponent/MobileFilterComponent'
import RotatingLoading from 'components/Loading/Loading'
import { SearchAndFilterTableProvider } from 'contexts/SearchAndFilterTableContext'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useProductos } from 'contexts/ProductosContext'
import { Col, Row } from 'components/layout/FlexComponents'
import ProductosProveedorTable from './ProductosTable'
import FilterSection from 'pages/comprar/SurtidoProveedores/components/FilterSectionSurtido'
import DescriptionRequerimientosProveedor from 'pages/comprar/SurtidoProveedores/components/DescriptionRequerimientosProveedor'
import { Download } from 'feather-icons-react/build/IconComponents'
import { H2, P2 } from 'components/Typography'
import { Button } from 'components/index'
import { useCronograma } from 'contexts/CronogramaContext'
import { useAuth } from 'contexts/AuthContext'
import RotatingLoader from 'components/Loading/RotatingLoader'
import Select from 'components/Select/Select'

const WrapperComprarPage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;
  align-items: flex-start;

  @media (max-width: 440px) {
    padding: 20px 8px;
  }
`

const TableSection = styled.div`
  width: 80%;
  height: 100%;

  @media (max-width: 440px) {
    width: 100%;
  }
`

const FooterSection = styled(Col)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const SurtidoPageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TableWrapper = styled.div`
  padding: 18px;
  border-radius: 0 0 10px 10px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};

  @media (max-width: 440px) {
    padding: 8px;
  }
`
const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;

  @media (max-width: 440px) {
    width: 80%;
  }
`

const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  @media (max-width: 440px) {
    flex-direction: column;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
const WrapperRequerimientos = styled.div`
  flex: 1;
  padding: 0 20px 20px;
`
const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 440px) {
    gap: 10px;
  }
`

const Header = styled(Col)`
  border-radius: 15px 15px 0 0;
  padding: 8px 0;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const ProveedorItem = styled.div`
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};

  @media (max-width: 440px) {
    flex-direction: column;
    padding: 8px 14px;
  }
`

const WrapperZona = styled.div`
  padding: 25px 20px 20px 20px;
`

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  text-align: start;
`

const CustomLoading = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProductosPage = () => {
  const [selectedZona, setSelectedZona] = useState(1)
  const [zonasProveedor, setZonasProveedor] = useState([])
  const { getProductos, productos, productosLoading } = useProductos()
  const { getZonasProveedor } = useCronograma()
  const { user } = useAuth()

  useEffect(() => {
    const fetchArticulos = async () => {
      const zonas = await getZonasProveedor(user.id)
      setZonasProveedor(zonas)
      await getProductos(selectedZona)
    }
    fetchArticulos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function transformarTexto(texto) {
    return texto
      .toLowerCase()
      .replace(/\s+/g, '_')
      .replace(/[^\w-]/g, '')
  }

  function getFilterCategories(articulos) {
    const estadoOptions = [
      {
        name: 'Activos',
        tag: 'Activos',
        key: 'activos',
        default: true,
      },
      {
        name: 'Suspendidos',
        tag: 'Suspendidos',
        key: 'suspendidos',
      },
    ]

    if (articulos?.articulos) {
      const articulosSet = new Set()
      articulosSet.add({
        name: 'Todos',
        tag: 'Todos',
        key: 'todos',
        default: true,
      })
      articulos?.articulos.forEach((item) => {
        articulosSet.add({
          name: item.descripcion,
          tag: item.descripcion,
          key: transformarTexto(item.descripcion),
        })
      })
      return { Estado: estadoOptions, Productos: Array.from(articulosSet) }
    } else {
      return { Estado: estadoOptions, Productos: [] }
    }
  }
  const onClickDescargarSurtido = async () => {}

  return (
    <SearchAndFilterTableProvider initialValueData={productos.articulos}>
      <SurtidoPageWrapper>
        {productosLoading ? (
          <RotatingLoading />
        ) : (
          <>
            <WrapperComprarPage>
              <>
                <MobileFilterComponent>
                  <FilterSection categorias={getFilterCategories(productos)} />
                </MobileFilterComponent>
                <TableSection>
                  <Header>
                    <ProveedorItem>
                      <ProveedoresItem
                        key={productos?.cabecera?.condiciones?.nombre_comercial}
                      >
                        <H2>
                          {productos?.cabecera?.condiciones?.nombre_comercial}
                        </H2>
                      </ProveedoresItem>
                      <ButtonSection>
                        <Button
                          icon={<Download size={14} />}
                          type="secondary"
                          text="Descargar Surtido"
                          onClick={onClickDescargarSurtido}
                        />
                      </ButtonSection>
                    </ProveedorItem>
                    {productos?.cabecera && (
                      <Wrapper>
                        <WrapperInfo>
                          <WrapperRequerimientos>
                            <DescriptionRequerimientosProveedor
                              requerimientos={productos?.cabecera}
                            />
                          </WrapperRequerimientos>
                        </WrapperInfo>
                      </Wrapper>
                    )}
                    <WrapperZona>
                      <StyledLabel>Cambiar zona</StyledLabel>
                      {zonasProveedor === undefined ? (
                        <CustomLoading>
                          <RotatingLoader />
                        </CustomLoading>
                      ) : zonasProveedor === null ? (
                        <P2>No hay zonas</P2>
                      ) : Object.keys(zonasProveedor).length > 0 ? (
                        <Select
                          id="Zona"
                          options={zonasProveedor}
                          selectedOption={selectedZona}
                          onChange={async (zonaId) => {
                            setSelectedZona(zonaId)
                            await getProductos(zonaId)
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </WrapperZona>
                  </Header>
                  <TableWrapper>
                    <ProductosProveedorTable
                      nombreProveedor={
                        productos?.cabecera?.condiciones?.nombre_comercial
                      }
                    />
                  </TableWrapper>
                  <FooterSection />
                </TableSection>
              </>
            </WrapperComprarPage>
          </>
        )}
      </SurtidoPageWrapper>
    </SearchAndFilterTableProvider>
  )
}

export default ProductosPage
