import Logo from 'assets/logos/logo_facc.svg'
import styled from 'styled-components'

const LogoImage = styled.img`
  object-fit: contain;
`

const LogoFacc = ({ className }) => {
  return (
    <LogoImage
      src={Logo}
      alt="Logo de la Federación Argentina de Cooperativas de Consumo"
      className={className}
    />
  )
}

export default LogoFacc
