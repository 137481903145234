import React from 'react'
import styled from 'styled-components'

const Text = styled.h1`
  margin: 0;
  font-size: 45px;
  font-family: 'Roboto-bold';
  line-height: 56px;
  @media screen and (max-width: 1366px) {
    font-size: 28px;
    line-height: 38px;
  }
`

const H1 = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default H1
