export const getLocalStorageItem = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key)
  if (storedValue === null || storedValue === undefined) {
    return defaultValue
  }
  try {
    return JSON.parse(storedValue)
  } catch (e) {
    console.error(`Error parsing JSON from localStorage key "${key}"`, e)
    return defaultValue
  }
}
