import React from 'react'

import { Wrapper } from 'components/layout/FlexComponents'
import styled from 'styled-components'
import { usePedidos } from 'contexts/PedidosContext'
import DetallePedidosTable from './components/DetallePedidosTable'
import RotatingLoading from 'components/Loading/Loading'

const WrapperCoordinacionPage = styled(Wrapper)`
  display: flex;
  box-sizing: border-box;
`

const PedidosParticipacionPage = ({ goToNextStep, reqCalculados }) => {
  const { participacion, participacionLoading, pedidosLoading } = usePedidos()
  return (
    <WrapperCoordinacionPage>
      {participacionLoading || pedidosLoading ? (
        <RotatingLoading size="large" />
      ) : (
        <DetallePedidosTable
          participacion={participacion}
          goToNextStep={goToNextStep}
          reqCalculados={reqCalculados}
        />
      )}
    </WrapperCoordinacionPage>
  )
}

export default PedidosParticipacionPage
