import React from 'react'
import Modal from './Modal'
import { useModal } from 'contexts/ModalContext'
import { useCarrito } from 'contexts/CarritoContext'

const DeleteProductModal = ({ idProducto, afterClose }) => {
  const { closeModal } = useModal()
  const { agregarItem, loading } = useCarrito()

  const content = {
    type: 'error',
    icon: 'trash',
    title: '¿Estás seguro de eliminar?',
    text: [
      'Si seleccionas ',
      'CONFIRMAR ',
      'eliminarás del carrito ',
      'este producto',
    ],
    primaryButton: {
      function: () => {
        agregarItem(idProducto, 0)
        closeModal()
        afterClose()
      },
      text: 'Confirmar',
    },
    secondaryButton: {
      function: () => {
        closeModal()
        afterClose()
      },
      text: 'Cancelar',
    },
    afterClose,
    loading,
  }
  return <Modal content={content} />
}

export default DeleteProductModal
