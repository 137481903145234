export function sumarBultosPallet(pedido) {
  return pedido.articulos.reduce((totalBultos, articulo) => {
    const bultosPallet =
      parseInt(articulo.pisos_pallet, 10) * parseInt(articulo.bultos_piso, 10)
    return totalBultos + bultosPallet
  }, 0)
}

export function sumarPrecios(pedido) {
  return pedido.articulos.reduce((totalPrecio, articulo) => {
    const precio = parseFloat(articulo.precio)
    return totalPrecio + precio
  }, 0)
}

export function totalArticulos(carrito) {
  if (carrito) {
    return carrito.reduce((totalArticulos, pedido) => {
      const cantidad = sumarArticulos(pedido)
      return totalArticulos + cantidad
    }, 0)
  } else {
    return 0
  }
}
export function sumarArticulos(pedido) {
  return pedido.articulos.reduce((totalArticulos, articulo) => {
    const cantidad = parseFloat(articulo.cantidad_carrito)
    return totalArticulos + cantidad
  }, 0)
}
