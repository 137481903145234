import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table/Table'
// import { Download, Eye, Mail } from 'feather-icons-react/build/IconComponents'
import { Download } from 'feather-icons-react/build/IconComponents'
import { usePedidos } from 'contexts/PedidosContext'
import { compressLongText } from 'utils/textProcess'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

// const EyeIcon = styled(Eye)`
//   width: 18px;
//   height: 18px;
//   margin-right: 12px;
//   cursor: pointer;
// `

const DownloadIcon = styled(Download)`
  width: 18px;
  height: 18px;
  margin-right: 12px;
  cursor: pointer;
`

// const MailIcon = styled(Mail)`
//   width: 18px;
//   height: 18px;
//   margin-right: 12px;
//   cursor: pointer;
// `

const WrapperTable = styled.div`
  max-height: 300px;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
`

const formatData = (oc, remitos, obtenerPdfRemito) => {
  const onClickRemitoDescarga = async (
    idOcCabecera,
    idCabeceraRecepcion,
    idAfiliado,
  ) => {
    const pdfData = await obtenerPdfRemito(
      idOcCabecera,
      idCabeceraRecepcion,
      idAfiliado,
    )
    const url = window.URL.createObjectURL(
      new Blob([pdfData], { type: 'application/pdf' }),
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `Remito-OC-${oc}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const remitosFormateados = []
  if (remitos && Object.keys(remitos).length > 0) {
    Object.keys(remitos).forEach((idCabeceraRecepcion) => {
      let remito = remitos[idCabeceraRecepcion]
      Object.keys(remito).forEach((afiliado) => {
        remitosFormateados.push({
          Cuit: remito[afiliado].cuit,
          Razon: compressLongText(remito[afiliado].razon_social, 24),
          Numero: remito[afiliado].numero_remito,
          Accionables: (
            <>
              {/* <EyeIcon /> */}
              <DownloadIcon
                onClick={() =>
                  onClickRemitoDescarga(
                    remito[afiliado].id_cabecera,
                    remito[afiliado].id_cabecera_recepcion,
                    afiliado,
                  )
                }
              />
              {/* <MailIcon /> */}
            </>
          ),
        })
      })
    })
  }
  return remitosFormateados
}

const headers = [
  {
    name: 'CUIT',
    isOrderable: false,
  },
  {
    name: 'Razón Social',
    isOrderable: false,
  },
  {
    name: 'N° remito',
    isOrderable: false,
  },
]

const RemitosTable = ({ oc, remitos, width }) => {
  const { obtenerPdfRemito } = usePedidos()
  const tableFormat = formatData(oc, remitos, obtenerPdfRemito)

  return (
    <WrapperTable>
      {tableFormat?.length > 0 ? (
        <Table
          data={tableFormat}
          headers={headers}
          gridTemplate={width > 440 ? '15% 35% 25% 25%' : '26% 35% 22% 25%'}
        />
      ) : (
        <EmptyTableData>
          Todavía no se han generado remitos. En breve podrás descargarlos.
        </EmptyTableData>
      )}
    </WrapperTable>
  )
}

export default RemitosTable
