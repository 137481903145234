import React from 'react'
import styled from 'styled-components'

import Link from './Link'

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  text-decoration: underline;
  font-weight: 500;
`

const LinkUnderline = ({ className, href, onClick, children, target }) => (
  <StyledLink
    href={href}
    className={className}
    onClick={onClick}
    target={target}
  >
    {children}
  </StyledLink>
)

export default LinkUnderline
