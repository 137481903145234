import { BtnM } from 'components/Typography'
import React from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  cursor: pointer;
`

const Link = ({ className, href, onClick, children, target }) => (
  <StyledLink
    href={href}
    className={className}
    onClick={onClick}
    target={target}
  >
    <BtnM>{children}</BtnM>
  </StyledLink>
)

export default Link
