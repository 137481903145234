import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './AuthContext'
import { useCronograma } from './CronogramaContext'
import { useArticulos } from './ArticulosContext'

const NotificacionesContext = createContext()

export const useNotificaciones = () => useContext(NotificacionesContext)

export const NotificacionesProvider = ({
  children,
  notificationSection = 'notificaciones',
}) => {
  const { user } = useAuth()
  const { cronograma } = useCronograma()
  const { articulos } = useArticulos()

  const [notificaciones, setNotificaciones] = useState(
    JSON.parse(localStorage.getItem(notificationSection)) || {},
  )
  // const [notificacionesLoading, setNotificacionesLoading] = useState(false)

  useEffect(() => {
    if (cronograma) {
      let newsNotifications = {}
      if (user?.rol?.id_rol !== '3' && user?.rol?.id_rol !== '4') {
        if (articulos.puedePedir && !articulos.puedePedir.puedePedir) {
          newsNotifications = {
            ...newsNotifications,
            habilitacionCronograma: {
              text: `No se pueden realizar pedidos al proveedor. Motivos: ${articulos.puedePedir.motivo} -.`,
              notificationName: 'pedidosProveedor',
            },
          }
        }
        if (user?.rol?.id_rol === '1') {
          if (cronograma.habilitado) {
            const carritos =
              JSON.parse(localStorage.getItem('pedidosAConsolidar')) || []
            const hayCarritosAConsolidar =
              carritos.proveedores !== undefined
                ? carritos.proveedores.some(
                    (carrito) => carrito.consolidado === false,
                  )
                : false
            if (hayCarritosAConsolidar) {
              newsNotifications = {
                ...newsNotifications,
                habilitacionCronograma: {
                  text: 'Tenés pedidos pendientes de consolidación',
                  notificationName: 'habilitacionCronograma',
                },
              }
            }
          } else {
            newsNotifications = {
              ...newsNotifications,
              habilitacionCronograma: {
                text: 'Apertura de cronograma, ya podés habilitarlo',
                notificationName: 'habilitacionCronograma',
              },
            }
          }
        } else if (user?.rol?.id_rol === '2') {
          if (cronograma?.habilitado) {
            newsNotifications = {
              ...newsNotifications,
              habilitacionCronograma: {
                text: 'El conograma actual ya está habilitado para compras.',
                notificationName: 'habilitacionCronograma',
              },
            }
          } else {
            newsNotifications = {
              ...newsNotifications,
              habilitacionCronograma: {
                text: 'Tu coordinadora aún no habilitó el cronograma.',
                notificationName: 'habilitacionCronograma',
              },
            }
          }
        }
      }
      localStorage.setItem('notificaciones', JSON.stringify(newsNotifications))
      setNotificaciones(newsNotifications)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cronograma, user])

  const contextValues = {
    notificaciones,
    setNotificaciones,
  }

  return (
    <NotificacionesContext.Provider value={contextValues}>
      {children}
    </NotificacionesContext.Provider>
  )
}
