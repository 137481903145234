import React from 'react'
import styled from 'styled-components'
import Loading from 'components/Loading/Loading'

import { useProveedor } from 'contexts/ProveedorContext'
import { usePedidos } from 'contexts/PedidosContext'
import DetallePedido from './DetallePedido'
import DropdownNotification from 'components/Notification/DropdownNotification'

const Wrapper = styled.div`
  background-color: ${({ $isExpanded, theme }) =>
    $isExpanded
      ? theme.colors.neutral.white
      : theme.colors.neutral.almostWhite};
  flex-direction: column;
  border-radius: 20px;
  margin-top: 20px;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  border-radius: 20px;
  width: 100%;
`

const WrapperDetalleProveedorPage = styled.div`
  gap: 32px;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border: 2px solid ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 15px;
`

const CustomDropdownNotification = styled(DropdownNotification)`
  width: 95%;
`

const DetallePedidosTable = ({
  participacion,
  goToNextStep,
  reqCalculados,
}) => {
  const { proveedorLoading, currentProveedor } = useProveedor()
  const { pedidosLoading } = usePedidos()

  return (
    <>
      <Wrapper>
        {(proveedorLoading || pedidosLoading) && !participacion ? (
          <Loading />
        ) : (
          currentProveedor.id && (
            <WrapperDetalleProveedorPage>
              <DetallePedido
                proveedor={{
                  ...currentProveedor,
                  requisitosCumplidos: reqCalculados,
                  requerimientos: participacion?.proveedor?.requerimientos,
                  afiliados: participacion?.proveedor?.afiliados,
                  totales: participacion?.proveedor?.totales,
                }}
                goToNextStep={goToNextStep}
              />
            </WrapperDetalleProveedorPage>
          )
        )}
      </Wrapper>
      {!(proveedorLoading || pedidosLoading) &&
        participacion.proveedor &&
        participacion.proveedor.afiliados &&
        participacion.proveedor.afiliados.length !== 0 &&
        participacion.proveedor.afiliados.some(
          (afiliado) => afiliado.estadoCarritos === 'EN CURSO',
        ) && (
          <CustomDropdownNotification
            text="Hay subasociadas que tienen carros en curso."
            type="alerta"
          />
        )}
    </>
  )
}

export default DetallePedidosTable
