import React from 'react'
import styled from 'styled-components'
import { H3, P4 } from 'components/Typography'
import CurrencyFormat from 'react-currency-format'

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 30px;
  padding: 6px 30px;
  box-sizing: border-box;
  border-radius: 15px 15px 0 0;
`

const UserDataWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  max-width: 34%;
`

const ProveedorSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const HeaderTitle = styled(H3)`
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const RequerimientosSection = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  margin: 0px 30px;
  gap: 10px;
  font-weight: 700;
`

const DataBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const DataBoxText = styled(P4)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const VerticalLine = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin: 0 5px;
`

const HeaderInfo = ({ proveedor, totales = {} }) => {
  return (
    <HeaderWrapper>
      <UserDataWrapper>
        <ProveedorSection>
          <HeaderTitle>{proveedor.nombre_comercial}</HeaderTitle>
        </ProveedorSection>
      </UserDataWrapper>
      <RequerimientosSection>
        <DataBox>
          <DataBoxText>Requisitos</DataBoxText>
          <DataBoxText>
            {totales.requerimiento
              ? totales.requerimiento
              : Math.min(proveedor.requisitosCumplidos, 100)}
            %
          </DataBoxText>
        </DataBox>
        <VerticalLine />
        <DataBox>
          <DataBoxText>Bultos</DataBoxText>
          <DataBoxText>
            {totales.bultos ? totales.bultos : proveedor?.totales?.cantidad}
          </DataBoxText>
        </DataBox>
        <VerticalLine />
        <DataBox>
          <DataBoxText>Neto</DataBoxText>
          <DataBoxText>
            <CurrencyFormat
              value={
                totales.subtotal
                  ? totales.subtotal
                  : proveedor?.totales?.subtotal
                    ? parseFloat(proveedor?.totales?.subtotal)
                    : 0
              }
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'$'}
              decimalScale={3}
              fixedDecimalScale={true}
            />
          </DataBoxText>
        </DataBox>
        <VerticalLine />
        <DataBox>
          <DataBoxText>Total</DataBoxText>
          <DataBoxText>
            <CurrencyFormat
              value={
                totales.total
                  ? totales.total
                  : proveedor?.totales?.total
                    ? parseFloat(proveedor?.totales?.total)
                    : 0
              }
              displayType={'text'}
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'$'}
              decimalScale={3}
              fixedDecimalScale={true}
            />
          </DataBoxText>
        </DataBox>
      </RequerimientosSection>
    </HeaderWrapper>
  )
}

export default HeaderInfo
