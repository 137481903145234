import React from 'react'
import styled from 'styled-components'

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
`

const P2 = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default P2
