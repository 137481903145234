import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ChevronDown,
  ChevronUp,
  Star,
} from 'feather-icons-react/build/IconComponents'
import RotatingLoader from 'components/Loading/RotatingLoader'
import { compressLongText } from 'utils/textProcess'

const SelectButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
`

const CustomSelect = styled.div`
  position: relative;
  min-width: 170px;
  display: inline-block;
  border-radius: ${({ $isopen }) =>
    $isopen === 'true' ? '10px 10px 0 0' : '10px'};
  border: 2px solid
    ${({ theme, $disabled }) =>
      $disabled
        ? theme.colors.neutral.disableGrey
        : theme.colors.primary.darkBlue};
  color: ${({ theme, $disabled }) =>
    $disabled
      ? theme.colors.neutral.disableGrey
      : theme.colors.primary.darkBlue};
  background-color: ${({ theme }) => theme.colors.neutral.white};
  ${SelectButton} {
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  }

  @media (max-width: 440px) {
    min-width: 100px;
  }
`

const IconContainer = styled.div`
  line-height: 0.5;
  vertical-align: middle;
`

const OptionsContainer = styled.div`
  position: fixed;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => width - 4}px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 0 0 10px 10px;
  border: 2px solid ${({ theme }) => theme.colors.primary.darkBlue};
  border-top: none;
  z-index: 9999;
`

const Option = styled.div`
  padding: 6px 10px;
  cursor: pointer;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.primary.darkBlue};
  &:last-child {
    border-radius: 0 0 10px 10px;
  }
  &:hover {
    background-color: #f5f5f5;
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4px 10px;
  height: 16px;
`

const StarCircleCustom = styled(Star)`
  color: ${({ theme }) => theme.colors.alarmsStatuses.yellow};
  stroke-width: 3px;
  margin-left: auto;
`

const Select = ({
  options,
  selectedOption,
  onChange,
  disabled,
  className,
  toggleOptionsAction = () => {},
  bestZona,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const dropdownRef = useRef(null)
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  })

  const handleOptionClick = async (key) => {
    setLoading(true)
    await onChange(key)
    setLoading(false)
  }

  const toggleOptions = () => {
    if (!disabled) {
      const rect = dropdownRef.current.getBoundingClientRect()
      setDropdownPosition({
        top: rect.bottom,
        left: rect.left,
        width: rect.width,
      })
      setIsOpen(!isOpen)
      toggleOptionsAction()
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('click', handleClickOutside)

    const handleScroll = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    options && (
      <CustomSelect
        aria-label="Selector de área"
        $isopen={isOpen.toString()}
        ref={dropdownRef}
        className={className}
        $disabled={disabled}
        onClick={loading || disabled ? () => {} : toggleOptions}
      >
        {loading ? (
          <LoadingWrapper>
            <RotatingLoader />
          </LoadingWrapper>
        ) : (
          <SelectButton>
            <div>{options[selectedOption]}</div>
            {bestZona && bestZona == selectedOption && (
              <StarCircleCustom size={16} />
            )}
            <IconContainer
              onClick={(event) => {
                event.stopPropagation()
                toggleOptions()
              }}
            >
              {isOpen ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
            </IconContainer>
          </SelectButton>
        )}
        {isOpen && (
          <OptionsContainer
            $isopen={isOpen.toString()}
            top={dropdownPosition.top}
            left={dropdownPosition.left}
            width={dropdownPosition.width}
          >
            {Object.keys(options).map((key) => {
              return (
                <Option
                  aria-selected={selectedOption === key}
                  key={key}
                  onClick={() => handleOptionClick(key)}
                >
                  {compressLongText(options[key], 30)}
                  {bestZona && bestZona == key && (
                    <StarCircleCustom size={16} />
                  )}
                </Option>
              )
            })}
          </OptionsContainer>
        )}
      </CustomSelect>
    )
  )
}

export default Select
