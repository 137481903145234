import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import { Button } from 'components/index'
import { Copy, Trash2 } from 'feather-icons-react/build/IconComponents'
import CantidadesRecibidasTable from './CantidadesRecibidasTable'
import { usePedidos } from 'contexts/PedidosContext'
import ModalNotification from 'components/Notification/ModalNotification'
import { useModal } from 'contexts/ModalContext'
import { showToast } from 'components/Notification/ToastNotification'

const TableSection = styled.div`
  width: 100%;
`

const CustomExpandableTables = styled(ExpandableTables)`
  padding: 14px 44px;
  border-radius: 20px;
  border: none;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 30px;
`

const CantidadesRecibidasPage = ({ goToNextStep }) => {
  const { recepcionPedido, recibirCantidadesPedido } = usePedidos()
  const { openModal, isModalOpen, closeModal } = useModal()
  const pedidoAgrupacion = recepcionPedido.dataPaso?.pedido
  const [articulosRecibidos, setArticulosRecibidos] = useState(
    pedidoAgrupacion?.detalle ? modificarBultosRecibidos() : [],
  )
  const [error, setError] = useState([])

  useEffect(() => {
    setArticulosRecibidos(modificarBultosRecibidos('copiar'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function modificarBultosRecibidos(funcion = 'default') {
    return pedidoAgrupacion?.detalle?.reduce((result, pedido) => {
      result[pedido.id_articulo] =
        funcion === 'copiar'
          ? parseInt(pedido.cant_pedido) - parseInt(pedido.cant_recibida) < 0
            ? 0
            : parseInt(pedido.cant_pedido) - parseInt(pedido.cant_recibida)
          : 0
      return result
    }, {})
  }

  const confirmarDistribucion = async () => {
    try {
      await recibirCantidadesPedido(pedidoAgrupacion.id, articulosRecibidos)
      closeModal()
      goToNextStep()
      showToast(`¡La operación fue realizada con éxito!`, 'validacion')
    } catch (error) {
      console.error('Error Recibiendo pedidos: ', error)
      throw error
    }
  }

  return (
    <TableSection>
      {pedidoAgrupacion?.detalle && (
        <CustomExpandableTables
          openTables={[pedidoAgrupacion.proveedor]}
          items={[
            {
              title: pedidoAgrupacion.proveedor,
              component: (
                <CantidadesRecibidasTable
                  data={pedidoAgrupacion?.detalle}
                  articulosRecibidos={articulosRecibidos}
                  setArticulosRecibidos={setArticulosRecibidos}
                  $error={error}
                  setError={setError}
                />
              ),
            },
          ]}
        />
      )}
      <ButtonWrapper>
        <Button
          text={'Borrar cantidades cargadas'}
          type={'tertiary'}
          icon={<Trash2 size={14} />}
          onClick={() => {
            setArticulosRecibidos(modificarBultosRecibidos())
            showToast(`¡Se borraron los valores con éxito!`, 'validacion')
          }}
        />
        <Button
          text={'Copiar valores del pedido'}
          type={'tertiary'}
          icon={<Copy size={14} />}
          onClick={() => {
            setArticulosRecibidos(modificarBultosRecibidos('copiar'))
            showToast(`¡Se copiaron los valores con éxito!`, 'validacion')
          }}
        />
        <Button
          text={'Confirmar recepción'}
          type={'primary'}
          onClick={openModal}
          disabled={error.length !== 0}
        />
      </ButtonWrapper>
      {isModalOpen && (
        <ModalNotification
          type="notificacion"
          title="Estás por confirmar las cantidades recibidas"
          descripcion={[
            ' Si seleccionas',
            ' CONTINUAR ',
            'confirmarás las cantidades recibidad.',
            'Si preferís controlar seleccioná ',
            'VOLVER Y REVISAR',
          ]}
          primaryFunction={confirmarDistribucion}
          secondaryFunction={closeModal}
          afterClose={() => {}}
        />
      )}
    </TableSection>
  )
}

export default CantidadesRecibidasPage
