import React from 'react'
import styled from 'styled-components'

import { H2, H5 } from 'components/Typography'
import { Button } from 'components/index'
import { Download } from 'feather-icons-react/build/IconComponents'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter, replaceText } from 'utils/textProcess'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import DescriptionRequerimientosProveedor from 'pages/comprar/SurtidoProveedores/components/DescriptionRequerimientosProveedor'
import ProveedorService from 'services/proveedor'
import { useCarrito } from 'contexts/CarritoContext'
import { useCronograma } from 'contexts/CronogramaContext'
import { useAuth } from 'contexts/AuthContext'
import { DATE_OPTIONS } from 'utils/constants'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 45px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const WrapperRequerimientos = styled.div`
  border: 1px solid #c8c8c8;
  padding: 0 20px 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  &.isExpanded {
    border-radius: 0;
  }
`

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  padding: 20px 44px;
  box-sizing: border-box;

  @media (max-width: 440px) {
    flex-direction: column;
    padding: 16px 22px;
    gap: 20px;
  }
`

const UserDataWrapper = styled.div`
  display: flex;
`

const HeaderTitle = styled(H2)`
  font-size: 30px;
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const CardsWrapper = styled.div`
  width: 100%;
`

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const InfoBox = styled.div`
  border-left: ${({ theme }) => theme.colors.primary.darkBlue} 3px solid;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 0 5px 5px 0;
  padding: 12px 8px;
  display: inline-block;
  width: ${(props) => (props.$isfullwidth ? '100%' : 'calc(25% - 28px)')};

  @media (max-width: 440px) {
    width: 100%;
  }
`

const ContactTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;
  gap: 10px;
  padding: 11px;
`

const ContactTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 440px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`

const CapitalizedSpan = styled.span`
  text-transform: capitalize;
`

const WrapperInfo = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.gray};
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 32px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 14px 48px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};

  @media (max-width: 440px) {
    flex-wrap: unset;
    flex-direction: column;
    row-gap: 14px;
  }
`

const TableDetalleProveedor = ({ proveedor }) => {
  const navigate = useNavigate()
  const { cronograma } = useCronograma()
  const { carritoAgrupado } = useCarrito()
  const { token } = useAuth()

  const formatInfo = (proveedor) => {
    return [
      {
        title: 'Requerimientos',
        component: (
          <WrapperRequerimientos>
            {proveedor.requerimientos && (
              <DescriptionRequerimientosProveedor
                requerimientos={proveedor.requerimientos}
              />
            )}
          </WrapperRequerimientos>
        ),
      },
      {
        title: 'Información',
        component: (
          <WrapperInfo>
            {[
              {
                titulo: 'Razón social',
                valor: proveedor.razon_social,
              },
              {
                titulo: 'C.U.I.T.',
                valor: proveedor.cuit,
              },
              {
                titulo: 'Localidad',
                valor: proveedor.nombre_localidad,
              },
              {
                titulo: 'Domicilio',
                valor: proveedor.domicilio,
              },
              {
                titulo: 'Categoría de IVA',
                valor: proveedor.categ_iva_desc,
              },
              {
                titulo: 'Código postal',
                valor: proveedor.cod_postal,
              },
              {
                titulo: 'Fax',
                valor: proveedor.fax,
              },
              {
                titulo: 'Teléfono',
                valor: proveedor.telefono,
              },
              {
                titulo: 'Contactos',
                contactos: proveedor.contactos,
              },
            ].map((item) => (
              <>
                <InfoBox
                  key={item.titulo}
                  $isfullwidth={item.titulo === 'Contactos'}
                >
                  <H5>{item.titulo}</H5>
                  {item.valor && <strong>{item.valor}</strong>}
                </InfoBox>
                {item.contactos && (
                  <ContactTable>
                    {item.contactos.map((contacto, index) => {
                      return (
                        <ContactTableRow key={index}>
                          <CapitalizedSpan>
                            {contacto.nombre.toLowerCase()}
                          </CapitalizedSpan>
                          <span>
                            {capitalizeFirstLetter(
                              contacto.cargo.toLowerCase(),
                            )}
                          </span>
                          <span>{contacto.telefono.toLowerCase()}</span>
                          <span>{contacto.email.toLowerCase()}</span>
                        </ContactTableRow>
                      )
                    })}
                  </ContactTable>
                )}
              </>
            ))}
          </WrapperInfo>
        ),
      },
    ]
  }

  const navigateToProveedorPage = async (idProveedor) => {
    navigate(
      `/comprar/${replaceText(proveedor.razon_social, ' ', '-')}/id/${idProveedor}`,
    )
  }

  const onClickDescargarSurtido = async () => {
    const csvData = await ProveedorService.obtenerCsvSurtidoProveedor({
      idCronograma: cronograma.id_cronograma,
      idProveedor: proveedor.id,
      idCarrito: carritoAgrupado.cabecera.id,
      token,
    })
    const url = window.URL.createObjectURL(
      new Blob([csvData], { type: 'text/csv' }),
    )
    const fechaDescarga = new Date()
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute(
      'download',
      `Surtido_${replaceText(proveedor.nombre_comercial, ' ', '-')}_${replaceText(fechaDescarga.toLocaleDateString('es', DATE_OPTIONS), '_', '-')}.csv`,
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <UserDataWrapper>
          <HeaderTitle>{proveedor.nombre_comercial}</HeaderTitle>
        </UserDataWrapper>
        <ButtonSection>
          <Button
            icon={<Download size={14} />}
            type="secondary"
            text="Descargar Surtido"
            onClick={onClickDescargarSurtido}
          />
          <Button
            type="primary"
            text="Ver surtido"
            onClick={() => navigateToProveedorPage(proveedor.id)}
          />
        </ButtonSection>
      </HeaderWrapper>
      <CardsWrapper>
        <ExpandableTables
          items={formatInfo(proveedor)}
          openTables={['Información', 'Requerimientos']}
        />
      </CardsWrapper>
    </Wrapper>
  )
}

export default TableDetalleProveedor
