import useAxiosInstance from './axiosInterceptor'
const axios = useAxiosInstance
import { queryString, reemplazarInterrogacionPorÑ } from 'utils/textProcess'

const AuthCronograma = {
  getCronogramaData: async (token) => {
    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/cronograma`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then((response) => {
        if (response.data.data.proveedores) {
          response.data.data.proveedores.map(
            (p) =>
              (p.nombre_comercial = reemplazarInterrogacionPorÑ(
                p.nombre_comercial,
              )),
          )
          return response.data.data
        } else {
          return response.data.data
        }
      })
      .catch((error) => {
        throw error
      })
  },

  habilitarDeshabilitarProveedor: ({
    idProveedor,
    habilitado,
    idGrupo,
    idCronograma,
    token,
  }) => {
    let data = JSON.stringify({
      id_grupo: parseInt(idGrupo),
      id_proveedor: parseInt(idProveedor),
      id_cronograma: parseInt(idCronograma),
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/${
        habilitado
          ? 'cronograma-deshabilitar-proveedor'
          : 'cronograma-habilitar-proveedor'
      }`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        throw error
      })
  },

  habilitarCronograma: ({ idGrupo, idCronograma, token }) => {
    let data = JSON.stringify({
      id_grupo: parseInt(idGrupo),
      id_cronograma: parseInt(idCronograma),
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/cronograma-habilitar`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  cambiarZonaProveedor: ({
    idGrupo = undefined,
    idCarrito = undefined,
    idProveedor,
    idZona,
    token,
  }) => {
    let data = {
      id_proveedor: parseInt(idProveedor),
      id_zona: parseInt(idZona),
    }

    if (idGrupo) {
      data.id_grupo = parseInt(idGrupo)
    }
    if (idCarrito) {
      data.id_carrito = parseInt(idCarrito)
    }

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/proveedor-zona`,
      headers: { Authorization: `Bearer ${token}` },
      data: JSON.stringify(data),
    }

    return axios
      .request(config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  mejorProveedorZona: ({ idProveedor, token }) => {
    let data = {
      id_proveedor: parseInt(idProveedor),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/proveedor-mejor-zona?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  zonasProveedor: ({ idProveedor, token }) => {
    let data = {
      id_proveedor: parseInt(idProveedor),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/proveedor-zonas?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  lugaresProveedores: ({ idProveedor, idZona, token }) => {
    let data = {
      id_proveedor: parseInt(idProveedor),
      id_zona: parseInt(idZona),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/lugares-entrega?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
}

export default AuthCronograma
