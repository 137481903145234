import React, { createContext, useContext, useState } from 'react'
import { useAuth } from './AuthContext'
import { useCronograma } from './CronogramaContext'
import { useCarrito } from './CarritoContext'
import ArticulosService from 'services/articulos'
import { getLocalStorageItem } from 'utils/loadData'

const ProductosContext = createContext()

export const useProductos = () => useContext(ProductosContext)

export const ProductosProvider = ({ children }) => {
  const [productosLoading, setProductosLoading] = useState(false)
  const [productos, setProductos] = useState(() =>
    getLocalStorageItem('productosProveedor', {}),
  )
  const { token } = useAuth()
  const { carritoAgrupado } = useCarrito()
  const { cronograma } = useCronograma()

  const getProductos = async (idZona) => {
    setProductosLoading(true)

    return await ArticulosService.getArticulosProveedor({
      idCronograma: cronograma.id_cronograma,
      idZona: idZona,
      idCarrito: carritoAgrupado?.cabecera?.id,
      token,
    })
      .then((response) => {
        setProductos(response)
        localStorage.setItem('productosProveedor', JSON.stringify(response))
        return response
      })
      .catch((error) => {
        throw error
      })
      .finally(() => setProductosLoading(false))
  }

  const contextValues = {
    productosLoading,
    productos,
    getProductos,
  }

  return (
    <ProductosContext.Provider value={contextValues}>
      {children}
    </ProductosContext.Provider>
  )
}
