import React from 'react'
import styled from 'styled-components'

const StyledErrorIcon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.alarmsStatuses.red};
  color: white;
  text-align: center;
  font-weight: 400;
  display: inline-block;
  margin-left: 4px;
  user-select: none;
`

const ErrorIcon = ({ children, ...props }) => {
  return <StyledErrorIcon {...props}>{children}</StyledErrorIcon>
}

export default ErrorIcon
