import { P4 } from 'components/Typography'
import React from 'react'
import styled from 'styled-components'
import { compressLongText } from 'utils/textProcess'

const StyledTabsComponent = styled.div`
  display: flex;
  flex-direction: column;
`

const RequerimientosSection = styled.div`
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  margin: 0px 30px;
  gap: 10px;

  @media (max-width: 440px) {
    flex-direction: column;
  }
`

const DataBox = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 12px 12px;
  justify-content: space-between;
  border-left: 3px solid ${({ theme }) => theme.colors.primary.darkBlue};
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const DescriptionText = styled(P4)`
  font-weight: 600;
`

const TabsComponent = ({ asociada }) => {
  return (
    <StyledTabsComponent>
      <RequerimientosSection>
        <DataBox>
          <P4>Nombre</P4>
          <DescriptionText>
            {compressLongText(asociada.nombre, 18)}
          </DescriptionText>
        </DataBox>
        <DataBox>
          <P4>C.U.I.T</P4>
          <DescriptionText>{asociada.cuit}</DescriptionText>
        </DataBox>
        <DataBox>
          <P4>Localidad</P4>
          <DescriptionText>{asociada.localidad}</DescriptionText>
        </DataBox>
        <DataBox>
          <P4>Domicilio</P4>
          <DescriptionText>{asociada.domicilio}</DescriptionText>
        </DataBox>
        <DataBox>
          <P4>Telefono</P4>
          <DescriptionText>{asociada.telefono}</DescriptionText>
        </DataBox>
        <DataBox>
          <P4>Fax</P4>
          <DescriptionText>{asociada.fax}</DescriptionText>
        </DataBox>
      </RequerimientosSection>
    </StyledTabsComponent>
  )
}

export default TabsComponent
