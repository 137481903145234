import React from 'react'
import styled from 'styled-components'

import { P2 } from 'components/Typography'
import HeaderTable from './HeaderTable'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow: auto;
`

const TableCell = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ $paddingVariant, width }) =>
    $paddingVariant === 'compact' || width <= 1366 ? '12px 0' : '16px 0'};
`

const NumberOfCell = styled(P2)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.darkGray};
  max-width: fit-content;
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: ${({ $gridTemplate }) => $gridTemplate};
  gap: ${({ $gap }) => $gap || 15}px;
  padding: 6px 14px 6px 30px;
  background-color: ${({ theme, $index }) =>
    $index % 2 === 0 || $index === '0'
      ? theme.colors.neutral.white
      : theme.colors.neutral.almostWhite};
  * > p {
    font-size: ${({ $paddingVariant, width }) =>
      $paddingVariant === 'compact' || width <= 1366 ? '12px 6px' : ''};
  }
  @media (max-width: 1366px) {
    padding: ${({ $paddingVariant, width }) =>
      $paddingVariant === 'compact' || width <= 1366 ? '3px 12px' : '6px 18px'};
    gap: ${({ $gap }) => $gap || 12}px;
  }

  @media (max-width: 440px) {
    padding: ${({ $paddingVariant, width }) =>
      $paddingVariant === 'compact' || width <= 1366 ? '3px 8px' : '4px 8px'};
    gap: ${({ $gap }) => $gap || 12}px;
    font-size: 14px;
  }
`

const Table = ({
  numerate = false,
  headers,
  data,
  gridTemplate,
  className,
  gap,
  $paddingVariant,
}) => {
  const { width } = useWindowWidth()
  const columns = data?.length > 0 ? Object.keys(data[0]) : []
  const columnWidths = columns.map(() => '1fr')
  const calculategridTemplate = ` ${numerate ? '16px ' : ''}  ${
    gridTemplate ? gridTemplate : columnWidths
  }`.replaceAll(',', ' ')

  return (
    <TableWrapper className={className}>
      {headers && (
        <HeaderTable
          numerate={numerate}
          headers={headers}
          gridTemplate={gridTemplate}
          gap={gap}
        />
      )}
      {data?.map((row, index) => (
        <TableRow
          key={`${index}-${row}`}
          $index={index}
          $gap={gap}
          $gridTemplate={calculategridTemplate}
          $paddingVariant={$paddingVariant}
          width={width}
        >
          {numerate && (
            <TableCell
              key={index}
              $paddingVariant={$paddingVariant}
              width={width}
            >
              <NumberOfCell>{index + 1}</NumberOfCell>
            </TableCell>
          )}
          {Object.keys(row).map((key, cellIndex) => (
            <TableCell
              key={cellIndex}
              $paddingVariant={$paddingVariant}
              width={width}
            >
              {row[key]}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableWrapper>
  )
}

export default Table
