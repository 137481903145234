import React, { useState } from 'react'
import styled from 'styled-components'
import {
  H2,
  // P1
} from 'components/Typography'

const StyledCard = styled.div`
  display: flex;
  flex-direction: ${({ $isActive }) => ($isActive ? 'column' : 'row')};
  align-items: ${({ $isActive }) => ($isActive ? ' flex-start' : 'center')};
  flex: 0 0 auto;
  width: 198px;
  gap: ${({ $isActive }) => ($isActive ? '0' : '20px')};
  height: ${({ $isActive }) => ($isActive ? '120px' : '65px')};
  margin: 10px;
  padding: 20px;
  border: 2px solid
    ${({ theme, $status, $isHovered }) =>
      $isHovered || $status === 'done'
        ? theme.colors.primary.darkBlue
        : theme.colors.primary.blue};
  border-radius: 8px;
  border-radius: 20px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: ${({ theme, $status, $isHovered }) =>
    $isHovered || $status === 'done'
      ? theme.colors.primary.darkBlue
      : $status === 'active'
        ? theme.colors.primary.blue
        : theme.colors.neutral.white};
  color: ${({ theme, $status, $isHovered }) =>
    $isHovered || $status !== 'inactive'
      ? theme.colors.neutral.white
      : theme.colors.primary.blue};
  scale: ${({ $isHovered }) => ($isHovered ? 1.07 : 1)};
  &:hover {
    cursor: pointer;
  }
  transition: all 0.3s ease-in-out;
  &:first-child {
    margin-left: 0;
  }
`

const CardTitle = styled(H2)`
  font-size: ${({ $isActive }) => ($isActive ? '30px' : '24px')};
`

const CardNumber = styled(CardTitle)`
  font-size: ${({ $isActive }) => ($isActive ? '40px' : '30px')};
`

// const HorizontalLine = styled.div`
//   height: 1px;
//   width: 20%;
//   background-color: ${({ theme, $status, $isHovered }) =>
//     $isHovered || $status === 'done' || $status === 'active'
//       ? theme.colors.neutral.gray
//       : theme.colors.primary.blue};
// `

// const CardText = styled(P1)`
//   margin-top: 6px;
// `

const StepCard = ({ cardData, index, activeStep }) => {
  const [isHovered, setIsHovered] = useState(false)

  const checkStatus = () => {
    return activeStep === index + 1
      ? 'active'
      : activeStep < index + 1
        ? 'inactive'
        : 'done'
  }

  const isActive = checkStatus() === 'active'

  return (
    <StyledCard
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      $status={checkStatus()}
      $isHovered={isHovered}
      $isActive={isActive}
    >
      <CardNumber $isActive={isActive}>{index + 1}</CardNumber>
      <CardTitle $isActive={isActive}>{cardData.title}</CardTitle>
      {/* <HorizontalLine $status={checkStatus()} $isHovered={isHovered} />
      <CardText>{cardData.description}</CardText> */}
    </StyledCard>
  )
}

export default StepCard
