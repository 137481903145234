import React from 'react'
import styled from 'styled-components'

import { Button } from 'components/index'
import { P1, P4 } from 'components/Typography'
import { useNavigate } from 'react-router-dom'
import { replaceText } from 'utils/textProcess'

const TitleCard = styled(P4)`
  margin-bottom: 18px;
  font-family: 'Roboto-bold';
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral.darkGray};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  padding: 8px 0 0 8px;

  @media (max-width: 440px) {
    padding: 2px;
    gap: 10px;
  }
`

const ListElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  border: 1px solid #0c1a4b3d;
  box-shadow:
    0px 3px 8px -1px rgba(50, 50, 71, 0.05),
    0px 0px 1px 0px rgba(12, 26, 75, 0.24);

  @media (max-width: 440px) {
    padding: 12px;
  }
`

const ListItemTitle = styled(P1)`
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 440px) {
    font-size: 14px;
  }
`

const Column = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ButtonProveedores = styled(Button)`
  border-radius: 40px;
  font-size: 14px;
`

const ListCard = ({ proveedores, title }) => {
  const navigate = useNavigate()

  return (
    <>
      <TitleCard>{title}</TitleCard>
      <List>
        {proveedores?.map((proveedor, index) => (
          <ListElement key={index}>
            <Column>
              <ListItemTitle>{proveedor.nombre_comercial}</ListItemTitle>
            </Column>
            <Column>
              <ButtonProveedores
                type={
                  proveedor.habilitado === 1 && proveedor.puedePedir.puedePedir
                    ? 'primary'
                    : 'secondary'
                }
                text={
                  proveedor.habilitado === 1 && proveedor.puedePedir.puedePedir
                    ? 'Comprar'
                    : 'Ver surtido'
                }
                onClick={() =>
                  navigate(
                    `/comprar/${replaceText(proveedor.nombre_comercial, ' ', '-')}/id/${proveedor.id}`,
                  )
                }
              />
            </Column>
          </ListElement>
        ))}
      </List>
    </>
  )
}

export default ListCard
