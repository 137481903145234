import React from 'react'

import { useAuth } from 'contexts/AuthContext'
import styled from 'styled-components'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import { H1 } from 'components/Typography'

const Container = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`

const Title = styled(H1)`
  color: ${({ theme }) => theme.colors.neutral.black};
  justify-self: flex-start;
  align-self: flex-start;
  margin-bottom: 16px;
  font-size: 30px;
`

const PreguntaContent = styled.div`
  padding: 16px 44px;
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral.gray}`};
  color: ${({ theme }) => theme.colors.neutral.black};
`

const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 20px;
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`

const Tutoriales = () => {
  const { user } = useAuth()

  const RolView = [
    [
      {
        video: 'https://www.youtube.com/embed/MU0kiVHJnmU',
        titulo: 'Recorrido por la Central de Compras de la FACC',
      },
      {
        video: 'https://www.youtube.com/embed/jhIEX-pwUng?si=JaXCFJbhRm6qz-HC',
        titulo: '¿Cómo comprar en la Central de Compras?',
      },
      {
        video: 'https://www.youtube.com/embed/qq30iiAbZxQ?si=VIkXaWWZJZF3h1AA',
        titulo: '¿Cómo habilitar un cronograma en la Central de Compras?',
      },
      {
        video: 'https://www.youtube.com/embed/7ZFFgIGc5V0?si=a-YZbMll_bmJ-Fh7',
        titulo:
          '¿Cómo recepcionar un pedido en la Central de Compras de la FACC?',
      },
      {
        video: 'https://www.youtube.com/embed/CF7SS0r4zvI?si=kBOTe_YFB1yjOLlk',
        titulo:
          '¿Cómo consolidar un pedido en la Central de Compras de la FACC?',
      },
      {
        video: 'https://www.youtube.com/embed/Y8l0hL96x0A?si=cM466Mfc__XefovE',
        titulo: '¿Cómo visualizar los requerimientos mínimos?',
      },
    ],
    [
      {
        video: 'https://www.youtube.com/embed/T4NfdIZ-uJ4',
        titulo: 'Recorrido por la Central de Compras de la FACC',
      },
      {
        video: 'https://www.youtube.com/embed/jhIEX-pwUng?si=JaXCFJbhRm6qz-HC',
        titulo: '¿Cómo comprar en la Central de Compras?',
      },
      {
        video: 'https://www.youtube.com/embed/Y8l0hL96x0A?si=cM466Mfc__XefovE',
        titulo: '¿Cómo visualizar los requerimientos mínimos?',
      },
    ],
    [
      {
        video: 'https://www.youtube.com/embed/rlwwpZmEfz8',
        titulo: 'Recorrido por la Central de Compras de la FACC',
      },
      {
        video: 'https://www.youtube.com/embed/fb_B0SNq5ek?si=VhTs7QvHM4SIb0xe',
        titulo:
          '¿Cómo recepcionar un pedido en la Central de Compras de la FACC?',
      },
      {
        video: 'https://www.youtube.com/embed/Y8l0hL96x0A?si=cM466Mfc__XefovE',
        titulo: '¿Cómo visualizar los requerimientos mínimos?',
      },
      {
        video: 'https://www.youtube.com/embed/4yt0gxmAFi4?si=FRUxsc3DMQh4UYDS',
        titulo: '¿Cómo comprar en la Central de Compras? ',
      },
    ],
  ]

  const formatInfo = () => {
    return RolView[parseInt(user?.rol?.id_rol) - 1].map((video, index) => {
      return {
        title: video.titulo,
        component: (
          <PreguntaContent key={index}>
            <VideoContainer>
              <StyledIframe
                src={video.video || null}
                title="Video de presentación"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VideoContainer>
          </PreguntaContent>
        ),
      }
    })
  }

  return (
    <Container>
      <Title>Tutoriales</Title>
      <ExpandableTables
        items={formatInfo()}
        className={{ removeBlueBorder: true }}
        style={{
          width: '100%',
          borderLeft: '',
        }}
      />
    </Container>
  )
}

export default Tutoriales
