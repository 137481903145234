import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import PromoCard from 'components/Carousel/PromoCard'
import { H2 } from 'components/Typography'
import { Wrapper } from 'components/layout/FlexComponents'
import { ChevronRight } from 'feather-icons-react/build/IconComponents'

const CarouselTitle = styled(H2)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin: 32px 0 16px 10%;
  background-color: ${({ type, theme }) =>
    type === 'primary' ? '#162240' : theme.colors.neutral.white};
`

const WrapperCarousel = styled(Wrapper)`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  padding-bottom: 32px;
  background-color: ${({ type }) => (type === 'primary' ? '#162240' : 'none')};
  position: relative;

  ${CarouselTitle} {
    color: ${({ type, theme }) =>
      type === 'primary'
        ? theme.colors.neutral.white
        : theme.colors.primary.darkBlue};
  }
`

const StyledHorizontalCarousel = styled.div`
  height: auto;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 0 0 0 10%;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &:active {
    cursor: grabbing;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const ScrollableArea = styled.div`
  display: flex;
  width: auto;

  &::after {
    content: '';
    flex-shrink: 0;
    width: calc(10% + 16px);
  }
`

const ScrollIndicator = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.primary.lightBlue};
  color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 50%;
  top: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const HorizontalPromoCarousel = ({
  cards,
  title,
  className,
  type,
  cronogramaHabilitado,
}) => {
  const scrollableAreaRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const showIndicator = useRef(true)

  useEffect(() => {
    if (isDragging) {
      const handleMouseMove = (event) => {
        showIndicator.current = false
        const distance = event.pageX - startX
        scrollableAreaRef.current.scrollLeft = scrollLeft - distance
      }

      const handleMouseUp = () => {
        setIsDragging(false)
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)

      return () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [isDragging, startX, scrollLeft])

  return (
    <WrapperCarousel className={className} type={type}>
      <CarouselTitle type={type}>{title}</CarouselTitle>
      <StyledHorizontalCarousel
        ref={scrollableAreaRef}
        onMouseDown={(e) => {
          setIsDragging(true)
          setStartX(e.pageX - scrollableAreaRef.current.offsetLeft)
          setScrollLeft(scrollableAreaRef.current.scrollLeft)
        }}
      >
        <ScrollableArea>
          {cards.map((card, index) => {
            return (
              <PromoCard
                key={index + '-' + title}
                card={card}
                cronogramaHabilitado={cronogramaHabilitado}
              />
            )
          })}
        </ScrollableArea>
        {showIndicator.current && (
          <ScrollIndicator>
            <ChevronRight size={35} />
          </ScrollIndicator>
        )}
      </StyledHorizontalCarousel>
    </WrapperCarousel>
  )
}

export default HorizontalPromoCarousel
