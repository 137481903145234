import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { H2, P2 } from 'components/Typography'
import TabsComponentPedidos from './components/TabsComponentPedidos'
import PedidosAgrupacionTable from './components/PedidosAgrupacion/PedidoAgrupacionTable'
import MisPedidosTable from './components/MisPedidos/MisPedidosTable'
import { useAuth } from 'contexts/AuthContext'
import { usePedidos } from 'contexts/PedidosContext'
import FilterSection from './components/FilterSectionPedidos'
import Pagination from 'components/Table/Pagination'
import NumberElement from 'components/Table/NumberElement'
import { useFilters } from 'contexts/FiltersContext'
import { getLocalStorageItem } from 'utils/loadData'
import DropdownNotification from 'components/Notification/DropdownNotification'
import { useWindowWidth } from 'contexts/WindowWidthContext'
import MobileFilterComponent from 'components/FilterComponent/MobileFilterComponent'
import axios from 'axios'
import { useCronograma } from 'contexts/CronogramaContext'
import PedidosProveedor from './components/PedidosProveedor/PedidosProveedor'

const WrapperPedidosPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;

  @media (max-width: 440px) {
    padding: 20px;
    flex-direction: column;
    gap: 18px;
  }
`

const TableSection = styled.div`
  width: 75%;

  @media (max-width: 440px) {
    width: 100%;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-bottom: 10px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Wrapper = styled.div`
  padding: 10px 16px 60px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};

  @media (max-width: 440px) {
    padding: 8px 8px 40px;
  }
`

const CustomPagination = styled(Pagination)`
  justify-content: end;
  margin: 20px 0;
  margin-left: auto;
`

const CustomNumberElement = styled(NumberElement)`
  text-align: end;
`

const PedidosPage = (asociada) => {
  const { user, token } = useAuth()
  const { cronograma } = useCronograma()
  const {
    pedidosAgrupacion,
    misPedidos,
    obtenerPdfPedidoSubasociada,
    obtenerPdfPedidoAgrupacion,
    getMisPedidos,
    setPedidosAgrupacion,
    getPedidosAgrupacion,
    getPedidosProveedor,
    setMisPedidos,
    pedidosProveedor,
  } = usePedidos()
  const [activeOrder, setActiveOrder] = useState('-fecha')
  const [searchValue, setSearchValue] = useState('')
  const { width } = useWindowWidth()
  const { pedidosFilters, onPedidosFiltersChange } = useFilters()
  const [currentPage, setCurrentPage] = useState(
    getLocalStorageItem('pedidosPageNumber') || 1,
  )
  const [activeTab, setActiveTab] = useState(
    getLocalStorageItem('activeTabPedidos', 0),
  )
  const prevSearchValueRef = useRef(searchValue)
  const prevFiltersRef = useRef(pedidosFilters)

  const pedidos =
    user.rol.id_rol === '5'
      ? pedidosProveedor
      : user.rol.id_rol !== '1'
        ? misPedidos
        : activeTab === 0
          ? pedidosAgrupacion
          : misPedidos

  const tabs =
    user.rol.id_rol === '1'
      ? ['Pedidos Agrupación', 'Mis pedidos']
      : ['Mis pedidos']

  const formatAsociadasDataToDisplay = (obj) =>
    obj
      ? {
          id: obj.id,
        }
      : {}

  const dataToDisplay = formatAsociadasDataToDisplay(asociada)

  const onClickPedidoDescarga = async (type, pedidoId, documentName) => {
    const pdfData =
      type === 'subasociada'
        ? await obtenerPdfPedidoSubasociada(pedidoId)
        : await obtenerPdfPedidoAgrupacion(pedidoId)
    const url = window.URL.createObjectURL(
      new Blob([pdfData], { type: 'application/pdf' }),
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `OC-${documentName}-ID${pedidoId}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const handleSetActiveOrder = (order) => {
    setActiveOrder((prevOrder) => {
      if (prevOrder.includes(order.toLowerCase())) {
        return prevOrder.startsWith('-')
          ? order.toLowerCase()
          : `-${order.toLowerCase()}`
      }
      return `${order.toLowerCase()}`
    })
  }

  const handlePageChange = (pageNumber) => {
    localStorage.setItem('pedidosPageNumber', JSON.stringify(pageNumber))
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    prevSearchValueRef.current = searchValue
    prevFiltersRef.current = pedidosFilters

    const filtersChanged =
      JSON.stringify(prevFiltersRef.current) !== JSON.stringify(pedidosFilters)
    const searchChanged = prevSearchValueRef.current !== searchValue

    if (filtersChanged || searchChanged) {
      localStorage.setItem('pedidosPageNumber', JSON.stringify(1))
      setCurrentPage(1)
    }
  }, [searchValue, pedidosFilters])

  useEffect(() => {
    const source = axios.CancelToken.source()
    const fetchMisPedidos = async () => {
      try {
        getMisPedidos({
          ...pedidosFilters,
          searchValue,
          activeOrder,
          currentPage,
          cancelToken: source.token,
        })
        prevSearchValueRef.current = searchValue
        prevFiltersRef.current = pedidosFilters
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Solicitud previa cancelada:', error.message)
        } else {
          setMisPedidos({})
          console.error('Error al obtener los pedidos: ', error)
        }
      }
    }
    const fetchPedidosAgrupacion = async () => {
      try {
        await getPedidosAgrupacion({
          ...pedidosFilters,
          searchValue,
          currentPage,
          activeOrder,
          cancelToken: source.token,
        })
        prevSearchValueRef.current = searchValue
        prevFiltersRef.current = pedidosFilters
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Petición previa cancelada:', error.message)
        } else {
          setPedidosAgrupacion({})
          console.error('Error al obtener los pedidos de agrupación:', error)
        }
      }
    }
    const fetchPedidosProveedor = async () => {
      try {
        await getPedidosProveedor({
          ...pedidosFilters,
          searchValue,
          currentPage,
          activeOrder,
          cancelToken: source.token,
        })
        prevSearchValueRef.current = searchValue
        prevFiltersRef.current = pedidosFilters
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Petición previa cancelada:', error.message)
        } else {
          setPedidosAgrupacion({})
          console.error('Error al obtener los pedidos de agrupación:', error)
        }
      }
    }
    if (
      user.rol &&
      cronograma?.id_cronograma &&
      Object.keys(pedidosFilters).length > 0
    ) {
      if (activeTab === 0 && user.rol.id_rol === '1') {
        fetchPedidosAgrupacion()
      } else if (user.rol.id_rol === '5') {
        fetchPedidosProveedor()
      } else {
        fetchMisPedidos()
      }
    }

    return () => {
      source.cancel('Operación cancelada debido a una nueva solicitud.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    token,
    pedidosFilters,
    currentPage,
    activeOrder,
    searchValue,
    activeTab,
  ])

  return (
    <WrapperPedidosPage>
      <MobileFilterComponent>
        <FilterSection
          filters={pedidosFilters}
          setFilters={(filter) => {
            onPedidosFiltersChange(filter)
          }}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          activeTab={activeTab}
        />
      </MobileFilterComponent>
      <TableSection>
        <Header>
          <Title>
            <H2>{user.rol.id_rol === '3' ? 'Mis pedidos' : 'Pedidos'}</H2>
            <P2>
              {pedidos?.pager?.total !== 0 &&
              pedidos?.pager?.total !== undefined
                ? pedidos?.pager?.total + ' resultados'
                : 'sin resultados'}
            </P2>
          </Title>
        </Header>
        {width < 440 && (
          <DropdownNotification
            text="Para recepcionar pedidos visitá la Central de Compras de la FACC desde una computadora"
            type="notificacion"
          />
        )}
        {user.rol.id_rol == 5 ? (
          <PedidosProveedor
            handleSetActiveOrder={handleSetActiveOrder}
            activeOrder={activeOrder}
          />
        ) : user.rol.id_rol == 1 ? (
          <TabsComponentPedidos
            $tabs={tabs}
            asociada={dataToDisplay}
            activeTab={activeTab}
            setActiveTab={(index) => {
              localStorage.setItem('activeTabPedidos', JSON.stringify(index))
              setActiveTab(index)
              setCurrentPage(1)
            }}
          >
            <PedidosAgrupacionTable
              onClickPedidoDescarga={onClickPedidoDescarga}
              currentPage={currentPage}
              handleSetActiveOrder={handleSetActiveOrder}
              activeOrder={activeOrder}
            />
            <MisPedidosTable
              onClickPedidoDescarga={onClickPedidoDescarga}
              currentPage={currentPage}
              handleSetActiveOrder={handleSetActiveOrder}
              activeOrder={activeOrder}
            />
          </TabsComponentPedidos>
        ) : (
          <Wrapper>
            <MisPedidosTable
              onClickPedidoDescarga={onClickPedidoDescarga}
              currentPage={currentPage}
              handleSetActiveOrder={handleSetActiveOrder}
              activeOrder={activeOrder}
            />
          </Wrapper>
        )}
        <CustomPagination
          totalPages={pedidos?.pager?.ultima_pagina}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
        <CustomNumberElement
          currentElement={
            pedidos?.pager
              ? pedidos?.pager.por_pagina * pedidos?.pager?.pagina_actual -
                pedidos?.pager?.por_pagina +
                pedidos.pedidos?.length
              : 0
          }
          totalElements={pedidos?.pager?.total}
        />
      </TableSection>
    </WrapperPedidosPage>
  )
}

export default PedidosPage
