import React from 'react'
import styled from 'styled-components'

const Text = styled.span`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.09px;
`

const BtnS = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default BtnS
