import React from 'react'
import styled from 'styled-components'

import ToggleSwitch from 'components/Button/ToggleSwitch'
import { P2 } from 'components/Typography'
import Table from 'components/Table/Table'
import { Row } from 'components/layout/FlexComponents'
import Link from 'components/Button/LinkUnderline'
import Select from 'components/Select/Select'
import { useSearchAndFilterTable } from 'contexts/SearchAndFilterTableContext'
import { useCronograma } from 'contexts/CronogramaContext'
import { getDayAndMonthFromFullDate, replaceText } from 'utils/textProcess'
import TableContainer from './TableContainer'
import { useModal } from 'contexts/ModalContext'
import EnableCronogramaModal from 'components/Modal/EnableCronogramaModal'
import { useNavigate } from 'react-router-dom'
import RotatingLoader from 'components/Loading/RotatingLoader'
import ModalNotification from 'components/Notification/ModalNotification'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;
`

const LinkTable = styled(Link)`
  font-size: 14px;
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`
const CustomLoading = styled(RotatingLoader)`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const formatData = (
  data,
  handleZonaChange,
  habilitarDeshabilitarProveedor,
  navigateToProveedorPage,
  mejorProveedorZona,
  bestZona,
  cronograma,
  width,
) =>
  data?.map((proveedor) => ({
    Proveedores: (
      <ProveedoresItem key={proveedor.nombre_comercial}>
        <P2>{proveedor.nombre_comercial}</P2>
      </ProveedoresItem>
    ),
    Zona:
      Object.keys(proveedor.zonas).length > 0 ? (
        <Select
          options={proveedor.zonas}
          bestZona={bestZona ? bestZona[proveedor.id] : false}
          selectedOption={
            // proveedor.tipo_proveedor === '1'
            //   ? 1
            //   :
            proveedor.zonas[proveedor.zona] ? proveedor.zona : 1
          }
          //TODO: ver cuando hay qeu deshabilitar el proveedor
          // disabled={proveedor.habilitado}
          toggleOptionsAction={() => mejorProveedorZona(proveedor.id)}
          onChange={async (zonaId) =>
            await handleZonaChange(zonaId, proveedor.id)
          }
          disabled={proveedor.tipo_proveedor === '1'}
        />
      ) : (
        <CustomLoading />
      ),
    ...(width > 440
      ? {
          Surtidos: (
            <LinkTable onClick={() => navigateToProveedorPage(proveedor)}>
              Ver Surtido
            </LinkTable>
          ),
        }
      : {}),

    Habilitar: (
      <ToggleSwitch
        isChecked={proveedor.habilitado}
        disable={!cronograma.habilitado}
        onClick={async () => {
          await habilitarDeshabilitarProveedor(
            proveedor.habilitado,
            proveedor.id,
          )
        }}
      />
    ),
  }))

const ProveedoresTable = ({ openTable, setOpenTable, toggleOpen }) => {
  const { searchValue, data } = useSearchAndFilterTable()
  const {
    cronograma,
    habilitarDeshabilitarProveedor,
    cambiarZona,
    mejorProveedorZona,
    bestZona,
  } = useCronograma()
  const navigate = useNavigate()
  const { width } = useWindowWidth()
  const { habilitarCronograma } = useCronograma()
  const { openModal, isModalOpen, closeModal } = useModal()

  const navigateToProveedorPage = async (proveedor) => {
    navigate(
      `/comprar/${replaceText(proveedor.nombre_comercial, ' ', '-')}/id/${proveedor.id}`,
    )
  }

  const headers = [
    {
      name: 'Proveedores',
      isOrderable: false,
    },
    {
      name: 'Zonas disponibles',
      isOrderable: false,
    },
    ...(width > 440
      ? [
          {
            name: 'Surtido',
            isOrderable: false,
          },
        ]
      : []),
    {
      name: 'Habilitar',
      isOrderable: false,
    },
  ]

  function todosHabilitados(objeto) {
    for (let elemento of objeto) {
      if (!elemento.habilitado) {
        return false
      }
    }
    return true
  }

  return (
    <TableContainer
      title="Habilitar"
      subtitle={`Hasta el ${replaceText(
        getDayAndMonthFromFullDate(cronograma.fecha_fin),
        '-',
        '/',
      )}`}
      centerText={`Cronograma N°${cronograma.id_cronograma}`}
      open={openTable['proveedores']}
      toggleOpen={() => toggleOpen('proveedores')}
      actionableTable={() => {
        openModal()
      }}
      buttonFooterText={
        cronograma.habilitado
          ? 'Deshabilitar cronograma'
          : 'Habilitar cronograma'
      }
      buttonType={cronograma.habilitado ? 'secondary' : 'primary'}
    >
      {data?.length > 0 ? (
        <Table
          data={formatData(
            data.filter((prove) => {
              return prove.zonas !== null && prove.zonas !== undefined
            }),
            cambiarZona,
            habilitarDeshabilitarProveedor,
            navigateToProveedorPage,
            mejorProveedorZona,
            bestZona,
            cronograma,
            width,
          )}
          headers={headers}
          gridTemplate={width > 440 ? '30% 30% 20% 10%' : '34% 40% 20%'}
          $paddingVariant="compact"
        />
      ) : (
        <EmptyTableData>
          No se encontraron proveedores que contengan el texto {searchValue}
        </EmptyTableData>
      )}
      {/* agregar número de cronograma a habilitar al integrar la DB */}
      {isModalOpen && !cronograma.habilitado && (
        <EnableCronogramaModal
          cronogramaNumber={cronograma.id}
          setOpenTable={setOpenTable}
          todosHabilitados={todosHabilitados(data)}
        />
      )}
      {isModalOpen && cronograma.habilitado && (
        <ModalNotification
          type="alerta"
          title="Estás por deshabilitar el cronograma"
          descripcion={[
            'Si seleccionas ',
            'CONTINUAR ',
            'este cronograma quedará deshabilitado. Podés editarlo y habilitarlo nuevamente ',
          ]}
          primaryFunction={() => {
            habilitarCronograma()
            closeModal()
          }}
          secondaryFunction={() => closeModal()}
          afterClose={() => {}}
        />
      )}
    </TableContainer>
  )
}

export default ProveedoresTable
