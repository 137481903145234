import React from 'react'
import styled from 'styled-components'

import { useCronograma } from 'contexts/CronogramaContext'
import { Lock, Unlock } from 'feather-icons-react/build/IconComponents'
import { formatDate } from 'utils/textProcess'
import { useAuth } from 'contexts/AuthContext'

const StyledCronogramaDateText = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  gap: 6px;
`

const StyledLock = styled(Lock)`
  stroke: ${({ theme }) => theme.colors.neutral.white};
  stroke-width: 3;
`

const StyledUnlock = styled(Unlock)`
  stroke: ${({ theme }) => theme.colors.neutral.white};
  stroke-width: 3;
`

const Text = styled.div`
  margin-right: 0;
`

const CronogramaDateText = () => {
  const { cronograma } = useCronograma()
  const { user } = useAuth()

  return (
    <StyledCronogramaDateText>
      {user?.rol?.id_rol &&
        cronograma?.fecha_inicio &&
        cronograma?.fecha_fin && (
          <>
            {(cronograma?.estado === '1' &&
              cronograma.habilitado &&
              user.rol.id_rol !== '3') ||
            user.rol.id_rol === '3' ? (
              <StyledUnlock size={14} />
            ) : (
              <StyledLock size={14} />
            )}
            <Text>Cronograma {cronograma.id_cronograma}</Text>
            <span> - </span>
            {formatDate(cronograma.fecha_inicio).slice(0, 5)} -{' '}
            {formatDate(cronograma.fecha_fin).slice(0, 5)}
          </>
        )}
    </StyledCronogramaDateText>
  )
}

export default CronogramaDateText
