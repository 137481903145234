import { P2 } from 'components/Typography'
import React, { useState } from 'react'
import styled from 'styled-components'
import CartItem from './CartItem'
import { Button } from 'components/index'
import { useCarrito } from 'contexts/CarritoContext'
import Loading from 'components/Loading/Loading'
import { useModal } from 'contexts/ModalContext'
import DeleteProveedorModal from 'components/Modal/DeleteProveedorModal'

const StyledShoppingCart = styled.div`
  position: absolute;
  top: 50px;
  right: -80px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  display: flex;
  flex-direction: column;
  width: 300px;
  max-height: 70vh;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 999;
  transform: translateX(-75px);

  &:before {
    content: '';
    position: absolute;
    top: -24px;
    right: 12px;
    width: 0;
    height: 0;
    z-index: 999;
    border-left: 12px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 25px solid ${({ theme }) => theme.colors.neutral.white};

    @media (max-width: 440px) {
      right: 86px;
    }
  }

  @media (max-width: 440px) {
    width: 270px;
    right: -150px;
  }
`

const CartHeader = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  width: 100%;
  padding: 15px 15px 10px 25px;
  box-sizing: border-box;
  text-align: start;
`

const CartFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.lighterGray};
`

const CartBody = styled.div`
  overflow-y: scroll;
`

const Vacio = styled(P2)`
  font-style: italic;
  font-weight: 400;
  margin-bottom: 10px;
`

const ShoppingCart = React.forwardRef(({ closeCart }, ref) => {
  const { openModal, isModalOpen } = useModal()
  const [deleteIdProveedor, setDeleteIdProveedor] = useState(null)
  const { carritoAgrupado, loadingCarritoAgrupado } = useCarrito()

  const handleClickVerCarrito = () => {
    closeCart()
  }
  return (
    <>
      {isModalOpen && deleteIdProveedor !== null ? (
        <DeleteProveedorModal
          idProveedor={deleteIdProveedor}
          afterClose={() => {}}
        />
      ) : (
        <StyledShoppingCart ref={ref}>
          <CartHeader>
            <P2>Mi Carrito</P2>
          </CartHeader>
          <CartBody>
            {loadingCarritoAgrupado ? (
              <Loading />
            ) : carritoAgrupado !== undefined &&
              carritoAgrupado?.resumenDetalle &&
              Object.keys(carritoAgrupado?.resumenDetalle).length > 0 ? (
              Object.keys(carritoAgrupado.resumenDetalle).map(
                (pedido, index) => {
                  return (
                    <CartItem
                      pedido={carritoAgrupado.resumenDetalle[pedido]}
                      key={index}
                      setDeleteIdProveedor={(id) => {
                        setDeleteIdProveedor(id)
                        openModal()
                      }}
                    />
                  )
                },
              )
            ) : (
              <Vacio>-- Vacío --</Vacio>
            )}
          </CartBody>
          <CartFooter>
            <Button text="Ver Carrito" onClick={handleClickVerCarrito} />
          </CartFooter>
        </StyledShoppingCart>
      )}
    </>
  )
})

ShoppingCart.displayName = 'ShoppingCart'

export default ShoppingCart
