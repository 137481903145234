import React, { useState } from 'react'
import styled from 'styled-components'
import NavbarOptions from './NavbarOptions'
import NavbarActions from './NavbarActions'
import { Menu } from 'feather-icons-react'
import LogoFaccCentral from 'components/images/LogoFaccCentral.jsx'
import { useAuth } from 'contexts/AuthContext'
import Link from 'components/Button/Link'
import { Wrapper } from 'components/layout/FlexComponents'
import RoutesComponent from 'components/layout/navbar/router/RoutesComponent'
import { useLocation } from 'react-router-dom'
import { P1 } from 'components/Typography'
import { useInitialData } from 'contexts/InitialDataContext'
import RotatingLoader from 'components/Loading/RotatingLoader'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const StyledNavbar = styled.nav`
  display: flex;
  position: sticky;
  top: 0;
  padding: 16px 32px;
  background-color: ${({ theme }) => theme.colors.neutral.lighterGray};
  z-index: 1000;
  @media (max-height: 768px) {
    max-height: 72px;
    box-sizing: border-box;
    align-items: start;
    padding: 14px 32px 8px;
  }
  @media (max-width: 979px) {
    padding: 16px 24px;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
  }
`

const Logo = styled(LogoFaccCentral)`
  flex-shrink: 0;
  height: 60px;
  width: auto;
  align-self: center;
  @media (max-height: 768px) {
    height: 46px;
  }
`

const MenuToggle = styled.div`
  display: block;
  font-size: 24px;
  cursor: pointer;
  align-content: center;
`

const MenuList = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  position: absolute;
  top: 90px;
  background: ${({ theme }) => theme.colors.neutral.lighterGray};
  padding: 10px 0 10px 20px;
  border-radius: 8px;
  width: 90%;
  right: 0px;
  height: 240px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  z-index: 2000;
  justify-content: space-between;
  @media (max-height: 768px) {
    top: 56px;
  }
`

const NavbarOptionsStyled = styled(NavbarOptions)`
  flex-direction: column;
  gap: 22px;
  padding: 0;
`

const MenuWrapper = styled(Wrapper)`
  align-items: initial;
  @media (max-width: 979px) {
    display: none;
  }
`

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 979px) {
    flex-direction: column;
    align-items: flex-end;
    display: none;
  }
  @media (max-width: 440px) {
  }
`

const NavigateWrapper = styled(Wrapper)`
  width: 100%;
  @media (min-width: 1180px) {
    margin-right: 230px;
  }
  @media (max-width: 979px) {
    align-items: flex-end;
    margin-right: 0;
  }
`

const UserMenuWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
  height: 32px;
`

const ActionsWrapper = styled.div`
  @media (max-width: 979px) {
    margin-left: auto;
    padding-right: 20px;
  }
`

const NavbarLogoutContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledLink = styled(Link)`
  font-weight: 400;
  white-space: nowrap;
  text-decoration: none;
  color: initial;
  &:visited {
    color: initial;
  }
`

const LogOutLink = styled.li`
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  list-style-type: none;
  margin-bottom: 10px;
  color: initial;
  &:visited {
    color: initial;
  }
`

const RolDescription = styled(P1)`
  display: flex;
  align-items: center;
  font-weight: 600;
`

const NameSection = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;

  @media (max-width: 440px) {
    margin-top: 8px;
  }
`

const CustomRotatingLoader = styled(RotatingLoader)`
  width: 40px;
`

const Navbar = ({ dontHandleRoutes }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()
  const { width } = useWindowWidth()
  const { user, logout, logoutLoading } = useAuth()
  const { loading, errorLoadingData } = useInitialData()
  const routesComponentView =
    location.pathname !== '/login' && location.pathname !== 'reset-password'

  const handleMenuToggle = () => {
    setMenuOpen((prevState) => !prevState)
  }

  const getNavOptions = () => {
    const genericNavOptions = [
      {
        id: 1,
        name: 'Comprar',
        url: '/comprar',
      },
      {
        id: 2,
        name: 'Pedidos',
        url: '/pedidos',
      },
    ]

    const pedidosOption = {
      id: 2,
      name: 'Mis Pedidos',
      url: '/pedidos',
    }

    const coordNavOptions = {
      id: 3,
      name: 'Coordinación',
      url: '/coordinacion',
    }

    const proveedorNavOptions = {
      id: 4,
      name: 'Mis Productos',
      url: '/mis-productos',
    }

    const navOptions = {
      Coordinadora: [...genericNavOptions, coordNavOptions],
      Transporte: [pedidosOption],
      Proveedor: [pedidosOption, proveedorNavOptions],
      Asociada: [genericNavOptions[0], pedidosOption],
      'Asociada Individual': [genericNavOptions[0], pedidosOption],
    }

    return navOptions[user.rol.nombre] || genericNavOptions
  }

  return (
    <StyledNavbar>
      <StyledLink href="/">
        <Logo />
      </StyledLink>
      {routesComponentView &&
        user?.rol?.id_rol &&
        !loading &&
        !errorLoadingData &&
        (width <= 979 ? (
          <>
            <ActionsWrapper>
              <NavbarActions />
            </ActionsWrapper>
            <MenuToggle onClick={handleMenuToggle}>
              {menuOpen ? <Menu /> : <Menu />}
            </MenuToggle>
            <MenuList $isOpen={menuOpen}>
              <NameSection>
                {user.abreviatura && <P1>{user.abreviatura}</P1>}-
                <RolDescription>{user.rol.nombre}</RolDescription>
              </NameSection>
              <NavbarOptionsStyled options={getNavOptions()} />
              <LogOutLink onClick={logout}>
                {logoutLoading ? <CustomRotatingLoader /> : 'Cerrar sesión'}
              </LogOutLink>
            </MenuList>
          </>
        ) : (
          <MenuWrapper>
            <OptionsWrapper>
              <NavigateWrapper>
                <NavbarOptions options={getNavOptions()} />
              </NavigateWrapper>
              <UserMenuWrapper>
                <ActionsWrapper>
                  <NavbarActions />
                </ActionsWrapper>
                <NameSection>
                  {user.operador?.login && <P1>{user.operador.login}</P1>}-
                  <RolDescription>{user.rol.nombre}</RolDescription>|
                </NameSection>

                <NavbarLogoutContainer>
                  <StyledLink onClick={logout}>
                    {logoutLoading ? <CustomRotatingLoader /> : 'Cerrar sesión'}
                  </StyledLink>
                </NavbarLogoutContainer>
              </UserMenuWrapper>
            </OptionsWrapper>
          </MenuWrapper>
        ))}

      {routesComponentView &&
        user?.rol?.id_rol &&
        !loading &&
        !errorLoadingData && (
          <RoutesComponent dontHandleRoutes={dontHandleRoutes} />
        )}
    </StyledNavbar>
  )
}

export default Navbar
