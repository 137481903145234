import React from 'react'
import styled from 'styled-components'

import CardCarousel from './components/CardCarousel'
import { Wrapper } from 'components/layout/FlexComponents'
import BannerCarousel from './components/Banner'
import circleImg from 'assets/circulo_home.png'
import backgroundImgHome from 'assets/fondo_punto.svg'
import { banners } from 'utils/BannersMocks'
import HorizontalCarousel from 'components/Carousel/HorizontalCarousel'
import HorizontalPromoCarousel from 'components/Carousel/HorizontalPromoCarousel'
import { orderByObjectKeyValue, shuffle } from 'utils/arrayFunctions'
import DropdownNotification from 'components/Notification/DropdownNotification'

const WrapperNotification = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  box-sizing: border-box;
  padding: 32px;
`

const CustomNotification = styled(DropdownNotification)`
  width: 90%;
  z-index: 999;
`

const ImageOverlay = styled.img`
  position: absolute;
  width: 100%;
  height: 25vh;
`

const WrapperHome = styled(Wrapper)`
  background-image: url(${backgroundImgHome});
  background-repeat: repeat;
  background-position: 5px 5px;
  background-size: auto;
`

const WrapperCards = styled(Wrapper)`
  margin: 30px 0;
`

const BannerContainer = styled.div`
  display: block;
  width: 100%;
  @media (max-width: 440px) {
    display: none;
  }
`

const HomeAsociada = ({
  promocionesLoading,
  promociones,
  proveedoresLoading,
  proveedoresActivos,
  formatData,
  cronogramaHabilitado,
}) => {
  return (
    <WrapperHome>
      {!cronogramaHabilitado && (
        <WrapperNotification>
          <CustomNotification
            text="El cronograma aún no fue habilitado. Comunicarse con la coordinadora para que lo habilite."
            type="alerta"
          />
        </WrapperNotification>
      )}
      <ImageOverlay src={circleImg} alt="Logo" />
      <WrapperCards>
        <CardCarousel />
      </WrapperCards>

      {!promocionesLoading &&
        promociones !== undefined &&
        promociones !== null &&
        cronogramaHabilitado &&
        Object.keys(promociones).length > 0 && (
          <HorizontalPromoCarousel
            title="Promociones"
            type="primary"
            cards={formatData(shuffle(promociones), 'promociones')}
            cronogramaHabilitado={cronogramaHabilitado}
          />
        )}

      {!proveedoresLoading &&
        proveedoresActivos &&
        Object.keys(proveedoresActivos).length > 0 && (
          <HorizontalCarousel
            title="Proveedores"
            cards={formatData(
              orderByObjectKeyValue(proveedoresActivos, 'puedePedir', true),
              'proveedores',
            )}
          />
        )}
      <BannerContainer>
        <BannerCarousel banners={banners} />
      </BannerContainer>
    </WrapperHome>
  )
}

export default HomeAsociada
