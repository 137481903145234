import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Actualiza el estado para que la siguiente vez que se renderice, muestre la UI alternativa
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Puedes enviar el error a un servicio de reporte de errores
    console.error('Error capturado en Error Boundary: ', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // Puedes renderizar cualquier interfaz alternativa
      return <h1>Algo salió mal. Por favor, inténtalo de nuevo más tarde.</h1>
    }

    return this.props.children
  }
}

export default ErrorBoundary
