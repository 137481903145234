import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { reemplazarInterrogacionPorÑ, replaceText } from 'utils/textProcess'
import { useAuth } from 'contexts/AuthContext'
import { charIsNumber } from 'react-currency-format/lib/utils'
import { useArticulos } from 'contexts/ArticulosContext'

const StyledBreadcrumbs = styled.nav`
  align-items: center;
  display: flex;
  font-size: 14px;
  text-transform: uppercase;

  @media (max-width: 440px) {
    display: none;
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.neutral.white};
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  vertical-align: middle;
  &:not(:last-of-type):after {
    content: ' / ';
    margin-right: 0.25em;
  }
  &:last-of-type {
    font-weight: 700;
  }
  cursor: ${({ route }) => (route === 'recepcion' ? 'default' : 'pointer')};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Breadcrumbs = ({ className }) => {
  const location = useLocation()
  const { user } = useAuth()
  const { articulo } = useArticulos()
  const formatRoutesArray = () => {
    const arr = location.pathname.split('/')
    const comprobantesIndex = arr.indexOf('comprobantes')
    const replaceSpaceCharacters = (arr) => {
      return arr.map((item) => item.replace(/%20/g, ' '))
    }
    if (comprobantesIndex !== -1) {
      arr.splice(comprobantesIndex, 0)
    }
    // usuarios asociada individual y subasociada cambia el nombre de la ruta de "pedidos" a "mis-pedidos"
    if (user.rol.id_rol === '2' || user.rol.id_rol === '3') {
      arr.indexOf('pedidos') !== -1
      arr[arr.indexOf('pedidos')] = 'mis-pedidos'
    }
    // quita id y el ${numero_id} del componente breadcrumbs
    if (charIsNumber(arr[arr.length - 1]) && arr[arr.length - 2] === 'id') {
      arr.pop()
      arr.pop()
    }
    return [...new Set(replaceSpaceCharacters(arr))]
  }
  const routesArray = formatRoutesArray()
  const navigate = useNavigate()
  function navigateBack(e) {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <StyledBreadcrumbs className={className}>
      {routesArray.map((route, index) => {
        route = reemplazarInterrogacionPorÑ(decodeURIComponent(route))
        const linkRoute =
          route === 'pedidos-agrupacion' ||
          route === 'consolidacion-pedido' ||
          route === 'mis-pedidos'
            ? 'pedidos'
            : route === ''
              ? ''
              : routesArray.includes('comprar')
                ? `comprar/${replaceText(route, ' ', '-')}`
                : route
        return route === '' ? (
          <StyledLink to={'/'} key={index}>
            inicio
          </StyledLink>
        ) : route === 'detalle-proveedor' ? (
          // la ruta 'detalle-proveedor' navega hacia atrás a comprar/:nombreProveedor
          <StyledLink onClick={navigateBack} key={index}>
            {route === '' ? 'inicio' : replaceText(route, '-', ' ')}
          </StyledLink>
        ) : // manejo de ruta 'comprar/:proveedor.nombre_comercial/:idProducto
        route === 'comprar' ? (
          <>
            <StyledLink to={'/comprar'} key={index}>
              {route === '' ? 'inicio' : replaceText(route, '-', ' ')}
            </StyledLink>
          </>
        ) : charIsNumber(routesArray[routesArray.length - 1]) &&
          routesArray.includes('comprar') ? (
          <>
            <StyledLink
              to={
                '/comprar/' + route + '/id/' + articulo?.articulo?.id_proveedor
              }
              key={index}
            >
              {route === '' ? 'inicio' : replaceText(route, '-', ' ')}
            </StyledLink>
          </>
        ) : (
          <StyledLink
            to={'/' + linkRoute}
            key={route}
            style={{
              pointerEvents:
                /* Rutas que no deben redireccionar */
                route === 'recepcion' ||
                route === 'confirmados' ||
                route === 'comprobantes' ||
                route === routesArray[routesArray.length - 1]
                  ? 'none'
                  : 'auto',
            }}
          >
            {route === '' ? 'inicio' : replaceText(route, '-', ' ')}
          </StyledLink>
        )
      })}
    </StyledBreadcrumbs>
  )
}

export default Breadcrumbs
