import { H1, P1, P2 } from 'components/Typography'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  capitalizeFirstLetter,
  compressLongText,
  reemplazarInterrogacionPorÑ,
} from 'utils/textProcess'

const StyledResultsListContainer = styled.div`
  position: absolute;
  right: 4px;
  left: -291px;
  top: -2px;
  bottom: 0;
  padding-top: 16px;
  background-color: #fff;
  z-index: 998;
  box-sizing: border-box;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
`

const StyledResultsList = styled.ul`
  list-style: none;
  max-height: 300px;
  background-color: #fff;
  overflow-y: scroll;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 0;
  margin-right: 0;
  width: 319px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  scrollbar-color: ${({ theme }) =>
    theme.colors.primary.darkBlue + ' ' + 'white'};
`

const ListItem = styled.li`
  padding: 6px 18px;
  cursor: pointer;
  font-size: 0.8rem;
  border-bottom: ${({ theme }) => theme.colors.neutral.lightGray} 1px solid;
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.lightGray};
  }
  a {
    margin: 0;
  }
  &:last-of-type {
    border-bottom: none;
  }
`

const NoResultsListItem = styled(ListItem)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.white};
  }
  cursor: default;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
`
const StyledH1 = styled(H1)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  font-family: 'Roboto-bold';
  font-size: 1.3em;
  line-height: 1.5em;
`

const StyledP2 = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral.black};
  margin-bottom: 8px;
  font-size: 1em;
`

const StyledBoldP1 = styled(P1)`
  color: ${({ theme }) => theme.colors.neutral.black};
  font-family: 'Roboto-bold';
`

const SearchResultsList = ({ results, resetSearch }) => {
  const navigate = useNavigate()

  const handleClick = (e, nombreProveedor, id) => {
    e.preventDefault()
    navigate(`/comprar/${reemplazarInterrogacionPorÑ(nombreProveedor)}/${id}`)
    resetSearch()
  }

  return results?.length > 0 ? (
    <StyledResultsListContainer>
      <StyledResultsList>
        {results?.map((item, index) => {
          return (
            item &&
            item.descripcion &&
            item.proveedor && (
              <ListItem
                key={index}
                onClick={(e) =>
                  handleClick(e, item.proveedor, item.id_articulo)
                }
              >
                <StyledH1>
                  {capitalizeFirstLetter(
                    reemplazarInterrogacionPorÑ(
                      compressLongText(item.descripcion, 32).toLowerCase(),
                    ),
                  )}
                </StyledH1>
                <StyledP2>
                  {capitalizeFirstLetter(
                    reemplazarInterrogacionPorÑ(item.proveedor.toLowerCase()),
                  )}
                </StyledP2>
                <StyledBoldP1>Precio: ${item.precio}</StyledBoldP1>
              </ListItem>
            )
          )
        })}
      </StyledResultsList>
    </StyledResultsListContainer>
  ) : (
    <StyledResultsListContainer>
      <StyledResultsList>
        <NoResultsListItem>Sin resultados</NoResultsListItem>
      </StyledResultsList>
    </StyledResultsListContainer>
  )
}

export default SearchResultsList
