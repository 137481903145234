import {
  ChevronDown,
  ChevronUp,
} from 'feather-icons-react/build/IconComponents'
import React from 'react'
import styled from 'styled-components'

const TableCell = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`

const HeaderCell = styled(TableCell)`
  text-transform: uppercase;
  display: flex;
  font-weight: ${({ $isActive }) => ($isActive ? 'bold' : '400')};
`

const IconContainer = styled.div`
  margin-left: 4px;
  line-height: 0.5;
  cursor: pointer;
`

const TableRow = styled.div`
  display: grid;
  font-family: Roboto;
  font-size: 12px;
  text-align: start;
  font-style: normal;
  font-weight: 600;
  grid-template-columns: ${({ $gridTemplate }) => $gridTemplate};
  gap: ${({ $gap }) => $gap || 15}px;
  padding: 6px 30px;
  background-color: ${({ theme, $index }) =>
    $index % 2 === 0 || $index === '0'
      ? theme.colors.neutral.white
      : theme.colors.neutral.almostWhite};

  @media (max-width: 1366px) {
    padding: 6px 14px;
    gap: ${({ $gap }) => $gap || 12}px;
  }

  @media (max-width: 440px) {
    padding: 4px 8px;
    gap: ${({ $gap }) => $gap || 10}px;
  }
`

const HeaderTable = ({
  numerate = false,
  headers,
  gridTemplate,
  className,
  activeOrder = '',
  handleSetActiveOrder,
  gap,
  camposOrden = [],
}) => {
  const columns = headers.length > 0 ? Object.keys(headers[0].name) : []
  const columnWidths = columns.map(() => '1fr')
  const calculateGridTemplate = ` ${numerate ? '18px ' : ''} ${
    gridTemplate ? gridTemplate : columnWidths
  }`.replaceAll(',', ' ')

  return (
    <TableRow
      className={className}
      $gridTemplate={calculateGridTemplate}
      $gap={gap}
    >
      {numerate && <TableCell key="number"></TableCell>}
      {headers.map((header, index) => {
        const isActive = activeOrder.includes(header.name.toLowerCase())
        return (
          <HeaderCell $isActive={isActive} key={index}>
            {header.name}
            {camposOrden.includes(header.tag) && (
              <IconContainer onClick={() => handleSetActiveOrder(header.name)}>
                {isActive && !activeOrder.startsWith('-') ? (
                  <ChevronUp size={16} />
                ) : (
                  <ChevronDown size={16} />
                )}
              </IconContainer>
            )}
          </HeaderCell>
        )
      })}
    </TableRow>
  )
}

export default HeaderTable
