import React from 'react'
import styled from 'styled-components'
import { Button } from 'components/index'
import { Wrapper } from 'components/layout/FlexComponents'
import {
  ChevronDown,
  ChevronUp,
} from 'feather-icons-react/build/IconComponents'

const Footer = styled(Wrapper)`
  padding: ${({ $open }) => ($open ? '22px 0 12px 0' : '0 0 24px')};
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    box-shadow: ${({ $open }) =>
      $open ? '0px -9px 10px rgba(0, 0, 0, 0.2)' : 'none'};
  }
`

const ButtonStyle = styled(Button)`
  margin-bottom: 8px;
  cursor: pointer;
`

const StyledButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
`

const TableFooter = ({
  open,
  toggleOpen,
  actionableTable,
  buttonFooterText,
  buttonType,
  loading,
}) => {
  return (
    <Footer $open={open && !loading}>
      {open ? (
        <>
          {buttonFooterText && (
            <ButtonStyle
              type={buttonType}
              text={buttonFooterText}
              onClick={actionableTable}
            />
          )}
          <StyledButton>
            <ChevronUp
              size={30}
              onClick={toggleOpen}
              style={{ cursor: 'pointer' }}
            />
          </StyledButton>
        </>
      ) : (
        <StyledButton>
          <ChevronDown
            size={30}
            onClick={toggleOpen}
            style={{ cursor: 'pointer' }}
          />
        </StyledButton>
      )}
    </Footer>
  )
}

export default TableFooter
