import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  colors: {
    primary: {
      lighterBlue: '#A0B9EA',
      lightBlue: '#7096FF',
      blue: '#0062FF',
      darkBlue: '#163488',
      transparentBlue: 'rgba(160, 185, 234, 0.2)',
    },
    neutral: {
      black: '#162240',
      darkGray: '#777777',
      skyBlue: '#F1F8FE',
      darkSkyBlue: '#DEE1EE',
      almostWhite: '#F2F3F9',
      white: '#FFFFFF',
      gray: '#c8c8c8',
      disableGrey: '#DFE1EF',
      lighterGray: '#EDEDED',
      lightGray: '#E5E5E5',
    },
    alarmsStatuses: {
      red: '#DC2626',
      yellow: '#FBBF24',
      green: '#10B981',
    },
  },
  fonts: ['sans-serif', 'Roboto'],
  fontSizes: {},
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
