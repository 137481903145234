import React from 'react'
import styled from 'styled-components'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import { H3, H5 } from 'components/Typography'
import { useAuth } from 'contexts/AuthContext'

const WrapperInfo = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral.gray};
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 32px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 14px 44px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};

  @media (max-width: 440px) {
    flex-wrap: unset;
    flex-direction: column;
    row-gap: 14px;
  }
`

const WrapperExpandabletables = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 20px;
`

const InfoBox = styled.div`
  border-left: ${({ theme }) => theme.colors.primary.darkBlue} 3px solid;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 0 5px 5px 0;
  padding: 12px 8px;
  display: inline-block;
  width: ${(props) => (props.$isfullwidth ? '100%' : 'calc(25% - 28px)')};

  @media (max-width: 440px) {
    width: 100%;
  }
`

const Title = styled(H3)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const Header = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  padding: 24px;
`

const formatInfo = (user) => {
  return [
    {
      title: 'Información',
      component: (
        <WrapperInfo>
          {[
            {
              titulo: 'Operador',
              valor: user.abreviatura,
            },
            {
              titulo: 'Último acceso',
              valor: '24/4/2024',
            },
            {
              titulo: 'CUIT',
              valor: user.cuit,
            },
            {
              titulo: 'Categoría de IVA',
              valor: user.categ_iva,
            },
            {
              titulo: 'Usuario',
              valor: user.operador.login,
            },
            {
              titulo: 'Agrupación',
              valor:
                user.rol.id_rol === '3'
                  ? 'asoc. ind.'
                  : user?.grupo?.descripcion,
            },
            {
              titulo: 'N° Asosc FACC',
              valor: user.asoc_facc,
            },
            {
              titulo: 'Dirección',
              valor: user.domicilio,
            },
            {
              titulo: 'Localidad',
              valor: user.localidad.provincia,
            },
            {
              titulo: 'Código postal',
              valor: user.cod_postal,
            },
            {
              titulo: 'Teléfono',
              valor: user.telefono,
            },
          ].map((item) => (
            <>
              <InfoBox key={item.nombre}>
                <H5>{item.titulo}</H5>
                {item.valor && <strong>{item.valor}</strong>}
              </InfoBox>
            </>
          ))}
        </WrapperInfo>
      ),
    },
    // {
    //   title: 'Lugares de entrega',
    //   component: (
    //     <WrapperInfo>
    //       {user.lugares_entrega.map((lugar) => {
    //         return [
    //           {
    //             titulo: 'Dirección',
    //             valor: lugar.descripcion,
    //           },
    //         ].map((item) => (
    //           <>
    //             <InfoBox key={item.nombre}>
    //               <H5>{item.titulo}</H5>
    //               {item.valor && <strong>{item.valor}</strong>}
    //             </InfoBox>
    //           </>
    //         ))
    //       })}
    //     </WrapperInfo>
    //   ),
    // },
  ]
}

const DatosPerfil = () => {
  const { user } = useAuth()

  return (
    <div>
      <Title>Datos de mi perfil</Title>
      <WrapperExpandabletables>
        <Header>
          <H3>{user.nombre_comercial}</H3>
        </Header>
        {
          <ExpandableTables
            items={formatInfo(user)}
            openTables={['Información', 'Lugares de entrega']}
          />
        }
      </WrapperExpandabletables>
    </div>
  )
}

export default DatosPerfil
