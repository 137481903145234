import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { X } from 'feather-icons-react'
import IconButton from 'components/Button/IconButton'
import { useModal } from 'contexts/ModalContext'
import RotatingLoader from 'components/Loading/RotatingLoader'

const BackgroundOverlay = styled.div`
  background-color: #00000096;
  width: 100%;
  height: 100%;
  z-index: 1198;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
`

const StyledModal = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  border-radius: 30px;
  padding: 24px 32px 64px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  text-align: center;
  z-index: 1199;
  box-sizing: content-box;
  max-width: 60%;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
`

const RemitoModal = ({ loading, children }) => {
  const { closeModal } = useModal()
  const modalRef = useRef()

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [closeModal])

  return (
    <BackgroundOverlay>
      <StyledModal ref={modalRef}>
        <IconButton icon={<X size={24} />} onClick={() => closeModal()} />{' '}
        {loading ? <RotatingLoader /> : children}
      </StyledModal>
    </BackgroundOverlay>
  )
}

export default RemitoModal
