import React from 'react'
import styled from 'styled-components'

import { Row } from 'components/layout/FlexComponents'
import Table from 'components/Table/Table'
import { P4 } from 'components/Typography'
import { FileText } from 'feather-icons-react/build/IconComponents'
import { usePedidos } from 'contexts/PedidosContext'
import { useAuth } from 'contexts/AuthContext'
import { useCronograma } from 'contexts/CronogramaContext'
import LinkUnderline from 'components/Button/LinkUnderline'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import Loading from 'components/Loading/Loading'
import HeaderTable from 'components/Table/HeaderTable'
import StatusTag from 'components/Tag/StatusTag'
import { Button } from 'components/index'
import {
  ESTADOS_DESHABILITAR_RECEPCION_STR,
  ESTADOS_OCULTAR_COMPROBANTES_STR,
} from 'utils/EstadosPedidos'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;
`

const DataBoxNumberLink = styled(LinkUnderline)`
  color: ${({ theme }) => theme.colors.neutral.black};
`

const Nombre = styled(P4)`
  font-weight: bold;
`

const ComprobanteIcon = styled(FileText)`
  width: 18px;
  height: 18px;
  margin: 0 4px;
  cursor: pointer;
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ButtonIrAComprar = styled(Button)`
  width: 116px;
  padding: 8px 20px;
`

const EmptyPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PercentageIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid
    ${({ theme, $porcentajeRecepcion }) =>
      $porcentajeRecepcion === 0
        ? theme.colors.neutral.gray
        : $porcentajeRecepcion === 100
          ? theme.colors.alarmsStatuses.green
          : $porcentajeRecepcion > 0
            ? theme.colors.alarmsStatuses.yellow
            : theme.colors.primary.lightBlue};
  background-color: ${({ theme, $porcentajeRecepcion }) =>
    $porcentajeRecepcion === 100
      ? theme.colors.alarmsStatuses.green
      : theme.colors.neutral.white};
  color: ${({ theme, $porcentajeRecepcion }) =>
    $porcentajeRecepcion === 0
      ? theme.colors.neutral.gray
      : $porcentajeRecepcion === 100
        ? theme.colors.neutral.white
        : $porcentajeRecepcion > 0
          ? theme.colors.alarmsStatuses.yellow
          : theme.colors.primary.lightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: 'Roboto-bold';
`

const Porcentaje = ({ value }) => {
  return (
    <PercentageIcon $porcentajeRecepcion={value}>{value + '%'}</PercentageIcon>
  )
}

const formatData = ({
  idCronograma,
  pedidos,
  onClickPedidosOC,
  onClickComprobantesDetalle,
  user,
  width,
}) => {
  return pedidos
    ?.filter((pedido) => pedido.id_proveedor !== null)
    ?.map((pedido) => ({
      OC:
        pedido?.estado?.descripcion !== 'Confeccionado' ? (
          <DataBoxNumberLink
            onClick={() =>
              onClickPedidosOC(
                user.rol.id_rol === '1' || user.rol.id_rol === '2'
                  ? pedido.ordenDeCompraVisible
                  : pedido.ordenDeCompra,
                pedido.id,
              )
            }
          >
            {user.rol.id_rol === '1' || user.rol.id_rol === '2'
              ? pedido.ordenDeCompraVisible
              : pedido.ordenDeCompra}
          </DataBoxNumberLink>
        ) : (
          <></>
        ),
      Estado:
        pedido?.estado?.descripcion === 'Facturado' &&
        (user?.rol?.id_rol === '1' || user?.rol?.id_rol === '2') &&
        pedido.tipo_proveedor === '0' ? (
          <StatusTag estado="Despachado" />
        ) : pedido?.estado?.descripcion === 'Confeccionado' ? (
          <StatusTag
            estado={
              pedido.idCronograma === idCronograma
                ? pedido.estado.descripcion
                : 'No Consolidado'
            }
          />
        ) : (
          <StatusTag estado={pedido?.estado?.descripcion} />
        ),
      Asociada: (
        <ProveedoresItem key={pedido.afiliado}>
          <Nombre>{pedido.afiliado}</Nombre>
        </ProveedoresItem>
      ),
      ...(width > 440
        ? {
            Fecha: (
              <P4>
                {pedido.fecha_confeccion
                  ? dayjs(pedido.fecha_confeccion).format('DD/MM/YYYY')
                  : ''}
              </P4>
            ),

            ...(pedido.tipo_proveedor === '1' ||
            ESTADOS_DESHABILITAR_RECEPCION_STR.includes(
              pedido?.estado?.descripcion?.toLowerCase(),
            )
              ? { PorcentajeRecepcion: <></> }
              : {
                  PorcentajeRecepcion: (
                    <Porcentaje
                      value={
                        user?.rol?.id_rol === '1'
                          ? pedido.porcentajeRecepcion
                          : pedido.estado.recepcion
                      }
                    />
                  ),
                }),

            Entrega: <P4>{pedido.entrega}</P4>,
          }
        : {}),
      Accionables: (
        <>
          {width > 440 &&
            !ESTADOS_OCULTAR_COMPROBANTES_STR.includes(
              pedido?.estado?.descripcion?.toLowerCase(),
            ) && (
              <ComprobanteIcon
                onClick={() =>
                  onClickComprobantesDetalle(
                    pedido.ordenDeCompra,
                    pedido.id,
                    pedido.tipo_proveedor,
                  )
                }
              />
            )}
        </>
      ),
    }))
}

const PedidosProveedor = ({ activeOrder, handleSetActiveOrder }) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { cronograma } = useCronograma()
  const { pedidosProveedor, pedidosLoading } = usePedidos()

  const { width } = useWindowWidth()

  const headers = [
    {
      name: 'OC',
      tag: 'ordenDeCompra',
    },
    {
      name: 'Estado',
      tag: 'estado',
    },
    {
      name: 'Asociada',
      tag: 'asociada',
    },
    ...(width > 440
      ? [
          {
            name: 'Confeccion',
            tag: 'fecha_confeccion',
          },
          {
            name: 'Recepción',
            tag: 'recepcion',
          },
          {
            name: 'Entrega',
            tag: 'entrega',
          },
        ]
      : []),
    {
      name: '',
      tag: '',
    },
  ]

  const onClickPedidosOC = (ordenCompra, id) => {
    navigate(`/mis-pedidos/OC-${ordenCompra}`, {
      state: id,
    })
  }

  const onClickComprobantesDetalle = (oc, idOcCompra, tipoProveedor) => {
    navigate(`/mis-pedidos/comprobantes/${oc}`, {
      state: { idOcCompra, tipoProveedor },
    })
  }

  return (
    <TableWrapper>
      {pedidosLoading ? (
        <Loading />
      ) : pedidosProveedor?.pedidos?.length > 0 ? (
        <>
          <HeaderTable
            headers={headers}
            gridTemplate={
              width < 440
                ? '25% 25% 25% 25%' // Ajuste para rol 3 y resolución < 440px
                : '8% 10% 35% 10% 10% 10% 10%' // Ajuste para otros roles y resolución >= 440px
            }
            activeOrder={activeOrder}
            handleSetActiveOrder={handleSetActiveOrder}
            camposOrden={pedidosProveedor.pager.campos_orden}
          />
          <Table
            data={formatData({
              idCronograma: cronograma.id_cronograma,
              pedidos: pedidosProveedor.pedidos,
              onClickPedidosOC,
              onClickComprobantesDetalle,
              user,
              width,
            })}
            gridTemplate={
              width < 440
                ? '25% 25% 25% 25%' // Ajuste para rol 3 y resolución < 440px
                : '8% 10% 35% 10% 10% 10% 10%' // Ajuste para otros roles y resolución >= 440px
            }
          />
        </>
      ) : (
        <TableWrapper>
          <EmptyPage>
            <EmptyTableData>No hay Pedidos aún</EmptyTableData>
            <ButtonIrAComprar
              onClick={() => navigate('/comprar')}
              type="secondary"
              text="Ir a Comprar"
              size="small"
            />
          </EmptyPage>
        </TableWrapper>
      )}
    </TableWrapper>
  )
}

export default PedidosProveedor
