import React, { createContext, useContext, useState } from 'react'
import ArticulosService from 'services/articulos'
import { useCronograma } from './CronogramaContext'
import { useCarrito } from './CarritoContext'
import { useAuth } from './AuthContext'
import { getLocalStorageItem } from 'utils/loadData'

const ArticulosContext = createContext()

export const useArticulos = () => useContext(ArticulosContext)

export const ArticulosProvider = ({ children }) => {
  const { token } = useAuth()
  const { cronograma } = useCronograma()
  const { carritoAgrupado } = useCarrito()
  const [articulosLoading, setArticulosLoading] = useState(false)
  const [promocionesLoading, setPromocionesLoading] = useState(false)
  const [promocionLoading, setPromocionLoading] = useState(false)
  const [articulos, setArticulos] = useState({})
  const [articulo, setArticulo] = useState({})
  const [promocion, setPromocion] = useState({})
  const [promociones, setPromociones] = useState(() =>
    getLocalStorageItem('promociones', {}),
  )

  const getArticulos = async (idProveedor) => {
    setArticulosLoading(true)
    return await ArticulosService.getArticulos({
      idCronograma: cronograma.id_cronograma,
      idProveedor: idProveedor,
      idCarrito: carritoAgrupado?.cabecera?.id,
      token,
    })
      .then((resp) => {
        setArticulos(resp)
        return resp
      })
      .catch((error) => {
        console.error('Error al obtener los articulos:', error)
      })
      .finally(() => setArticulosLoading(false))
  }

  const getArticulo = async (idArticulo) => {
    setArticulosLoading(true)
    await ArticulosService.getArticulo({
      idArticulo,
      idCronograma: cronograma.id_cronograma,
      idCarrito: carritoAgrupado.cabecera.id,
      token,
    })
      .then((resp) => {
        setArticulo(resp)
      })
      .catch((error) => {
        console.error('Error al obtener el articulo:', error)
      })
      .finally(() => setArticulosLoading(false))
  }

  const getPromocion = async (idProveedor) => {
    setPromocionLoading(true)
    await ArticulosService.getPromocion({
      idCronograma: cronograma.id_cronograma,
      idProveedor: idProveedor,
      idCarrito: carritoAgrupado.cabecera.id,
      token,
    })
      .then((resp) => {
        setPromocion(resp)
      })
      .catch((error) => {
        console.error('Error al obtener promociones:', error)
      })
      .finally(() => setPromocionLoading(false))
  }

  const getPromociones = async ({ idCronograma } = {}) => {
    setPromocionesLoading(true)
    await ArticulosService.getPromociones({
      idCronograma: idCronograma ? idCronograma : cronograma.id_cronograma,
      idCarrito: carritoAgrupado?.cabecera?.id,
      token,
    })
      .then((resp) => {
        if (resp !== null && resp) {
          localStorage.setItem('promociones', JSON.stringify(resp.promociones))
          setPromociones(resp.promociones)
        } else {
          localStorage.setItem('promociones', JSON.stringify([]))
          setPromociones([])
        }
      })
      .catch((error) => {
        console.error('Error al obtener promociones:', error)
      })
      .finally(() => setPromocionesLoading(false))
  }

  const contextValues = {
    articulos,
    setArticulos,
    getArticulos,
    articulo,
    setArticulo,
    getArticulo,
    articulosLoading,
    setArticulosLoading,
    promocion,
    getPromocion,
    setPromocion,
    promocionLoading,
    setPromocionLoading,
    promociones,
    setPromociones,
    getPromociones,
    promocionesLoading,
    setPromocionesLoading,
  }

  return (
    <ArticulosContext.Provider value={contextValues}>
      {children}
    </ArticulosContext.Provider>
  )
}
