export const terminosYCondiciones = {
  title: 'Términos y condiciones',
  sections: [
    {
      title: 'PRIMERO. TÉRMINOS Y ACLARACIONES',
      description: `A los efectos de una mejor y más clara interpretación de las normas que integran el presente y evitar reiteraciones innecesarias, se formulan las siguientes aclaraciones y precisiones introductorias de los términos y condiciones de uso aplicables al acceso y uso del portal web www.faccargenitna.coop/ccfaccweb:`,
      subsections: [
        {
          title: '1) Términos',
          content: [
            'CCFACC: Central de Compras de la Federación Argentina de Cooperativas de Consumo Ltda.',
            'FACC: Federación Argentina de Cooperativas de Consumo Ltda.',
            'USUARIO: cooperativas y mutuales asociadas a la FACC adherentes a la CCFACC que gestionan compras individualmente.',
            'AGRUPACION: grupo de cooperativas y mutuales asociadas a la FACC adherentes a la CCFACC que gestionan compras conjuntamente.',
            'PROVEEDOR: empresas ofertantes de productos en la CCFACC.',
          ],
        },
        {
          title: '2) Aclaraciones',
          content: [
            'Este contrato describe los términos y condiciones de uso del sitio web de la CCFACC.',
            'Cualquier persona que desee acceder y/o utilizar el sitio deberá sujetarse a los presentes Términos y Condiciones de Uso. Todos los ingresos al sitio y cada una de las operaciones celebradas a través de él.',
            'El futuro usuario deberá leer, comprender y aceptar estos Términos y Condiciones de Uso y políticas del sistema.',
            'Una vez que se haya registrado como usuario, la utilización del sistema implica que se han leído y aceptado los presentes Términos y Condiciones de Uso, obligándose a respetarlos y cumplirlos.',
            'La No Aceptación de los Términos y Condiciones de Uso implicará la imposibilidad de operar como usuario del sistema.',
          ],
        },
      ],
    },
    {
      title: 'SEGUNDO. OBJETO Y ALCANCE',
      description: `La CCFACC tiene por objeto facilitar el acceso de los usuarios y agrupaciones a la oferta de mercaderías de consumo y uso personal, y de insumos que se requieren en el habitual funcionamiento de un supermercado a través de un sitio web en el que contraten directamente con el proveedor, que publicará las condiciones de sus ofertas.`,
      content: [
        'FACC y CCFACC se constituyen en sujetos ajenos a la relación y/ transacciones de compraventa de mercaderías entre usuarios, agrupaciones y proveedores, que eventualmente se lleven adelante; tanto así en la faz preparatoria o en actos precontractuales, perfeccionada, en etapa de cumplimiento o de frustración.',
        'FACC y CCFACC no tiene participación alguna en el proceso de negociación y perfeccionamiento del contrato definitivo entre las partes, el que no se desarrolla en esta plataforma virtual. Por ello, son las partes que eventualmente intervengan en la transacción comercial las obligadas a cumplir con las obligaciones fiscales e impositivas impuestas por la ley.',
      ],
    },
    {
      title: 'TERCERO. LICENCIA. DERECHOS INTELECTUALES',
      description: `Los servicios que provee CCFACC así como, la marca y todos los derechos de propiedad intelectual inherentes al Software son propiedad exclusiva de FACC. Asimismo, FACC se reserva todos los derechos sobre el Software que no se le otorgan expresamente al usuario por el presente.`,
      content: [
        'FACC otorga a los usuarios, agrupaciones y proveedores una licencia limitada, revocable, no exclusiva e intransferible de acceso y uso remoto del Software, únicamente a los fines de que las empresas ofrezcan a los usuarios y agrupaciones mercaderías de consumo y uso personal, y de insumos que se requieren en el habitual funcionamiento de un supermercado a través de la CCFACC.',
        'El usuario y consumidor reconoce que el Software, así como su estructura, organización y código fuente inherentes a CCFACC son secretos profesionales valiosos que pertenecen a FACC. Por lo tanto, excepto por los permisos expresamente previstos en el presente, usuarios, agrupaciones y proveedores no podrán, directa o indirectamente, por sí o a través de terceros, (a) modificar, adaptar, alterar o traducir el Software o crear obras derivadas a partir del Software; (b) distribuir, conceder la licencia a terceros, ofrecer en locación o préstamo, o transferir de cualquier otro modo el Software a terceros; (c) aplicar herramientas de ingeniería inversa para decodificar, decompilar, desensamblar o intentar de cualquier otro modo descifrar el código fuente del Software; o (d) transferir a terceros el derecho de los servicios que brinda CCFACC.',
      ],
    },
    {
      title:
        'CUARTO. FACULTADES DE LA CCFACC. LIMITACIONES DE RESPONSABILIDAD ',
      description: `FACC y CCFACC no serán responsables por las transacciones efectuadas entre usuarios, agrupaciones y proveedores derivadas del conocimiento de los mismos a través de CCFACC, en tanto no participa de ellas en forma alguna.`,
      content: [
        'Usuarios, agrupaciones y proveedores no podrán volcar términos o utilizar expresiones injuriosas, intimidatorias, calumniantes o contrarias a las buenas costumbres, no podrán transmitir información o material que pueda, concreta o eventualmente, violar derechos de un tercero o que contenga virus o cualquier otro componente dañino. En todos los casos la FACC se reserva el derecho de extraer y editar total o parcialmente cualquiera de los contenidos volcados en CCFACC por el proveedor que considere contrario a la obligación aquí establecida.',
        'El proveedor reconoce que, al permitirle publicitar sus ofertas, CCFACC actúa únicamente como canales pasivos para dicha publicidad, y asimismo la FACC no revisa ni corrobora el contenido de  la publicación del proveedor o de los productos o servicios que allí ofrece.',
        'FACC prestará los Servicios propios de CCFACC en el estado en el que se encuentran, sin prestar ninguna garantía expresa, implícita o legal, incluidas las de disponibilidad, adecuación para usos o sistemas determinados, ausencia de violaciones o errores. FACC no garantiza el uso continuado e ininterrumpido de CCFACC, por tal motivo los usuarios no podrán imputarle a FACC responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en Internet o cualquier otro tipo de error.',
        'La CCFACC se reserva el derecho de rechazar cualquier solicitud de registración o de suspender temporalmente y/o inhabilitar definitivamente una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión al usuario, y sin que ello genere ningún derecho a indemnización o resarcimiento. ',
        'La CCFACC podrá advertir, suspender temporal o inhabilitar definitivamente la cuenta de un usuario, e iniciar las acciones que estime pertinentes cuando: a) se infringiera alguna de las estipulaciones de los Términos y Condiciones de Uso y demás políticas previstas en el sistema. b) si incumpliera sus compromisos como usuario; c) si incurriera, a criterio del operador, en conductas o actos dolosos o fraudulentos. ',
        'Usuarios, agrupaciones y proveedores reconocen y aceptan que las limitaciones de responsabilidad convenidas en los presentes Términos y Condiciones de CCFACC constituyen un elemento esencial del acuerdo y por lo tanto resultan razonables, toda vez que constituyen un elemento esencial para mantener las condiciones económicas del contrato. ',
      ],
    },
    {
      title:
        'QUINTO. CONFIDENCIALIDAD. VIOLACIONES AL SISTEMA O BASES DE DATOS ',
      content: [
        'Usuarios, agrupaciones y proveedores se comprometen a guardar estricta confidencialidad de toda su operatoria y ejecución. Acordando que la información se encuadra en cuanto corresponda a los términos de la Ley 24.766. No está permitida ninguna acción o uso de dispositivos, software, u otro medio tendiente a interferir en las actividades, operatoria, ofertas, descripciones, cuentas o bases de datos del Operador. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual y/o a las prohibiciones estipuladas en estos Términos y Condiciones de Uso, harán pasible a su responsable de las acciones legales pertinentes. ',
      ],
    },
    {
      title: 'SEXTO. OPERATORIA  ',
      description: `Usuarios, agrupaciones y proveedores se comprometen a guardar estricta confidencialidad de toda su operatoria y ejecución. Acordando que la información se encuadra en cuanto corresponda a los términos de la Ley 24.766.`,
      subsections: [
        {
          title: '1) Registración y responsabilidad de usuario ',
          content: [
            'El usuario dispondrá, una vez asociado, de un login y de una contraseña, que le permitirá el acceso personalizado, confidencial y seguro al sitio. Tendrá la posibilidad de cambiarla, para lo cual deberá sujetarse al procedimiento establecido. La contraseña es secreta, el usuario se obliga a mantener su reserva y confidencialidad, asumiendo toda la responsabilidad. Es de uso personal, y su entrega o divulgación a terceros no implicará responsabilidad alguna del operador, a quien le será por lo tanto inoponible cualquier invocación de utilización por parte de aquellos.',
            'El usuario será responsable por todas las operaciones efectuadas en el sitio con su contraseña, se trate que opere por sí y/o por terceros. El usuario se compromete a notificar al operador, en forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado de su contraseña. Este compromiso no obstante no implicará obligación ni reconocimiento automático alguno de parte del operador. Estará prohibida la venta, cesión o transferencia de la contraseña.',
          ],
        },
        {
          title: '2) Fallas en el sistema',
          content: [
            'El operador no garantiza la inexistencia o ausencia de eventuales dificultades técnicas o fallas en los sistemas o en Internet. Tampoco garantiza el acceso y uso continuado o ininterrumpido de su sitio. El sistema puede eventualmente no estar disponible debido a dificultades o fallas técnicas o de Internet, o por cualquier otra circunstancia ajena al operador; en tales casos se procurará restablecerlo con la mayor celeridad posible. El operador no garantiza la inexistencia de errores u omisiones en la presente página de Internet. En el supuesto de existir inconsistencias o detectarse omisiones que por su naturaleza sean manifiestas y ostensibles, serán consideradas como errores involuntarios sobre los cuales no responderá el operador, en la medida de su razonabilidad.',
          ],
        },
        {
          title: '3) Notificaciones y comunicaciones',
          content: [
            'El/la USUARIO/A acepta recibir notificaciones, avisos y/o recordatorios  relacionados con los diferentes servicios que brinda la APLICACIÓN a través de notificaciones automáticas (push) y/o cualquier otro medio de comunicación que EL ADMINISTRADOR considere idóneo a tales efectos.',
          ],
        },
        {
          title: '4) Responsabilidad de las asociadas',
          content: [
            'La generacion de una orden de compra por parte de el/la USUARIO/A implica la aceptación de los precios, minimos de compra y  plazos/modalidad de pago exigida por el proveedor. El plazo y modalidad de pago es distinto para todos los proveedores y deben ser visualizados en la web en forma previa a la  generación de la OC.',
            'De recibir la mercaderia sin la factura o no contar con los datos bancarios del proveedor para cancelar la obligación deberan ser solcitados a la CCFACC.  ',
            'Los comprobantes de pago y retenciones deberán ser enviados a las personas designada por cada proveedor para dicho fin. ',
          ],
        },
        {
          title: '5) Coordinadores de Agrupaciones',
          content: [
            'Los integrantes de las agrupaciones han encomendado y designado a un coordinador para que sea responsable de consolidar por cuenta y orden de cada una y/o por su propio derecho, las órdenes de compra de las mercaderías solicitadas en cada caso.',
            'Los coordinadores contarán con la posibilidad de modificar con su propio usuario los carros solicitados y confirmados de cualquiera de las entidades integrantes. Dicha modifiacion se realizará en el caso de que no se alcance con algun requerimiento exigido. ',
          ],
        },
        {
          title: '6) Precios y facturación',
          content: [
            'Los coordinadores contarán con la posibilidad de modificar con su propio usuario los carros solicitados y confirmados de cualquiera de las entidades integrantes. Dicha modifiacion se realizará en el caso de que no se alcance con algun requerimiento exigido.',
          ],
        },
        {
          title: '7) Lugares de Entrega',
          content: [
            'Los lugares definidos para las entregas son: Bs.As – Bahia Blanca – Fábrica (proveedor)',
            'Dependiendo la ubicación del proveedor es posible que se entregue en otros destinos (consultar)',
          ],
        },
        {
          title: '8) Control de la mercaderia',
          content: [
            'Al momento de recibir la mercadería adquirida a través del sitio, el usuario deberá verificar calidad y cantidad antes de suscribir el remito. Es necesario asignar a los transportes dicha función para poder documentar posibles inconvenientes al momento de la entrega.',
          ],
        },
        {
          title: '9) Publicidad',
          content: [
            'CCFACC cuenta con un servicio de publicidad por medio del cual ésta se hace llegar a los usuarios y agrupaciones a través de banderas (banners), correos electrónicos y/u otros medios.',
          ],
        },
      ],
    },
    {
      title: 'SEPTIMO. INCUMPLIMIENTOS',
      content: [
        'En el caso de alguna violación a los Términos y Condiciones, FACC podrá sin necesidad de previa notificación suspender o finalizar algunos o todos los Servicios, bloquear cualquier actividad abusiva y/o tomar cualquier acción que sea considerada apropiada a su exclusivo criterio.',
        'FACC se reserva el derecho de denunciar tales violaciones a las autoridades pertinentes y revelar cualquier información con la finalidad de cumplir con las leyes, reglamentos y/o a requerimiento de autoridad competente a los efectos de cooperar con las mismas en su resolución.',
      ],
    },
    {
      title:
        'OCTAVO. MODIFICACIÓN Y ACTUALIZACIONES DE LOS TÉRMINOS Y CONDICIONES ',
      content: [
        'FACC se reserva el derecho de denunciar tales violaciones a las autoridades pertinentes y revelar cualquier información con la finalidad de cumplir con las leyes, reglamentos y/o a requerimiento de autoridad competente a los efectos de cooperar con las mismas en su resolución.',
      ],
    },
    {
      title: 'NOVENO. LEY APLICABLE Y JURISDICCIÓN ',
      content: [
        'La relación entre usuarios, agrupaciones, proveedores, por una parte, y CCFACC, por otra, así como la interpretación y aplicación de estos Términos y Condiciones se regirá de conformidad con la legislación de la República Argentina. Serán competentes los tribunales ordinarios del Departamento Judicial de Bahía Blanca.',
      ],
    },
  ],
}
