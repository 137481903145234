import React from 'react'
import styled from 'styled-components'

import { Row } from 'components/layout/FlexComponents'
import Table from 'components/Table/Table'
import { P4 } from 'components/Typography'
import ProgressBarComponent from 'components/ProgressBar/ProgressBar'
import StatusTag from 'components/Tag/StatusTag'
import { textoCapitalizado } from 'utils/textProcess'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ProveedoresItem = styled(Row)`
  align-items: center;
  gap: 6px;
`

const ProgressBar = styled(ProgressBarComponent)`
  height: 10px;
  width: 40%;
`

const formatData = (afiliados) =>
  (!Array.isArray(afiliados)
    ? Object.keys(afiliados).map((key) => afiliados[key])
    : afiliados
  ).map((afiliado) => ({
    Estado: (
      <StatusTag
        estado={textoCapitalizado(afiliado.estadoCarritos.toLowerCase())}
      />
    ),
    Operador: (
      <ProveedoresItem key={afiliado.nombre_comercial}>
        <P4>{afiliado.nombre_comercial}</P4>
      </ProveedoresItem>
    ),
    Requerimientos:
      afiliado.participacion === 0 ? (
        <P4>No participa</P4>
      ) : (
        <>
          <ProgressBar
            progress={
              (afiliado.participacion > 0 && afiliado.participacion < 1) ||
              (afiliado.participacion > 99 && afiliado.participacion < 100)
                ? afiliado.participacion.toFixed(2)
                : Math.round(afiliado.participacion)
            }
            type="participacion"
          />
        </>
      ),
  }))

const headers = [
  {
    name: 'Estado',
    isOrderable: false,
  },
  {
    name: 'Subasociada',
    isOrderable: false,
  },
  {
    name: 'Participación',
    isOrderable: false,
  },
  {
    name: '',
    isOrderable: false,
  },
]

const ParticipacionTable = ({ afiliados }) => {
  const tableFormat = formatData(afiliados)

  return (
    <>
      {tableFormat?.length > 0 ? (
        <Table
          data={tableFormat}
          headers={headers}
          gridTemplate="20% 45% 20%"
        />
      ) : (
        <EmptyTableData>No hay datos</EmptyTableData>
      )}
    </>
  )
}

export default ParticipacionTable
