import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'components/Button/Button'
import { useAuth } from 'contexts/AuthContext'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from 'components/Input/Input'
import { BtnS, H1, H2, P3, P4 } from 'components/Typography'
import backgroundImg from 'assets/banner_login.jpeg'
import RotatingLoader from 'components/Loading/RotatingLoader'
import { AlertTriangle, Lock } from 'feather-icons-react/build/IconComponents'

const Wrappper = styled.div`
  display: flex;
  height: fit-content;
  justify-content: center;
  width: 100%;
  gap: 95px;
  margin: 5%;
`

const LoginPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(${backgroundImg});
  background-size: contain;
  background-repeat: no-repeat;
`

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primary.darkBlue};
  padding: 108px 48px 33px;
  gap: 18px;
  box-shadow: 0px 16px 24px 4px rgba(90, 82, 128, 0.12);
`

const LoginButton = styled(Button)`
  margin: 30px 0;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.06);
`

const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
`

const RigthSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: end;
`

const WelcomeMessage = styled(H1)`
  max-width: 570px;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const InputLogin = styled(Input)`
  width: 298px;
  height: 32px;
  padding: 8px 16px;
`

const TextPaso1 = styled(P3)`
  text-align: center;
  width: 80%;
  color: ${({ theme }) => theme.colors.neutral.white};
`

const TextPaso2 = styled(H2)`
  text-align: center;
  width: 80%;
  color: ${({ theme }) => theme.colors.neutral.white};
`

const StyledBtnS = styled(BtnS)`
  font-weight: 400;
`

const CustomRotatingLoader = styled(RotatingLoader)`
  width: 63px;
  height: 24px;
`

const ErrorMessage = styled.div`
  width: 393px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8d7da;
  color: #721c24;
  padding: 8px 0;
  border-radius: 4px;
  gap: 5px;
  font-weight: 400;
`

const RetryMessage = styled(P4)`
  font-weight: 400;
`

const ResetPassword = () => {
  const navigate = useNavigate()
  const { loginLoading, resetPassword } = useAuth()
  const [loginError, setLoginError] = useState(false)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [currentForm, setCurrentForm] = useState(0)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  const handleUpdatePassword = async () => {
    try {
      await resetPassword(password, repeatPassword, token)
      setCurrentForm(1)
    } catch (e) {
      console.log(e)
      setLoginError(true)
    }
  }

  return (
    <LoginPage>
      <Wrappper>
        <RigthSection>
          <WelcomeMessage>
            Te damos la bienvenida a la Central de Compras FACC
          </WelcomeMessage>
        </RigthSection>

        {currentForm === 0 && (
          <LoginForm>
            <TextPaso1>Generar una nueva contraseña</TextPaso1>
            <InputLogin
              type="password"
              placeholder="Nueva contraseña"
              onChange={(event) => {
                setPassword(event.target.value)
              }}
              value={password}
              icon={<Lock size={20} />}
            />
            <InputLogin
              type="password"
              placeholder="Repetir nueva contraseña"
              onChange={(event) => {
                setRepeatPassword(event.target.value)
              }}
              value={repeatPassword}
              icon={<Lock size={20} />}
            />
            <LoginButton
              onClick={handleUpdatePassword}
              text={
                loginLoading ? <CustomRotatingLoader /> : 'Generar contraseña'
              }
              type="primary"
              disabled={password === '' || repeatPassword === ''}
            />
            {loginError && (
              <ErrorMessage>
                <AlertTriangle size={12} />
                <StyledBtnS>Error al cambiar la contraseña </StyledBtnS>
                <RetryMessage>Intentá nuevamente.</RetryMessage>
              </ErrorMessage>
            )}
          </LoginForm>
        )}

        {currentForm === 1 && (
          <LoginForm>
            <TextPaso2>Tu contraseña se cambió correctamente</TextPaso2>
            <TextPaso1>Iniciá sesión para ingresar al sitio</TextPaso1>
            <WrapperButtons>
              <LoginButton
                onClick={() => {
                  navigate('/login')
                }}
                text={
                  loginLoading ? <CustomRotatingLoader /> : 'Iniciar sesión'
                }
                type="primary"
              />
            </WrapperButtons>
          </LoginForm>
        )}
      </Wrappper>
    </LoginPage>
  )
}

export default ResetPassword
