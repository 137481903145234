import React from 'react'
import Modal from './Modal'
import { useModal } from 'contexts/ModalContext'
import { useCarrito } from 'contexts/CarritoContext'
import { showToast } from 'components/Notification/ToastNotification'

const DeleteProveedorModal = ({ idProveedor, afterClose }) => {
  const { closeModal } = useModal()
  const { remover, loading } = useCarrito()

  const content = {
    type: 'error',
    icon: 'trash',
    title: '¿Estás seguro de eliminar?',
    text: [
      'Si seleccionas ',
      'CONFIRMAR ',
      'eliminarás del carrito ',
      'todos los productos de este proveedor',
    ],
    primaryButton: {
      function: () => {
        remover(idProveedor)
        showToast(`Carrito eliminado!`, 'validacion')
        closeModal()
        afterClose()
      },
      text: 'Confirmar',
    },
    secondaryButton: {
      function: () => {
        closeModal()
        afterClose()
      },
      text: 'Cancelar',
    },
    loading,
    afterClose,
  }
  return <Modal content={content} idProveedor={idProveedor}></Modal>
}

export default DeleteProveedorModal
