import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BtnS, H2, P2 } from 'components/Typography'
import { Button } from 'components/index'
import { Download } from 'feather-icons-react/build/IconComponents'
import TotalesSection from '../HeaderComprobantes/TotalesSection'
import { usePedidos } from 'contexts/PedidosContext'
import { useAuth } from 'contexts/AuthContext'
import StepsComponent from '../HeaderComprobantes/StepsComponent'
import { ESTADOS_HABILITADOS, obtenerEstadoPedido } from 'utils/EstadosPedidos'
import StatusTag from 'components/Tag/StatusTag'

const Header = styled.div`
  display: flex;
  align-items: start;
  gap: 18px;
  margin-bottom: 20px;

  @media (max-width: 440px) {
    flex-direction: column;
    margin: 10px 0px 20px 0px;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  max-width: 40%;

  @media (max-width: 440px) {
    max-width: 100%;
  }
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 25px;
  width: 60%;
  margin-left: auto;
  flex-grow: 1;

  @media (max-width: 440px) {
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`

const ButtonsRow = styled(Row)`
  gap: 12px;
  justify-content: end;

  @media (max-width: 440px) {
    justify-content: start;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const TopSection = styled(Row)`
  justify-content: space-around;
  width: 100%;

  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 24px;
  }
`

const BottomSection = styled(Row)`
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 48px;
    align-items: end;
  }
`

const StateContainer = styled(BtnS)`
  background: ${({ theme, $disabled }) =>
    !$disabled ? theme.colors.neutral.white : theme.colors.neutral.disableGrey};
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  border-radius: 8px;
  padding: 20px;
  width: 85%;
`

const HeaderPedido = ({ oc }) => {
  const { obtenerPdfPedidoSubasociada, obtenerPdfPedidoAgrupacion } =
    usePedidos()
  const { user } = useAuth()
  const onClickPedidoDescarga = async (pedidoId, documentName) => {
    const pdfData =
      user.rol.id_rol === '3'
        ? await obtenerPdfPedidoAgrupacion(pedidoId)
        : await obtenerPdfPedidoSubasociada(pedidoId)
    const url = window.URL.createObjectURL(
      new Blob([pdfData], { type: 'application/pdf' }),
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `${documentName}-ID${pedidoId}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
  const steps = ['Aprobación', 'Preparación', 'Retiro', 'En destino']
  const EstadoPedido = obtenerEstadoPedido(oc.estado.id, oc.porcentajeRecepcion)
  const [isStepsComponentEnabled, setIsStepsComponentEnabled] = useState(false)
  useEffect(() => {
    const checkIfStepsComponentIsEnabled = () => {
      return ESTADOS_HABILITADOS.includes(parseInt(oc.estado.id))
    }
    setIsStepsComponentEnabled(checkIfStepsComponentIsEnabled())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oc.estado])

  return (
    <Header>
      <Title>
        <H2>{oc?.proveedor}</H2>
        <P2>OC# {oc?.ordenDeCompra}</P2>
        {user.rol.id_rol === '3' && (
          <StatusTag estado={oc?.estado.descripcion} />
        )}
      </Title>
      <InfoSection>
        <TopSection>
          <Col>
            <TotalesSection headerData={{ ...oc?.totales, fecha: oc.fecha }} />
          </Col>
          <ButtonsRow>
            <Button
              text="Descargar"
              icon={<Download />}
              size="small"
              onClick={() => {
                onClickPedidoDescarga(oc?.id, oc?.ordenDeCompra)
              }}
            />
          </ButtonsRow>
        </TopSection>
        {user.rol.id_rol === '3' && (
          <BottomSection>
            <StepsComponent
              steps={steps}
              currentStep={EstadoPedido.step}
              disabled={!isStepsComponentEnabled}
            />
            <StateContainer $disabled={!isStepsComponentEnabled}>
              {!isStepsComponentEnabled ? '' : EstadoPedido?.descripcion}
            </StateContainer>
          </BottomSection>
        )}
      </InfoSection>
    </Header>
  )
}

export default HeaderPedido
