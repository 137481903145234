import React, { createContext, useContext, useState } from 'react'
import ProveedoresService from 'services/proveedores'
import { useCronograma } from './CronogramaContext'
import { useAuth } from './AuthContext'
import { getLocalStorageItem } from 'utils/loadData'
import axios from 'axios'

const ProveedoresContext = createContext()

export const useProveedores = () => useContext(ProveedoresContext)

export const ProveedoresProvider = ({ children }) => {
  const { token } = useAuth()
  const { cronograma } = useCronograma()
  const [proveedoresLoading, setProveedoresLoading] = useState(false)
  const [proveedores, setProveedores] = useState(
    getLocalStorageItem('proveedores', []),
  )
  const [proveedoresActivos, setProveedoresActivos] = useState(
    getLocalStorageItem('proveedoresActivos', []),
  )
  const [proveedoresDehabilitados, setProveedoresDehabilitados] = useState(() =>
    getLocalStorageItem('proveedoresDehabilitados', []),
  )
  const [proveedoresPager, setProveedoresPager] = useState({})

  const getProveedores = async ({
    paginaActual = 1,
    porPagina = 10,
    familias,
    habilitados,
    tipo_proveedor,
    buscar,
    cancelToken,
  }) => {
    // function filterProveedoresConZona(proveedoresArray) {
    //   return proveedoresArray.filter((item) => {
    //     return Array.isArray(item.zonas) && item.zonas.length > 0
    //   })
    // }
    setProveedoresLoading(true)
    return await ProveedoresService.getProveedores({
      token,
      idCronograma: cronograma.id_cronograma,
      porPagina,
      paginaActual,
      familias,
      tipo_proveedor,
      buscar,
      habilitados,
      cancelToken,
    })
      .then((resp) => {
        if (resp) {
          localStorage.setItem('proveedores', JSON.stringify(resp.proveedores))
          setProveedoresPager(resp.pager)
          setProveedores(resp.proveedores)
          return resp.proveedores
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.info('Previous request canceled:', error.message)
        } else {
          localStorage.setItem('proveedores', {})
          console.error(
            'Error al obtener los datos de proveedores:',
            error.message ? error.message : error,
          )
        }
      })
      .finally(() => {
        setProveedoresLoading(false)
      })
  }

  const contextValues = {
    proveedores:
      proveedores !== undefined || proveedores.length > 0
        ? proveedores.map(
            ({
              id,
              habilitado,
              nombre_comercial,
              path,
              puedePedir,
              localidad,
              familias,
              zonaDefault,
              zonas,
            }) => {
              return {
                id,
                nombre_comercial,
                path,
                habilitado,
                puedePedir: puedePedir.puedePedir,
                motivoPuedePedir: puedePedir.motivo,
                localidad,
                familias,
                zonaDefault,
                zonas,
              }
            },
          )
        : [],
    proveedoresPager,
    setProveedores,
    getProveedores,
    proveedoresLoading,
    setProveedoresLoading,
    proveedoresActivos,
    setProveedoresActivos,
    proveedoresDehabilitados,
    setProveedoresDehabilitados,
  }

  return (
    <ProveedoresContext.Provider value={contextValues}>
      {children}
    </ProveedoresContext.Provider>
  )
}
