import React, { useEffect } from 'react'
import styled from 'styled-components'

import { FilterComponent } from 'components/FilterComponent/FilterComponent'
import SearchInput from 'components/SearchBar/SearchInput'
import { useSearchAndFilterTable } from 'contexts/SearchAndFilterTableContext'

const FilterWrapper = styled.div`
  width: 20%;

  @media (max-width: 440px) {
    width: 100%;
  }
`

const SearchTableInput = styled(SearchInput)`
  color: ${({ theme }) => theme.colors.primary.lighterBlue};
  border: 3px solid ${({ theme }) => theme.colors.primary.darkBlue};
  width: auto;
  margin: 20px 0;
`

const FilterSection = ({ categorias }) => {
  const { searchValue, setSearchInputValue, setFilters, filters } =
    useSearchAndFilterTable()

  useEffect(() => {
    setFilters({
      activos: true,
      todos: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FilterWrapper>
      <SearchTableInput
        value={searchValue}
        onChange={(e) => {
          setSearchInputValue(e.target.value)
        }}
        placeholder="Buscar producto"
      />
      <FilterComponent
        filtersOptions={categorias}
        setFilters={setFilters}
        filters={filters}
      />
    </FilterWrapper>
  )
}

export default FilterSection
