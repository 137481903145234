import { Loader } from 'feather-icons-react/build/IconComponents'
import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const getIconSize = (size) => {
  switch (size) {
    case 'large':
      return '32px'
    case 'medium':
      return '24px'
    case 'small':
    default:
      return '16px'
  }
}
const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 10% 0;
`

const Loading = styled(Loader)`
  margin: -4px;
  height: ${($size) => getIconSize($size)};
  width: ${($size) => getIconSize($size)};
  animation: ${spinAnimation} 2s linear infinite;
`

const RotatingLoading = ({ size }) => (
  <LoadingWrapper>
    <Loading $size={size} />
  </LoadingWrapper>
)

export default RotatingLoading
