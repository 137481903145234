import React, { useState } from 'react'
import styled from 'styled-components'

import { H1, H4 } from 'components/Typography'
import { Button } from 'components/index'

const CardWrapper = styled.div`
  padding: 18px 30px;
  border-radius: 20px;
  background-color: ${({ theme, $isHovered }) =>
    $isHovered ? theme.colors.primary.blue : theme.colors.primary.darkBlue};
  color: ${({ theme }) => theme.colors.neutral.white};
  scale: ${({ $isHovered }) => ($isHovered ? 1.07 : 1)};
  transition: all 0.3s ease-in-out;
`

const DateText = styled(H4)`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 1366px) {
    font-size: 16px;
  }
`

const SubTitle = styled(H4)`
  min-height: 50px;

  @media (max-width: 1366px) {
    font-size: 16px;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: ${({ $numberOfButtons }) =>
    $numberOfButtons > 1 ? 'space-between' : 'flex-end'};
  margin-top: ${({ $isHovered }) => ($isHovered ? '80px' : '60px')};
  gap: 8px;

  @media (max-width: 1366px) {
    margin-top: ${({ $isHovered }) => ($isHovered ? '40px' : '30px')};
  }
`

const Card = ({
  icon = null,
  title = '',
  subTitle = '',
  buttonSection,
  dateText,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const numberOfButtons = buttonSection.length

  return (
    <CardWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      $isHovered={isHovered}
    >
      <>
        <DateText>
          {icon}
          {dateText}
        </DateText>
        <H1>{title}</H1>
        <SubTitle>{subTitle}</SubTitle>
      </>
      <Footer $isHovered={isHovered} $numberOfButtons={numberOfButtons}>
        {buttonSection.map((ele, index) => (
          <Button
            key={index}
            size="large"
            type={
              numberOfButtons >= 2 && index === 0
                ? 'contrast'
                : isHovered
                  ? 'secondaryNoBorder'
                  : 'primary'
            }
            text={ele.text}
            onClick={ele.action}
          />
        ))}
      </Footer>
    </CardWrapper>
  )
}

export default Card
