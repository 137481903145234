import { ArrowLeft } from 'feather-icons-react/build/IconComponents'
import styled from 'styled-components'
import Link from 'components/Button/Link'
import { useNavigate } from 'react-router-dom'

const StyledButton = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.white};
  text-decoration: none;
  font-size: 14px;
`

const LinkText = styled.div`
  margin-left: 10px;

  @media (max-width: 440px) {
    display: none;
  }
`

const BackButton = () => {
  const navigate = useNavigate()

  function navigateBack() {
    navigate(-1)
  }

  return (
    <StyledButton onClick={navigateBack}>
      <ArrowLeft size={18} />
      <LinkText>Volver</LinkText>
    </StyledButton>
  )
}

export default BackButton
