import useAxiosInstance from './axiosInterceptor'
const axios = useAxiosInstance
import { queryString, reemplazarInterrogacionPorÑ } from 'utils/textProcess'

const CarritoService = {
  getCarritoAgrupado: async ({ idCronograma, token }) => {
    let data = {
      cronograma: parseInt(idCronograma),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/carrito-agrupado?${queryString(
        data,
      )}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then((response) => {
        Object.keys(response.data.data.resumenDetalle).forEach((key) => {
          response.data.data.resumenDetalle[key].nombre_comercial =
            reemplazarInterrogacionPorÑ(
              response.data.data.resumenDetalle[key].nombre_comercial,
            )
        })
        return response.data.data
      })
      .catch((error) => {
        throw error
      })
  },
  agregarItem: ({ idCarrito, idArticulo, cantidad, token }) => {
    let data = JSON.stringify({
      id_carrito: parseInt(idCarrito),
      id_articulo: parseInt(idArticulo),
      cantidad: parseInt(cantidad),
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/carrito-item`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        throw error
      })
  },
  comprarEnOtraOcasion: ({ idCarrito, idProveedor, idCronograma, token }) => {
    let data = JSON.stringify({
      id_carrito: parseInt(idCarrito),
      id_proveedor: parseInt(idProveedor),
      id_cronograma: parseInt(idCronograma),
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/carrito-cero`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  cancelarComprarEnOtraOcasion: ({ idProveedor, idCronograma, token }) => {
    let data = JSON.stringify({
      id_proveedor: parseInt(idProveedor),
      id_cronograma: parseInt(idCronograma),
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/carrito-restablecer`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        console.log(response)
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  noComprarEstaSemana: ({ idCarrito, idCronograma, token }) => {
    let data = JSON.stringify({
      id_carrito: parseInt(idCarrito),
      id_cronograma: parseInt(idCronograma),
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/carritos-en-cero`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        console.log(response)
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  finalizar: ({
    rol,
    idCarrito,
    idProveedor,
    token,
    ocGrupo,
    fechaVto,
    observacion,
    idCronograma,
    idZona,
    idLugarEntrega,
    tieneReqLog,
    superoReqLog,
  }) => {
    let data =
      rol === '3'
        ? JSON.stringify({
            id_carrito: parseInt(idCarrito),
            id_proveedor: parseInt(idProveedor),
            id_cronograma: parseInt(idCronograma),
            oc_grupo: parseInt(ocGrupo),
            fecha_vto: fechaVto,
            observacion: observacion,
            id_zona: parseInt(idZona),
            id_lugar_entrega: parseInt(idLugarEntrega),
            tiene_req_log: tieneReqLog,
            supero_req_log: superoReqLog,
          })
        : JSON.stringify({
            id_carrito: parseInt(idCarrito),
            id_proveedor: parseInt(idProveedor),
          })
    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/carrito-finalizar`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        console.log(response)
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  remover: ({ idCarrito, idProveedor, token, pedido = false }) => {
    let data = JSON.stringify({
      id_carrito: parseInt(idCarrito),
      id_proveedor: parseInt(idProveedor),
      pedido,
    })

    let config = {
      method: 'DELETE',
      url: `${process.env.REACT_APP_API_URL}/carrito-remover`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getCarrito: async ({ idCronograma, idCarrito, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_carrito: parseInt(idCarrito),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/carrito?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then((response) => {
        response.data.data.carritos.map(
          (p) =>
            (p.cabecera.condiciones.nombre_comercial =
              reemplazarInterrogacionPorÑ(
                p.cabecera.condiciones.nombre_comercial,
              )),
        )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
}

export default CarritoService
