import React from 'react'
import Modal from './Modal'
import { useModal } from 'contexts/ModalContext'
import { useCarrito } from 'contexts/CarritoContext'
import { showToast } from 'components/Notification/ToastNotification'

const ConfirmComprarEnCeroModal = ({ idProveedor, resetFilter }) => {
  const { closeModal } = useModal()
  const { comprarEnOtraOcasion, loading } = useCarrito()

  const content = {
    type: 'info',
    icon: 'bell',
    title: '¿Estás seguro que no querés comprar en esta ocasión?',
    text: [
      'Si seleccionas ',
      'CONFIRMAR ',
      'el pedido para este proveedor ',
      'se consolidará en cero en este cronograma',
    ],
    primaryButton: {
      function: () => {
        comprarEnOtraOcasion(idProveedor)
        showToast(
          'El proveedor fue desactivado para esta ocasión de manera exitosa',
          'validacion',
        )
        closeModal()
        resetFilter()
      },
      text: 'Confirmar',
    },
    secondaryButton: {
      function: () => {
        closeModal()
      },
      text: 'Volver y revisar',
    },
    loading,
  }
  return <Modal content={content} idProveedor={idProveedor}></Modal>
}

export default ConfirmComprarEnCeroModal
