import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'components/index'
import Select from 'components/Select/Select'
import Input from 'components/Input/Input'
import { usePedidos } from 'contexts/PedidosContext'
import { useCronograma } from 'contexts/CronogramaContext'
import RotatingLoader from 'components/Loading/RotatingLoader'
import { showToast } from 'components/Notification/ToastNotification'
import DropdownNotification from 'components/Notification/DropdownNotification'
import { useNavigate } from 'react-router-dom'
import { H3, H4, P2 } from 'components/Typography'
import ModalNotification from 'components/Notification/ModalNotification'
import { useModal } from 'contexts/ModalContext'

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 16px;
  padding: 32px 45px;
`

const FullWidthInputContainer = styled.div`
  grid-column: span 2;
`

const GridInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 20px;
`

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  text-align: start;
`

const StyledInput = styled(Input)`
  border-radius: 8px;
`

const StyledDatepicker = styled.input`
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.primary.darkBlue};
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
`

const CustomSelect = styled(Select)`
  width: 100%;
  text-align: left;
`

const CustomLoading = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
  padding: 20px;
  margin: 30px;
  width: -webkit-fill-available;
`

const TitleTab = styled(H4)`
  width: auto;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const CardHeader = styled.header`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral.gray}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  display: flex;
  justify-content: space-between;
  border: 1px solid #c8c8c8;
  flex-direction: row;
  align-items: center;
  padding: 14px 44px;
`

const UserDataWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  max-width: 34%;
`

const ProveedorSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const HeaderTitle = styled(H3)`
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const StepConsolidatePedido = ({
  proveedor,
  afiliados,
  goToPrevStep,
  zonasProveedor,
  reqCalculados,
}) => {
  const orderInputRef = useRef()
  const navigate = useNavigate()
  const { pedidosAConsolidar, consolidarPedidosMatriz, conoslidacionLoading } =
    usePedidos()
  const { openModal, isModalOpen, closeModal } = useModal()
  const {
    bestZona,
    mejorProveedorZona,
    cronograma,
    getLugaresProveedores,
    zonaLoading,
  } = useCronograma()

  const [errores, setErrores] = useState([])

  const [lugaresProveedor, setLugaresProveedor] = useState([])
  const [inputs, setInputs] = useState({
    oc_grupo: pedidosAConsolidar.ordenDeCompra || '',
    id_zona: proveedor.zonaDefault,
    observacion: '',
    id_lugar:
      Object.keys(lugaresProveedor).length !== 0
        ? Object.keys(lugaresProveedor)[0]
        : 0,
    fecha_vto: getDefaultDate(),
  })

  useEffect(() => {
    const fetchZonasProveedor = async () => {
      const lugares = await getLugaresProveedores(proveedor.id, inputs.id_zona)
      setLugaresProveedor(lugares)

      setInputs((prevInputs) => ({
        ...prevInputs,
        id_lugar:
          Object.keys(lugares).length !== 0 ? Object.keys(lugares)[0] : 0,
      }))
    }
    // TODO: CREAR UN LOADIN GPARA LUGARES
    if (proveedor && !zonaLoading) fetchZonasProveedor()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.id_zona])

  useEffect(() => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      id_lugar:
        Object.keys(lugaresProveedor).length !== 0
          ? Object.keys(lugaresProveedor)[0]
          : 0,
    }))

    if (orderInputRef.current) {
      orderInputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lugaresProveedor])

  function getDefaultDate() {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + 1)
    return currentDate.toISOString().split('T')[0]
  }

  async function checkDataAndConsolidatePedido() {
    const erroresNuevos = []
    if (inputs.oc_grupo === '') {
      erroresNuevos.push('oc_grupo')
    }
    if (inputs.id_zona === '') {
      erroresNuevos.push('id_zona')
    }
    if (inputs.id_entrega === '') {
      erroresNuevos.push('id_entrega')
    }
    if (!inputs.fecha_vto) {
      erroresNuevos.push('fecha_vto')
    }

    if (erroresNuevos.length > 0) {
      setErrores(erroresNuevos)
    } else {
      setErrores([])

      if (afiliados) {
        await consolidarPedidosMatriz(
          cronograma.id_cronograma,
          proveedor.id,
          inputs.oc_grupo,
          inputs.fecha_vto,
          inputs.id_zona,
          inputs.id_lugar,
          inputs.observacion,
          afiliados,
        )
        showToast('Consolidación realizada con éxito!', 'validacion')
        navigate(`/coordinacion`)
      }
    }
  }

  return (
    <InputContainer>
      <UserDataWrapper>
        <ProveedorSection>
          <HeaderTitle>{proveedor.nombre_comercial}</HeaderTitle>
        </ProveedorSection>
      </UserDataWrapper>
      <CardHeader>
        <TitleTab>Consolidacion</TitleTab>
      </CardHeader>
      <GridInput>
        <div>
          <StyledLabel htmlFor="Orden de compra">Orden de compra</StyledLabel>
          <StyledInput
            ref={orderInputRef}
            type="number"
            border={true}
            $error={errores.includes('oc_grupo')}
            placeholder="xxx-xxx-xxx"
            maxLength="9"
            value={inputs.oc_grupo}
            disabled={
              pedidosAConsolidar.ordenDeCompra !== undefined &&
              pedidosAConsolidar.ordenDeCompra !== null
            }
            onChange={(event) =>
              setInputs((prevInputs) => ({
                ...prevInputs,
                oc_grupo: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <StyledLabel htmlFor="Fecha de vencimiento">
            Fecha de vencimiento
          </StyledLabel>
          <StyledDatepicker
            type="date"
            value={inputs.fecha_vto}
            onChange={(event) =>
              setInputs((prevInputs) => ({
                ...prevInputs,
                fecha_vto: event.target.value,
              }))
            }
          />
        </div>
      </GridInput>

      <GridInput>
        <div>
          <StyledLabel htmlFor="Zona">Zona</StyledLabel>

          {zonasProveedor === null ? (
            <P2>No hay zonas</P2>
          ) : Object.keys(zonasProveedor).length > 0 || zonaLoading ? (
            <CustomSelect
              id="Zona"
              options={zonasProveedor}
              bestZona={bestZona ? bestZona[proveedor.id] : false}
              selectedOption={inputs.id_zona}
              toggleOptionsAction={() => mejorProveedorZona(proveedor.id)}
              onChange={async (zonaId) => {
                const lugares = await getLugaresProveedores(
                  proveedor.id,
                  zonaId,
                )
                setLugaresProveedor(lugares)
                setInputs((prevInputs) => ({
                  ...prevInputs,
                  id_lugar: Object.keys(lugares)[0],
                }))
                return setInputs((prevInputs) => ({
                  ...prevInputs,
                  id_zona: zonaId,
                }))
              }}
            />
          ) : (
            <CustomLoading>
              <RotatingLoader />
            </CustomLoading>
          )}
        </div>
        <div>
          <StyledLabel htmlFor="Lugares de Entrega">
            Lugares de Entrega
          </StyledLabel>
          {!zonaLoading ? (
            <CustomSelect
              id="Lugar"
              options={lugaresProveedor}
              selectedOption={inputs.id_lugar}
              onChange={(selectedOption) => {
                return setInputs((prevInputs) => ({
                  ...prevInputs,
                  id_lugar: selectedOption,
                }))
              }}
              disabled={Object.keys(lugaresProveedor).length === 0}
            />
          ) : (
            <CustomLoading>
              <RotatingLoader />
            </CustomLoading>
          )}
        </div>
      </GridInput>

      <FullWidthInputContainer>
        <StyledLabel htmlFor="Observaciones">Observaciones</StyledLabel>
        <StyledInput
          type="text"
          border={true}
          placeholder="Observación"
          value={inputs.observacion}
          onChange={(event) =>
            setInputs((prevInputs) => ({
              ...prevInputs,
              observacion: event.target.value,
            }))
          }
        />
      </FullWidthInputContainer>

      {errores.length > 0 ||
        (zonasProveedor.length !== 0 &&
          Object.keys(lugaresProveedor).length === 0 &&
          !zonaLoading && (
            <DropdownNotification
              text={
                errores.length > 0
                  ? 'Por favor completá los datos faltantes'
                  : 'No existen lugares de entrega asociados a esa zona. Eligí otra zona para continuar.'
              }
              type="error"
            />
          ))}
      {isModalOpen && (
        <ModalNotification
          type="notificacion"
          title="Estás por consolidar un pedido que no cumple con los requisitos"
          descripcion={[
            'Si seleccionás CONTINUAR ',
            'consolidarás el pedido de este proveedor. ',
            'Si no estás seguro seleccioná VOLVER Y REVISAR',
          ]}
          primaryFunction={checkDataAndConsolidatePedido}
          secondaryFunction={() => {
            closeModal()
          }}
          afterClose={() => {
            closeModal()
          }}
        />
      )}
      <TableFooter>
        <Button
          type="tertiary"
          text="Volver al paso 2"
          onClick={() => {
            goToPrevStep()
          }}
        />
        <Button
          type="primary"
          text={conoslidacionLoading ? <RotatingLoader /> : 'Consolidar'}
          onClick={() => {
            reqCalculados < 100 ? openModal() : checkDataAndConsolidatePedido()
          }}
        />
      </TableFooter>
    </InputContainer>
  )
}

export default StepConsolidatePedido
