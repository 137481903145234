export const preguntas = [
  {
    title: '¿Cómo puede mi Cooperativa/Mutual realizar compras en la CCFACC? ',
    text: `Para que tu Cooperativa o Mutual pueda realizar compras en la CCFACC, debes seguir estos pasos:
    \n1. Asociarse a la Federación Argentina de Cooperativas de Consumo.
    \n2. Firmar un convenio de adhesión con la CCFACC.
    \n3. Definir el transporte con el cual deseas trabajar.
    \n4. Solicitar un usuario para poder generar los pedidos.`,
  },
  {
    title: '¿Tiene algún costo el servicio? ',
    text: `Los precios que se muestran en la plataforma incluyen un 3,5% en concepto de aporte para el sostenimiento de la CCFACC, destinado a cubrir los gastos operativos de la misma. Este porcentaje se suma al costo neto negociado por la Cooperativa Obrera. 
    \nA diferencia de otras plataformas que cobran un canon fijo, la CCFACC únicamente adiciona el aporte de sostenimiento del 3,5% a las operaciones que se concretan.`,
  },
  {
    title: '¿Puedo comprar en cualquier momento a cualquier proveedor? ',
    text: 'Para poder generar un pedido, es necesario que el proveedor esté habilitado en el cronograma vigente. Si el proveedor deseado no está disponible en el cronograma actual, puedes ponerte en contacto con la CCFACC para consultar sobre su próxima habilitación.',
  },
  {
    title: '¿Qué es un cronograma de compras?',
    text: `Los cronogramas de compras se establecen para negociar con los proveedores, actualizar los precios en la plataforma y permitir que los proveedores reciban todos los pedidos de las cooperativas y mutuales al mismo tiempo. Esto ayuda a optimizar tiempos y costos de entrega. 
    \nLos cronogramas tienen una vigencia de una semana, comenzando los miércoles y finalizando los martes. Los pedidos que no se hayan consolidado en los carros de compras desaparecerán al finalizar el cronograma.`,
  },
  {
    title:
      '¿Qué debo tener en cuenta al momento de generar un pedido a un proveedor?',
    text: `Al generar un pedido (Orden de Compra), es importante considerar los siguientes aspectos:
    \nPrecios: Asegúrate de verificar los precios en la plataforma.
    \nMínimo de compra: Revisa si hay un monto mínimo de compra requerido.
    \nCondiciones comerciales: Familiarízate con las condiciones de pago y otras políticas del proveedor.
    \nLugares de entrega: Confirma los lugares donde se puede realizar la entrega.
    \nLa generación de un pedido implica un compromiso para la Asociada compradora de cumplir con las condiciones de pago indicadas en la web. A su vez, el proveedor debe respetar los precios reflejados en la plataforma al momento de facturar.`,
  },
  {
    title: '¿Cuáles son los plazos y formas de pago disponibles? ',
    text: 'Los plazos y formas de pago varían según cada proveedor. Esta información se puede visualizar en la barra de información específica de cada proveedor en la plataforma.',
  },
  {
    title: '¿Cuáles son los lugares de entrega? ',
    text: `Las entregas se realizan principalmente en la Ciudad Autónoma de Buenos Aires (Zona Bs. As.) y sus alrededores, utilizando los transportes indicados por cada Cooperativa o Mutual al comenzar la operación. 
    \nEn algunos casos, dependiendo de la ubicación del proveedor, se pueden negociar otros puntos de entrega, como Córdoba, Bahía Blanca, Rosario, entre otros. Estos detalles se especifican en las observaciones de cada proveedor en la plataforma.`,
  },
  {
    title:
      '¿Los precios y mínimos de compra son iguales para un mismo proveedor en todas las zonas? ',
    text: 'No, los precios y mínimos de compra pueden variar según la ubicación del proveedor. En algunos casos, se pueden negociar mínimos de compra o precios inferiores dependiendo de la zona de entrega',
  },
  {
    title: '¿Los precios incluyen impuestos?  ',
    text: 'No, los precios detallados en la web son netos de impuestos. Los impuestos aplicables se encuentran especificados en columnas independientes en la plataforma.',
  },
]
