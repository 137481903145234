import React from 'react'
import styled from 'styled-components'

const StyledTabsComponent = styled.div`
  display: flex;
  flex-direction: column;
`

const TabSelector = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  height: 60px;
`

const TabBody = styled.div`
  padding: 10px 16px 60px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  display: ${({ $isActive }) => ($isActive ? 'flex' : 'none')};
  gap: 12px;
  border-radius: 0 0 20px 20px;

  @media (max-width: 440px) {
    padding: 8px 8px 40px;
  }
`

const TabHead = styled.div`
  border-radius: 15px 15px 0 0;
  padding: 18px;
  background-color: ${({ theme, $isActive }) =>
    $isActive
      ? theme.colors.neutral.almostWhite
      : theme.colors.neutral.lightGray};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.neutral.black : theme.colors.neutral.darkGray};
  font-weight: ${({ $isActive }) => ($isActive ? '700' : '400')};
  user-select: none;
  cursor: pointer;
`

const TabsComponentPedidos = ({ $tabs, children, activeTab, setActiveTab }) => {
  const handleTabClick = (index) => {
    setActiveTab(index)
  }

  return (
    <StyledTabsComponent $tabs={$tabs}>
      <TabSelector>
        {$tabs.map((tab, index) => (
          <TabHead
            key={index}
            $isActive={index === activeTab}
            title={tab}
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </TabHead>
        ))}
      </TabSelector>

      {React.Children.map(children, (child, index) => (
        <TabBody key={index} $isActive={activeTab === index}>
          {child}
        </TabBody>
      ))}
    </StyledTabsComponent>
  )
}

export default TabsComponentPedidos
