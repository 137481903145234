import React from 'react'
import styled from 'styled-components'
import ParticipacionTable from './ParticipacionTable'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import ProgressBarComponent from 'components/ProgressBar/ProgressBar'
import DescriptionRequerimientosProveedor from 'pages/comprar/SurtidoProveedores/components/DescriptionRequerimientosProveedor'

const ProgressBar = styled(ProgressBarComponent)`
  height: 10px;
  flex: none;
  width: 20%;
  margin-left: 30px;
`

const WrapperRequerimientos = styled.div`
  padding: 0 20px 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const ExpandableTablePedidos = ({ data }) => (
  <ExpandableTables
    openTables={['Participacion']}
    items={[
      {
        title: 'Requerimientos',
        componentTitle: (
          <>
            <ProgressBar
              progress={Math.min(data.requisitosCumplidos, 100)}
              tipoProveedor={data.tipo_proveedor}
            />
          </>
        ),
        component: (
          <WrapperRequerimientos>
            <DescriptionRequerimientosProveedor
              requerimientos={data.requerimientos}
            />
          </WrapperRequerimientos>
        ),
      },
      {
        title: 'Participacion',
        component: <ParticipacionTable afiliados={data.afiliados} />,
      },
    ]}
  />
)

export default ExpandableTablePedidos
