import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { H3 } from 'components/Typography'
import ExpandableAsociada from './ExpandableAsociada'
import { useAuth } from 'contexts/AuthContext'
import { Button } from 'components/index'

const WrapperDisplayAsociadasIndividuales = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 20px 44px;
  box-sizing: border-box;

  @media (max-width: 440px) {
    padding: 18px 22px;
  }
`

const HeaderTitle = styled(H3)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const CardsWrapper = styled.div`
  width: 100%;
`

const DisplayAsociadasIndividuales = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  return (
    <WrapperDisplayAsociadasIndividuales>
      <HeaderWrapper>
        <HeaderTitle>{user.grupo.descripcion}</HeaderTitle>
        {user.rol.id_rol === '1' && (
          <Button text="Ver pedidos" onClick={() => navigate(`/pedidos/`)} />
        )}
      </HeaderWrapper>
      <CardsWrapper>
        {user.grupo.afiliados.map((asociada, index) => {
          return (
            <ExpandableAsociada
              key={asociada.id}
              $index={index}
              asociada={asociada}
            />
          )
        })}
      </CardsWrapper>
    </WrapperDisplayAsociadasIndividuales>
  )
}

export default DisplayAsociadasIndividuales
