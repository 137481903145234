import React from 'react'
import styled from 'styled-components'

import Table from 'components/Table/Table'
import HeaderTable from 'components/Table/HeaderTable'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import TotalesSection from '../HeaderComprobantes/TotalesSection'
import { Button } from 'components/index'
import { Download } from 'feather-icons-react/build/IconComponents'
import CurrencyFormat from 'react-currency-format'
import dayjs from 'dayjs'
import { P2 } from 'components/Typography'
import { usePedidos } from 'contexts/PedidosContext'

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
`

const ScrolleableTable = styled.div`
  overflow: scroll;
  max-height: 50vh;
`

const CustomHeader = styled(HeaderTable)`
  padding: 14px 44px;
`

const CustomExpandableTables = styled(ExpandableTables)`
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding: 14px 44px;
  border: none;
`

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  gap: 24px;
`

const WrapperTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  heigth: 50vh;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const headers = [
  { name: 'EAN', isOrderable: false },
  { name: 'Producto', isOrderable: false },
  { name: 'Precio unitario', isOrderable: false },
  { name: 'Bultos pedidos', isOrderable: false },
  { name: 'Neto', isOrderable: false },
  { name: 'subtotal c/iva', isOrderable: false },
]

const formatData = ({ articulos }) =>
  articulos.map((e) => ({
    EAN: <P2>{e.ean13}</P2>,
    Producto: <P2>{e.descripcion}</P2>,
    'Precio unitario': (
      <CurrencyFormat
        value={parseFloat(e.precio_pedido)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    'Bultos pedidos': <P2>{e.cant_pedido}</P2>,
    Neto: (
      <CurrencyFormat
        value={parseFloat(e.neto_pedido)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
    'subtotal c/iva': (
      <CurrencyFormat
        value={parseFloat(e.neto_pedido) + parseFloat(e.iva_pedido)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'$'}
        decimalScale={3}
        fixedDecimalScale={true}
      />
    ),
  }))

const DetallesSubasociadasTable = ({ pedidos, idOcCabecera }) => {
  const { obtenerPdfPedidoSubasociada } = usePedidos()

  const onClickPedidoDescarga = async (pedidoId, idAfiliado, documentName) => {
    const pdfData = await obtenerPdfPedidoSubasociada(pedidoId, idAfiliado)
    const url = window.URL.createObjectURL(
      new Blob([pdfData], { type: 'application/pdf' }),
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `OC-${documentName}-ID${pedidoId}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <>
      {pedidos ? (
        <CustomExpandableTables
          items={Object.keys(pedidos).map((key) => {
            return {
              title: pedidos[key].nombre_comercial,
              componentTitle: (
                <TitleSection>
                  <TotalesSection
                    headerData={{
                      ...pedidos[key].totales,
                      fecha: dayjs(pedidos[key].fechaConfeccion).format(
                        'DD/MM/YYYY',
                      ),
                    }}
                  />
                  <Button
                    icon={<Download size={14} />}
                    text="Descargar"
                    onClick={() => {
                      onClickPedidoDescarga(
                        idOcCabecera,
                        pedidos[key].id_afiliado,
                        pedidos[key].orden_de_compra,
                      )
                    }}
                  />
                </TitleSection>
              ),
              component: (
                <WrapperTable>
                  <CustomHeader
                    numerate={false}
                    headers={headers}
                    gridTemplate="1fr, 1fr, 1fr, 1fr, 1fr, 1fr"
                  />
                  <ScrolleableTable>
                    <Table
                      data={formatData({
                        articulos: pedidos[key].articulos,
                      })}
                      gridTemplate="1fr, 1fr, 1fr, 1fr, 1fr, 1fr"
                      numerate={false}
                    />
                  </ScrolleableTable>
                </WrapperTable>
              ),
            }
          })}
        />
      ) : (
        <EmptyTableData>No se encontraron articulos</EmptyTableData>
      )}
    </>
  )
}

export default DetallesSubasociadasTable
