import React, { useEffect } from 'react'
import styled from 'styled-components'
import { H2, P1 } from 'components/Typography'
import ExpandableTables from 'components/ExpandableTables/ExpandableTables'
import FacturasTable from './components/Tables/FacturasTable'
import RemitosTable from './components/Tables/RemitosTable'
import { useLocation } from 'react-router-dom'
import { usePedidos } from 'contexts/PedidosContext'
import { useAuth } from 'contexts/AuthContext'
import RotatingLoading from 'components/Loading/Loading'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 10%;
  position: relative;
  border-radius: 20px;
  width: 100%;
  @media (max-width: 1366px) {
    padding: 40px 4%;
  }
`

const WrapperDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const HeaderWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
`

const HeaderTitle = styled(H2)`
  font-size: 30px;
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const HeaderSubtitle = styled(P1)`
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const CardsWrapper = styled.div`
  width: 95%;
  margin-bottom: 32px;
`

const ComprobantesPage = () => {
  function getFacturas() {
    if (user.rol.id_rol === '2') {
      if (state?.tipoProveedor === '1') {
        return comprobantes?.facturas
      } else {
        return []
      }
    } else {
      return comprobantes?.facturas
    }
  }

  const location = useLocation()
  const { user } = useAuth()
  const { state } = location
  const { comprobantes, pedidosLoading, getComprobantes } = usePedidos()
  let tableItems = []

  if (
    user.rol.id_rol === '3' ||
    (user.rol.id_rol === '2' && state?.tipoProveedor === '1')
  ) {
    tableItems = [
      {
        title: 'Facturas',
        component: <FacturasTable facturas={getFacturas()} />,
      },
    ]
  } else if (user.rol.id_rol === '2' && state?.tipoProveedor === '0') {
    tableItems = [
      {
        title: 'Remitos',
        component: (
          <RemitosTable
            oc={comprobantes?.pedido?.id_oc_afiliado}
            remitos={comprobantes?.remitos}
          />
        ),
      },
    ]
  } else {
    tableItems = [
      {
        title: 'Remitos',
        component: (
          <RemitosTable
            oc={comprobantes?.pedido?.id_oc_afiliado}
            remitos={comprobantes?.remitos}
          />
        ),
      },
      {
        title: 'Facturas',
        component: <FacturasTable facturas={getFacturas()} />,
      },
    ]
  }

  useEffect(() => {
    if (state?.idOcCompra) {
      const afiliadoId =
        user.rol.id_rol === '1' || user.rol.id_rol === '2' ? user.id : null
      getComprobantes(state.idOcCompra, afiliadoId)
    } else if (state?.idOcCompraCoordinadora) {
      getComprobantes(state.idOcCompraCoordinadora)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      {pedidosLoading ? (
        <RotatingLoading />
      ) : (
        comprobantes &&
        Object.keys(comprobantes).length > 0 && (
          <WrapperDisplay>
            <HeaderWrapper>
              <HeaderTitle>
                {comprobantes?.pedido?.nombre_comercial}
              </HeaderTitle>
              <HeaderSubtitle>
                OC #{comprobantes?.pedido?.id_oc_afiliado}
              </HeaderSubtitle>
            </HeaderWrapper>
            <CardsWrapper>
              <ExpandableTables
                items={tableItems}
                openTables={tableItems.map((i) => i.title)}
              />
            </CardsWrapper>
          </WrapperDisplay>
        )
      )}
    </Wrapper>
  )
}

export default ComprobantesPage
