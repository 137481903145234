import React from 'react'
import styled from 'styled-components'

const Text = styled.h3`
  margin: 0;
  font-size: 24px;
  font-family: 'Roboto-bold';
  line-height: 32px;
  @media screen and (max-width: 1366px) {
    font-size: 18px;
    line-height: 20px;
  }
`

const H3 = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default H3
