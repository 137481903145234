import React from 'react'
import styled from 'styled-components'
import ListCard from './ListCard'
import TiempoRestanteCard from './TimeCloseCronogramaCard'

const WrapperSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 5% 10%;
  gap: 80px;

  @media (max-width: 440px) {
    flex-direction: column;
    gap: 40px;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px;
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border: solid 1px rgba(0, 0, 0, 0.25);

  @media (max-width: 440px) {
    padding: 16px;
    width: 90%;
    height: 280px;
  }
`

const AlertSection = ({ proveedores }) => {
  return (
    <WrapperSection>
      <CardContainer>
        <ListCard proveedores={proveedores} title="Proveedores" />
      </CardContainer>
      <CardContainer>
        <TiempoRestanteCard />
      </CardContainer>
    </WrapperSection>
  )
}

export default AlertSection
