import React from 'react'
import styled from 'styled-components'

import { P4 } from 'components/Typography'

const NumberElementText = styled(P4)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const NumberElement = ({ currentElement, totalElements, className }) => {
  return currentElement && totalElements ? (
    <NumberElementText className={className}>
      Mostrando {currentElement} de {totalElements} resultados
    </NumberElementText>
  ) : (
    <></>
  )
}

export default NumberElement
