import { BtnS } from 'components/Typography'
import React from 'react'
import styled from 'styled-components'
import CurrencyFormat from 'react-currency-format'

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  display: flex;
  gap: 10px;
  text-transform: uppercase;
  margin-left: auto;
  font-weight: bold;
  span {
    font-weight: 400;
  }

  @media (max-width: 440px) {
    gap: 6px;
    margin-left: 0;
  }
`

const VerticalLine = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.darkBlue};
  width: 3px;
  height: 40px;
`

const TotalesSection = ({ headerData }) => {
  return (
    <Wrapper>
      <Col>
        <BtnS>Fecha</BtnS>
        <BtnS>{headerData?.fecha}</BtnS>
      </Col>
      <VerticalLine />
      <Col>
        <BtnS>bultos</BtnS>
        <BtnS>{headerData?.bultos_pedido || headerData?.bultos}</BtnS>
      </Col>
      <VerticalLine />
      <Col>
        <BtnS>Neto</BtnS>
        <BtnS>
          <CurrencyFormat
            value={parseFloat(headerData?.subtotal || headerData?.neto_pedido)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'$'}
            decimalScale={3}
            fixedDecimalScale={true}
          />
        </BtnS>
      </Col>
      <VerticalLine />
      <Col>
        <BtnS>total</BtnS>
        <BtnS>
          <CurrencyFormat
            value={parseFloat(headerData?.total || headerData?.total_pedido)}
            displayType={'text'}
            thousandSeparator={'.'}
            decimalSeparator={','}
            prefix={'$'}
            decimalScale={3}
            fixedDecimalScale={true}
          />
        </BtnS>
      </Col>
    </Wrapper>
  )
}

export default TotalesSection
