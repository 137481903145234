import toast from 'react-hot-toast'
import DropdownNotification from './DropdownNotification'
import styled from 'styled-components'

const ToastNotification = styled(DropdownNotification)`
  width: 400px;
`

export const showToast = (text, type, actionText, action) => {
  toast.custom((t) => (
    <ToastNotification
      text={text}
      type={type}
      actionText={actionText}
      action={() => {
        action()
        toast.dismiss(t.id)
      }}
      closeAction={() => toast.dismiss(t.id)}
    />
  ))
}
