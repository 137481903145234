import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import backgroundImg from 'assets/banner.png'
import { Wrapper } from 'components/layout/FlexComponents'

const CarouselContainer = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 3% 10%;
  overflow: hidden;
`

const SlideContainer = styled.div`
  display: flex;
  position: relative;
  height: 300px;
  width: 100%;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  background-image: url(${backgroundImg});
  background-repeat: round;
  overflow: hidden;

  @media (max-width: 440px) {
    height: 260px;
  }
`

const Slide = styled.div`
  width: 100%;
  position: absolute;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: ${({ $index, $currentSlide, $previousSlide, $autoplay }) =>
    $autoplay
      ? $index === $currentSlide
        ? 'slideInRight'
        : $index === $previousSlide
          ? 'slideOutLeft'
          : ''
      : $currentSlide > $previousSlide
        ? $index === $currentSlide
          ? 'slideInRight'
          : 'slideOutLeft'
        : $index === $currentSlide
          ? 'slideInLeft'
          : 'slideOutRigh'};

  @keyframes slideInLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  @keyframes slideOutRight {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(100%);
    }
  }

  @keyframes slideInRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  @keyframes slideOutLeft {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-100%);
    }
  }
`

const DotsContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
`

const Dot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.neutral.white : theme.colors.neutral.gray};
  scale: ${({ $isActive }) => ($isActive ? 1.5 : 1)};
  margin: 0 5px;
  cursor: pointer;
  z-index: 1;
`

const BannerCarousel = ({ banners }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [previousSlide, setPreviousSlide] = useState(2)
  const [autoplay, setAutoplay] = useState(true)
  const timeoutRef = useRef(null)

  const handleNextSlide = useCallback(() => {
    setPreviousSlide(currentSlide)
    setCurrentSlide((prevSlide) => (prevSlide + 1) % banners.length)

    setTimeout(() => {
      setAutoplay(true)
    }, 1000)
  }, [banners.length, currentSlide])

  useEffect(() => {
    if (autoplay) {
      timeoutRef.current = setTimeout(() => {
        handleNextSlide()
      }, 3000)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [autoplay, currentSlide, handleNextSlide])

  const handleDotClick = (index) => {
    setPreviousSlide(currentSlide)
    setCurrentSlide(index)
    setAutoplay(false)
  }

  return (
    <CarouselContainer>
      <SlideContainer>
        {banners.map((banner, index) => (
          <Slide
            key={index}
            $index={index}
            $autoplay={autoplay}
            $currentSlide={currentSlide}
            $previousSlide={previousSlide}
            $isActive={index === currentSlide}
          >
            {banner.element}
          </Slide>
        ))}
        <DotsContainer>
          {banners.map((_, index) => (
            <Dot
              key={index}
              $isActive={index === currentSlide}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </DotsContainer>
      </SlideContainer>
    </CarouselContainer>
  )
}

export default BannerCarousel
