import React from 'react'
import Modal from './Modal'
import { useModal } from 'contexts/ModalContext'
import { useCronograma } from 'contexts/CronogramaContext'
import { usePedidos } from 'contexts/PedidosContext'

const EnableCronogramaModal = ({
  cronogramaNumber,
  setOpenTable,
  todosHabilitados,
}) => {
  const { closeModal } = useModal()
  const { habilitarCronograma } = useCronograma()
  const { getPedidosAConsolidar } = usePedidos()
  const content = {
    type: 'info',
    icon: 'bell',
    title: 'Estás por habilitar el cronograma ',
    text: todosHabilitados
      ? [
          'Si seleccionas ',
          'CONTINUAR ',
          'habilitarás todos los proveedores del cronograma. Si preferís controlarlos seleccioná ',
          'VOLVER Y REVISAR',
        ]
      : [
          'Hay proveedores que no están habilitados, podés ',
          'CONTINUAR ',
          'o controlarlos seleccionando ',
          'VOLVER Y REVISAR',
        ],
    primaryButton: {
      function: async () => {
        closeModal()
        await habilitarCronograma()
        await getPedidosAConsolidar()

        setOpenTable((prevState) => ({
          ...prevState,
          proveedores: false,
        }))
      },
      text: 'Continuar',
    },
    secondaryButton: {
      function: () => closeModal(),
      text: 'Volver y revisar',
    },
  }
  return <Modal content={content} cronogramaNumber={cronogramaNumber}></Modal>
}

export default EnableCronogramaModal
