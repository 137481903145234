import React from 'react'
import styled from 'styled-components'

const Text = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
`

const P4 = ({ className, children, title }) => (
  <Text className={className} title={title}>
    {children}
  </Text>
)

export default P4
