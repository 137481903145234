import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Card from 'components/Carousel/Card'
import { H2 } from 'components/Typography'
import { Wrapper } from 'components/layout/FlexComponents'
import { ChevronRight } from 'feather-icons-react/build/IconComponents'

const CarouselTitle = styled(H2)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin: 32px 0 16px 10%;
  background-color: ${({ type, theme }) =>
    type === 'primary'
      ? theme.colors.primary.darkBlue
      : theme.colors.neutral.white};
`

const WrapperCarousel = styled(Wrapper)`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  padding-bottom: 32px;
  background-color: ${({ type, theme }) =>
    type === 'primary' ? theme.colors.primary.darkBlue : 'none'};
  position: relative;

  ${CarouselTitle} {
    color: ${({ type, theme }) =>
      type === 'primary'
        ? theme.colors.neutral.white
        : theme.colors.primary.darkBlue};
  }
`

const StyledHorizontalCarousel = styled.div`
  height: auto;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 0 0 0 10%;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &:active {
    cursor: grabbing;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const ScrollableArea = styled.div`
  display: flex;
  width: auto;

  &::after {
    content: '';
    flex-shrink: 0;
    width: calc(10% + 16px);
  }
`

const ScrollIndicator = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.primary.darkBlue};
  border-radius: 50%;
  top: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const HorizontalCarousel = ({ cards, title, className, type }) => {
  const scrollableAreaRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [showIndicator, setShowIndicator] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableAreaRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } =
          scrollableAreaRef.current
        setShowIndicator(
          scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth,
        )
      }
    }

    scrollableAreaRef.current.addEventListener('scroll', handleScroll)
    return () => {
      if (scrollableAreaRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollableAreaRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (isDragging) {
      const handleMouseMove = (event) => {
        const distance = event.pageX - startX
        scrollableAreaRef.current.scrollLeft = scrollLeft - distance
      }

      const handleMouseUp = () => {
        setIsDragging(false)
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)

      return () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [isDragging, startX, scrollLeft])

  return (
    <WrapperCarousel className={className} type={type}>
      <CarouselTitle type={type}>{title}</CarouselTitle>
      <StyledHorizontalCarousel
        ref={scrollableAreaRef}
        onMouseDown={(e) => {
          setIsDragging(true)
          setStartX(e.pageX - scrollableAreaRef.current.offsetLeft)
          setScrollLeft(scrollableAreaRef.current.scrollLeft)
        }}
      >
        <ScrollableArea>
          {cards.map((card, index) => (
            <Card key={index + '-' + title} card={card} />
          ))}
        </ScrollableArea>
        {showIndicator && (
          <ScrollIndicator
            onClick={() => {
              scrollableAreaRef.current.scrollLeft += 300
            }}
          >
            <ChevronRight size={35} color="#ffffff" />
          </ScrollIndicator>
        )}
      </StyledHorizontalCarousel>
    </WrapperCarousel>
  )
}

export default HorizontalCarousel
