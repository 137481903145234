import React, { useState } from 'react'
import styled from 'styled-components'
import {
  ChevronDown,
  ChevronUp,
} from 'feather-icons-react/build/IconComponents'
import TabsComponent from './TabsComponent'
import { replaceText } from 'utils/textProcess'
import { H4, H5 } from 'components/Typography'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const Wrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.gray};
  background-color: ${({ $isExpanded, theme }) =>
    $isExpanded
      ? theme.colors.neutral.white
      : theme.colors.neutral.almostWhite};
  flex-direction: column;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  padding: 14px 36px;
  &:last-child {
    border-radius: 0 0 20px 20px;
  }

  @media (max-width: 440px) {
    padding: 12px 22px;
  }
`

const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CardTitle = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.black};
`

const CardActions = styled.div`
  display: flex;
  gap: 12px;
`

const CardContent = styled.div`
  display: ${({ $isExpanded }) => ($isExpanded ? 'block' : 'none')};
  margin: 25px -25px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
`

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  font-weight: 600;
  font-size: 14px;
`

const ExpandableAsociada = ({ asociada, $index }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { width } = useWindowWidth()
  const handleClick = () => {
    setIsExpanded(!isExpanded)
  }

  const formatAsociadasDataToDisplay = (obj) =>
    obj
      ? {
          id: obj.id,
          nombre: obj.abreviatura,
          cuit: obj.cuit,
          localidad: obj.localidad.localidad,
          domicilio: obj.domicilio,
          telefono: obj.telefono === '0' ? '-' : obj.telefono,
          fax: obj.fax === '0' ? '-' : obj.fax,
        }
      : {}

  const dataToDisplay = formatAsociadasDataToDisplay(asociada)

  return (
    <Wrapper $isExpanded={isExpanded}>
      <CardHeader>
        <CardTitle>
          <H4>Asoc {$index + 1} /</H4>
          <H5>
            {replaceText(asociada.nombre_comercial)} -{' '}
            {asociada.localidad.localidad}
          </H5>
        </CardTitle>
        <CardActions>
          <StyledButton onClick={handleClick} $isExpanded={isExpanded}>
            {isExpanded ? (
              <>
                {width > 440 && <span>Ver menos</span>}

                <ChevronUp />
              </>
            ) : (
              <>
                {width > 440 && <span>Ver mas</span>}
                <ChevronDown />
              </>
            )}
          </StyledButton>
        </CardActions>
      </CardHeader>
      <CardContent $isExpanded={isExpanded}>
        <TabsComponent asociada={dataToDisplay} />
      </CardContent>
    </Wrapper>
  )
}

export default ExpandableAsociada
