import { H1, H2, P1 } from 'components/Typography'
import { Button } from 'components/index'
import React from 'react'
import styled from 'styled-components'
import LogoFaccCentral from 'components/images/LogoFaccCentral.jsx'
import { useAuth } from 'contexts/AuthContext'
import RotatingLoader from 'components/Loading/RotatingLoader'
import {
  Navigate,
  // , useRouteError
} from 'react-router-dom'
import { useInitialData } from 'contexts/InitialDataContext'
// import ExpandableTables from 'components/ExpandableTables/ExpandableTables'

const Main = styled.section`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  gap: 18px;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`

const LeftContainer = styled.div`
  width: 50%;
  text-align: end;

  @media (max-width: 1199px) {
    width: 100%;
    text-align: center;
  }
`

const RightContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-left: 16px;

  @media (max-width: 1199px) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
`

const Line = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.blue};
  height: 80%;
  width: 2px;

  @media (max-width: 1199px) {
    width: 80%;
    height: 2px;
  }
`

const Logo = styled(LogoFaccCentral)`
  flex-shrink: 0;
  max-height: 30vh;
  width: auto;
  align-self: center;

  @media (max-width: 440px) {
    max-height: 16vh;
  }
`

const StyledP1 = styled(P1)`
  text-wrap: balance;
  margin-bottom: 24px;
`

const StyledButton = styled(Button)`
  width: min-content;
`

const CustomRotatingLoader = styled(RotatingLoader)`
  width: 40px;
`

// const CustomExpandableTables = styled(ExpandableTables)`
//   border: 1px solid ${({ theme }) => theme.colors.neutral.gray};
//   flex-wrap: wrap;

//   overflow: scroll;
// `

// const ErrorContent = styled.div`
//   background-color: ${({ theme }) => theme.colors.neutral.lightGray};
//   padding: 10px;
//   color: ${({ theme }) => theme.colors.alarmsStatuses.red};
// `

const Error = ({ isCronogramaError = false }) => {
  const { user, logout, logoutLoading, token } = useAuth()
  const { setErrorLoadingData } = useInitialData()
  // const error = useRouteError()

  if (!token) {
    return <Navigate to="/login" />
  }
  return (
    <Main>
      <LeftContainer>
        <Logo />
      </LeftContainer>
      <Line />
      <RightContainer>
        {!isCronogramaError ? (
          <>
            <H1>Parece que hubo un error en cargar la página.</H1>
            <StyledP1>
              Para continuar necesitamos que cierres sesión y vuelvas a ingresar
              con usuario y contraseña. Si el error peresiste por favor
              comunicarse a través de los correos electrónicos de contacto.
            </StyledP1>
          </>
        ) : (
          <>
            <H2>
              Hola {user?.abreviatura}, El cronograma aún no está activo. Por
              favor, espera a que se habilite un nuevo cronograma para ingresar
              al portal.
            </H2>
            <StyledP1>
              Comunicate con la Central de Compras para dar aviso del
              inconveniente.
            </StyledP1>
          </>
        )}
        {/* <CustomExpandableTables
          items={[
            {
              title: 'Detalle',
              component: (
                <>
                  {error && (
                    <ErrorContent>
                      <p>
                        <strong>Error:</strong>
                        {error.message || error.statusText}
                      </p>
                      {process.env.NODE_ENV === 'development' && (
                        <pre>{error.stack}</pre> // Mostrar la pila de errores solo en desarrollo
                      )}
                    </ErrorContent>
                  )}
                </>
              ),
            },
          ]}
        /> */}
        <StyledButton
          text={logoutLoading ? <CustomRotatingLoader /> : 'Cerrar sesión'}
          onClick={async () => {
            await logout()
            setErrorLoadingData(false)
          }}
        />
      </RightContainer>
    </Main>
  )
}

export default Error
