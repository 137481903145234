import useAxiosInstance from './axiosInterceptor'
const axios = useAxiosInstance
import { queryString, reemplazarInterrogacionPorÑ } from 'utils/textProcess'

const ProveedorService = {
  getProveedor: async ({ idProveedor, idCarrito, idCronograma, token }) => {
    let data = {
      id_proveedor: parseInt(idProveedor),
      id_carrito: parseInt(idCarrito),
      id_cronograma: parseInt(idCronograma),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/proveedor?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        data.data.proveedor.nombre_comercial = reemplazarInterrogacionPorÑ(
          data.data.proveedor.nombre_comercial,
        )
        return data.data.proveedor
      })
      .catch((error) => {
        throw error
      })
  },
  obtenerCsvSurtidoProveedor: async ({
    idCronograma,
    idProveedor,
    idCarrito,
    token,
  }) => {
    let data = {
      id_cronograma: idCronograma,
      id_proveedor: idProveedor,
      id_carrito: idCarrito,
      token,
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/csv-articulos?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        // se imprime el error ya que no se llama esta función desde ningún context sino en los componentes
        console.error('Error obteniendo CSV del surtido de proveedor: ', error)
        throw error
      })
  },
}

export default ProveedorService
