import { Eye, EyeOff } from 'feather-icons-react/build/IconComponents'
import React, { forwardRef, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  width: ${({ $password }) => ($password ? 'calc(100% - 44px)' : '100%')};
  border-radius: 10px;
  ${({ $border }) =>
    $border &&
    css`
      border: 2px solid
        ${({ theme, $error }) =>
          $error
            ? theme.colors.alarmsStatuses.red
            : theme.colors.primary.darkBlue};
    `}
`

const InputStyled = styled.input`
  font-size: 16px;
  border: none;
  width: 100%;
  margin: 8px;
  background-color: transparent;
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
  &:focus-visible {
    outline: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 0 12px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  cursor: pointer;
`

const Input = forwardRef(
  (
    {
      className,
      placeholder,
      onChange,
      type,
      value,
      icon,
      onKeyPress,
      disabled,
      name,
      maxLength,
      border,
      $error,
      password,
    },
    ref,
  ) => {
    const inputRef = useRef(null)
    const [showPassword, setShowPassword] = useState(false)

    const focusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }

    const handleMouseWheel = (e) => {
      if (type === 'number') {
        e.preventDefault()
      }
    }

    const togglePasswordVisibility = () => {
      setShowPassword((prev) => !prev)
    }

    return (
      <InputContainer
        onClick={focusInput}
        className={className}
        $hasicon={icon !== undefined || password ? 'true' : 'false'}
        $border={border}
        $password={password}
        $error={$error}
      >
        {icon !== undefined && <IconWrapper>{icon}</IconWrapper>}
        <InputStyled
          ref={ref ? ref : inputRef}
          type={showPassword ? 'password' : type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onKeyUp={onKeyPress}
          onWheel={handleMouseWheel}
          disabled={disabled}
          name={name}
          maxLength={maxLength}
        />
        {password && (
          <IconWrapper onClick={togglePasswordVisibility}>
            {showPassword ? <EyeOff /> : <Eye />}
          </IconWrapper>
        )}
      </InputContainer>
    )
  },
)

Input.displayName = 'Input'

export default Input
