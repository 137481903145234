import React from 'react'
import styled from 'styled-components'
import { H3 } from 'components/Typography'
import DisplayAsociadasIndividuales from 'pages/coordinacion/AsociadasIndividuales/DisplayAsociadasIndividuales'

const Title = styled(H3)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const DisplayWrapper = styled.div`
  width: 100%;
`

const DatosAgrupacion = () => {
  return (
    <DisplayWrapper>
      <Title>Datos de Agrupación</Title>
      <DisplayAsociadasIndividuales />
    </DisplayWrapper>
  )
}

export default DatosAgrupacion
