import React, { useEffect } from 'react'
import styled from 'styled-components'
import HeaderPedido from './HeaderPedido'
import { H4 } from 'components/Typography'
import PedidosAgrupadosTable from '../PedidosAgrupadosTable'
import { usePedidos } from 'contexts/PedidosContext'
import { useAuth } from 'contexts/AuthContext'
import { useCronograma } from 'contexts/CronogramaContext'
import dayjs from 'dayjs'
import Loading from 'components/Loading/Loading'
import { useLocation } from 'react-router-dom'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const WrapperAgrupacionPage = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  margin: 50px auto;
  border-radius: 20px;

  @media (max-width: 440px) {
    padding: 10px 24px 10px 18px;
    margin: 32px 14px;
  }
`

const TableSection = styled.div`
  width: 100%;
`

const CardHeader = styled.header`
  border: 1px solid ${({ theme }) => theme.colors.neutral.gray};
  border-left: 4px solid ${({ theme }) => theme.colors.primary.darkBlue};
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 14px 44px;
`

const EmptyTableData = styled.div`
  padding: 23px 30px;
  background-color: ${({ theme }) => theme.colors.white};
`

const PedidoOrdenDeCompra = () => {
  const { user } = useAuth()
  const { cronograma } = useCronograma()
  const { width } = useWindowWidth()
  const { getOc, setOc, oc, pedidosLoading } = usePedidos()
  const location = useLocation()
  const id = location.state

  useEffect(() => {
    const fetchOcAgrupacion = async () => {
      try {
        if (user && id) {
          getOc(id)
        }
      } catch (error) {
        setOc({})
        console.error('Error al obtener los pedidos: ', error)
      }
    }
    if (user.rol && cronograma?.id_cronograma) {
      fetchOcAgrupacion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return pedidosLoading ? (
    <Loading />
  ) : oc && oc.pedido ? (
    <WrapperAgrupacionPage>
      <TableSection>
        <HeaderPedido
          oc={{
            proveedor: oc.pedido.proveedor,
            ordenDeCompra:
              user.rol.id_rol === '1' || user.rol.id_rol === '2'
                ? oc.pedido.orden_de_compra_visible
                : oc.pedido.orden_de_compra,
            estado: oc.pedido?.estado,
            porcentajeRecepcion: oc.pedido?.porcentajeRecepcion,
            totales: oc.pedido.totales,
            fecha: dayjs(oc.pedido.fechaConfeccion).format('DD/MM/YYYY'),
            id: id,
          }}
        />
        <CardHeader>
          <H4>{user.nombre_comercial}</H4>
        </CardHeader>
        {width > 440 && (
          <PedidosAgrupadosTable
            pedidos={oc.pedido.articulos}
            totales={oc.pedido.totales}
          />
        )}
      </TableSection>
    </WrapperAgrupacionPage>
  ) : (
    <EmptyTableData>No hay datos</EmptyTableData>
  )
}

export default PedidoOrdenDeCompra
