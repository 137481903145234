import React from 'react'
import styled from 'styled-components'

const Text = styled.h5`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  @media screen and (max-width: 1366px) {
    font-size: 16px;
  }
`

const H5 = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default H5
