import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Wrapper = ({ className, children }) => {
  return <StyledWrapper className={className}>{children}</StyledWrapper>
}

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
`

export const Col = ({ className, children }) => {
  return <StyledCol className={className}>{children}</StyledCol>
}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const Row = ({ className, children }) => {
  return <StyledRow className={className}>{children}</StyledRow>
}
