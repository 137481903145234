import React from 'react'
import styled from 'styled-components'

const Text = styled.h2`
  margin: 0;
  font-size: 32px;
  font-family: 'Roboto-bold';
  @media screen and (max-width: 1366px) {
    font-size: 28px;
  }
`

const H2 = ({ className, children }) => (
  <Text className={className}>{children}</Text>
)

export default H2
